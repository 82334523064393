import { useState } from "react";
import moment, { Moment } from "moment";
import "moment/locale/th";

export const useViewModel = () => {
  const [selectedDate, setSelectedDate] = useState<Moment>(
    moment().startOf("day")
  );

  const generateWeek = (date: Moment) => {
    const startOfWeek = date.clone().startOf("week");
    return Array.from({ length: 7 }, (_, i) =>
      startOfWeek.clone().add(i, "days")
    );
  };

  const weekDates = generateWeek(selectedDate);
  const onChangeWeek = (dir: "prev" | "next") => {
    const newDate = selectedDate.clone().add(dir === "next" ? 7 : -7, "days");
    setSelectedDate(newDate);
  };

  const handleDateClick = (date: Moment, onClick: (date: Moment) => void) => {
    onClick(date)
    setSelectedDate(date);
  };

  console.log("selectedDate", selectedDate.format("D MMMM YY"));

  return {
    selectedDate,
    onChangeWeek,
    weekDates,
    handleDateClick,
  };
};
