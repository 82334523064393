import { useEffect, useState } from "react";
import { CustomerData } from "../../types/customer";
import CustomerDetail from "../widget/haederCustomerForm";
import newCustomerRegisterServices from "../../services/newCustomerApi";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DataTable, { TableStyles } from "react-data-table-component";
import routeApi from "../../services/routeApi";
import scheduleApi from "../../services/scheduleApi";
import {
  CustomerSchedule,
  CustomerAlreadyHaveSchedules,
} from "../../types/schedule";
import { toast } from "react-toastify";
import { ReactComponent as Trash } from "../../assets/icon/trash.svg";
import Swal from "sweetalert2";

interface Entry {
  runNumber: number;
  id: number;
  serviceDate: string;
  route: string;
  type_delivery: number; // Add type_delivery
  frequency?: number | null; // Add frequency
  cycle?: number | null; // Add cycle
}

interface RouteData {
  message: string;
  data: {
    id: number;
    name: string;
    day: number;
    truck_id: number;
    trucks: {
      id: number;
      brand: string;
      model: string;
      license_plate: string;
      load_capacity: number;
      status: boolean;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
    };
    driver_id: number;
    drivers: {
      id: number;
      full_name: string;
      username: string;
      email: string;
      image_path: string;
      user_permissions: string | null;
      is_activated: boolean;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
    };
    porter_one_id: number;
    porter_ones: {
      id: number;
      full_name: string;
      username: string;
      email: string;
      image_path: string;
      user_permissions: string | null;
      is_activated: boolean;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
    };
    porter_two_id: number;
    porter_twos: {
      id: number;
      full_name: string;
      username: string;
      email: string;
      image_path: string;
      user_permissions: string | null;
      is_activated: boolean;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
    };
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
  }[];
}

const CustomerSchedulesForm = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const [customer, setCustomer] = useState<CustomerData | null>(null);
  const navigate = useNavigate();
  const [routes, setRoute] = useState<RouteData["data"]>([]);
  const [customerSchedules, setCustomerSchedules] = useState<
    CustomerAlreadyHaveSchedules[]
  >([]);

  const getCustomerAlreadyHaveSchedules = async (id: number) => {
    try {
      const response = await scheduleApi.getSchedulesByCustomerId(id);
      console.log("getCustomerAlreadyHaveSchedules", response);

      if (Array.isArray(response.data.data)) {
        const schedulesWithRunNumber = response.data.data.map(
          (schedule: CustomerAlreadyHaveSchedules, index: number) => ({
            ...schedule,
            runNumber: index + 1, // Sequential run number starting from 1
          })
        );
        setCustomerSchedules(schedulesWithRunNumber); // Only set if it's an array
      } else {
        console.error("Expected an array but got:", response.data.data);
        setCustomerSchedules([]); // Default to empty array if not valid
      }
    } catch (error) {
      console.error("Error fetching schedules:", error);
      setCustomerSchedules([]); // Default to empty array on error
    }
  };

  const mapCustomerSchedulesToEntries = (
    schedules: CustomerAlreadyHaveSchedules[]
  ): Entry[] => {
    const daysOfWeek = [
      "อาทิตย์",
      "จันทร์",
      "อังคาร",
      "พุธ",
      "พฤหัสบดี",
      "ศุกร์",
      "เสาร์",
    ];

    return schedules.map((schedule) => ({
      runNumber: schedule.runNumber,
      id: schedule.id,
      serviceDate: daysOfWeek[schedule.day] || "ไม่ทราบ",
      route: schedule.router?.name || "ไม่ทราบ",
      type_delivery: schedule.type_delivery,
      frequency: schedule.frequency,
      cycle: schedule.cycle,
    }));
  };

  const getNewCustomerById = async (id: string) => {
    try {
      const responseCustomer =
        await newCustomerRegisterServices.getNewCustomerByid(id);
      return responseCustomer.data;
    } catch (error) {
      console.log("Error in getNewCustomerById:", error);
      throw error;
    }
  };

  const setNewCustomerById = async () => {
    try {
      if (id) {
        const newCustomer = await getNewCustomerById(id);
        setCustomer(newCustomer);
      }
    } catch (error) {
      console.log("Error fetching customer data:", error);
    }
  };

  const deleteScheduleByID = async (delete_id:number) => {
    try {
      Swal.fire({
        title: "ยืนยันการลบ",
        icon: "warning",
        confirmButtonColor: "#FF0000",
        confirmButtonText: "ยืนยัน",
        cancelButtonColor: "#E5E7EB",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await scheduleApi.deleteScheduleByID(delete_id);
          if (response.status === 200) {
            toast.success("ลบวันเข้าเก็บสำเร็จ", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: setToastId,
            });
            getCustomerAlreadyHaveSchedules(Number(id));
          } else {
            toast.error("ลบไม่สำเร็จ", {
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
          
        }
      })
    } catch (error) {
      console.error("Error delete schedule:", error);
    }
    
  }

  useEffect(() => {
    setNewCustomerById();
    if (id) {
      getCustomerAlreadyHaveSchedules(Number(id));
    }
  }, [id]);

  const validationSchema = Yup.object().shape({
    type_delivery: Yup.string().required("Required"),
    frequency: Yup.string().required("Required"),
    serviceDate: Yup.string().required("Required"),
    route: Yup.string().required("Required"),
  });
  const setToastId = "toast-id";
  const addEntryToTable = async (values: any) => {
    let frequency: number | undefined;
    let cycle: number | undefined;
    switch (values.frequency) {
      case "every_week":
        frequency = 1;
        break;
      case "biweekly":
        frequency = 2;
        break;
      case "week1":
        cycle = 1;
        break;
      case "week2":
        cycle = 2;
        break;
      case "week3":
        cycle = 3;
        break;
      case "week4":
        cycle = 4;
        break;
      default:
        frequency = undefined;
        cycle = undefined;
    }

    // Construct the body dynamically
    let data: CustomerSchedule;

    if (frequency !== undefined) {
      // Assign only frequency when it's defined
      data = {
        customer_id: Number(id),
        router_id: Number(values.route),
        type_delivery: values.type_delivery === "weekly" ? 1 : 2,
        day: Number(values.serviceDate),
        frequency, // Include frequency
      };
    } else if (cycle !== undefined) {
      // Assign only cycle when it's defined
      data = {
        customer_id: Number(id),
        router_id: Number(values.route),
        type_delivery: values.type_delivery === "weekly" ? 1 : 2,
        day: Number(values.serviceDate),
        cycle, // Include cycle
      };
    } else {
      // Assign neither frequency nor cycle when both are undefined
      data = {
        customer_id: Number(id),
        router_id: Number(values.route),
        type_delivery: values.type_delivery === "weekly" ? 1 : 2,
        day: Number(values.serviceDate),
      };
    }
    console.log("Data to POST:", data);
    try {
      const response = await scheduleApi.createCustomerSchedule(data);
      console.log("Response:", response);
      if (response && response.message === "successfully") {
        console.log("Schedule created successfully:", response.data);
        toast.success("เพิ่มวันเข้าเก็บ สำเร็จ!", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: setToastId,
        });
        getCustomerAlreadyHaveSchedules(Number(id));
      } else {
        console.error("Failed to create schedule");
        toast.error("ไม่สามารถเพิ่มได้", {
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (error) {
      console.error("Error adding entry to table:", error);
    }
  };

  const getRouter = async (id: number) => {
    try {
      console.log(id);

      // Explicitly type `response` as { data: RouteData }
      const response = (await routeApi.getRouteByDay(id)) as {
        data: RouteData;
      };
      const data = response.data;

      console.log(data.message);

      // Set the route data array directly to state
      setRoute(data.data);

      // Log each route's details
      data.data.forEach((route) => {
        console.log(
          `Route id: ${route.id}, Route Name: ${route.name}, Truck Model: ${route.trucks.model}`
        );
      });
    } catch (error) {
      console.log("Error in getRouter:", error);
    }
  };

  const columns = [
    {
      name: "ลำดับ",
      selector: (row: Entry) => row.runNumber,
      sortable: true,
      width: "6%",
    },
    {
      name: "ประเภทการเข้าเก็บขน",
      selector: (row: Entry) =>
        row.type_delivery === 1 ? "รายสัปดาห์" : "รายเดือน",
      sortable: true,
      width: "22%",
    },
    {
      name: "ความถี่/สัปดาห์ที่",
      selector: (row: Entry) =>
        row.frequency !== null
          ? row.frequency === 1
            ? "ทุกสัปดาห์"
            : "สัปดาห์เว้นสัปดาห์"
          : row.cycle !== null
          ? `สัปดาห์ที่ ${row.cycle}`
          : "ไม่ทราบ",
      sortable: true,
      width: "22%",
    },
    {
      name: "วันเข้าเก็บขน",
      selector: (row: Entry) => row.serviceDate,
      sortable: true,
      width: "22%",
    },
    {
      name: "เส้นทางการเดินรถ",
      selector: (row: Entry) => row.route,
      sortable: true,
      width: "22%",
    },
    {
      name: "",
      cell: (row: any) => (
        <button
          className="text-gray-400"
          onClick={() => deleteScheduleByID(row.id)}
        >
          <Trash />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "6%",
    },
  ];

  const customStyles: TableStyles = {
    header: {
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#ffffff",
        backgroundColor: "#3758F9",
        textAlign: "center",
      },
    },
    rows: {
      style: {
        minHeight: "50px", // Override default row height
      },
    },
    headCells: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        padding: "0px",
        color: "#ffffff",
        backgroundColor: "#3758F9",
        textAlign: "center",
        "&:first-child": {
          padding: "0px",
          textAlign: "end",
          borderRadius: "5px 0px 0px 0px",
        },
        "&:nth-child(6)": {
          padding: "0px",
          textAlign: "center",
          borderRadius: "0px 5px 0px 0px",
        },
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        // padding: "12px",
        textAlign: "center", // Center align the text
      },
    },
  };

  return (
    <div className="mx-auto">
      <CustomerDetail
        customer={customer}
        isReadonly={true}
        onBack={() => navigate(-1)}
      />
      <div className="p-4 card rounded-2xl shadow-md my-2">
        <h2 className="text-xl font-semibold mb-4">จัดการวันเข้าเก็บขน</h2>
        <Formik
          initialValues={{
            type_delivery: "",
            frequency: "",
            serviceDate: "",
            route: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            addEntryToTable(values);
            resetForm();
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="mb-4 flex flex-row space-x-4 items-center m-1">
                <div className="basis-1/4">
                  <label className="block text-md font-medium text-gray-700">
                    ประเภทการเข้าเก็บขน :
                  </label>
                </div>
                <div className="basis-3/4 mb-4 flex flex-row space-x-4 items-center m-1">
                  <div className="basis-1/4 mx-2">
                    <label>
                      <Field
                        type="radio"
                        name="type_delivery"
                        value="weekly"
                        className="mx-2"
                      />
                      รายสัปดาห์
                    </label>
                  </div>
                  <div className="basis-1/4 mx-2">
                    <label>
                      <Field
                        type="radio"
                        name="type_delivery"
                        value="monthly"
                        className="mx-2"
                      />
                      รายเดือน
                    </label>
                  </div>
                  <ErrorMessage
                    name="type_delivery"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              <div className="mb-4 flex flex-row space-x-4 items-center m-1">
                <div className="basis-1/4">
                  <label className="block text-md font-medium text-gray-700">
                    ความถี่ในการเข้าเก็บขน :
                  </label>
                </div>
                <div className="basis-3/4 mb-4 flex flex-row space-x-4 items-center m-1">
                  {values.type_delivery === "weekly" ? (
                    <>
                      <label className="basis-1/4 mx-2">
                        <Field
                          type="radio"
                          name="frequency"
                          value="every_week"
                          className="mx-2"
                        />
                        ทุกสัปดาห์
                      </label>
                      <label className="basis-1/4 mx-2">
                        <Field
                          type="radio"
                          name="frequency"
                          value="biweekly"
                          className="mx-2"
                        />
                        สัปดาห์เว้นสัปดาห์
                      </label>
                    </>
                  ) : values.type_delivery === "monthly" ? (
                    <>
                      <label className="basis-1/4 mx-2">
                        <Field
                          type="radio"
                          name="frequency"
                          value="week1"
                          className="mx-2"
                        />
                        สัปดาห์ที่ 1
                      </label>
                      <label className="basis-1/4 mx-2">
                        <Field
                          type="radio"
                          name="frequency"
                          value="week2"
                          className="mx-2"
                        />
                        สัปดาห์ที่ 2
                      </label>
                      <label className="basis-1/4 mx-2">
                        <Field
                          type="radio"
                          name="frequency"
                          value="week3"
                          className="mx-2"
                        />
                        สัปดาห์ที่ 3
                      </label>
                      <label className="basis-1/4 mx-2">
                        <Field
                          type="radio"
                          name="frequency"
                          value="week4"
                          className="mx-2"
                        />
                        สัปดาห์ที่ 4
                      </label>
                    </>
                  ) : null}
                </div>
                <ErrorMessage
                  name="frequency"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4 flex flex-row space-x-4 items-start h-16">
                <div className="basis-1/4 h-full">
                  <label className="block text-md font-medium text-gray-700">
                    วันเข้าเก็บขน
                  </label>
                  <Field
                    as="select"
                    name="serviceDate"
                    className="w-full border p-2 rounded h-10"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const selectedValue = Number(e.target.value);
                      setFieldValue("serviceDate", selectedValue);
                      getRouter(selectedValue);
                    }}
                  >
                    <option value="" disabled>
                      เลือกวันเข้าเก็บขน
                    </option>
                    <option value={0}>อาทิตย์</option>
                    <option value={1}>จันทร์</option>
                    <option value={2}>อังคาร</option>
                    <option value={3}>พุธ</option>
                    <option value={4}>พฤหัสบดี</option>
                    <option value={5}>ศุกร์</option>
                    <option value={6}>เสาร์</option>
                  </Field>
                  <ErrorMessage
                    name="serviceDate"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="basis-2/4 h-full">
                  <label className="block text-md font-medium text-gray-700">
                    เส้นทางการเดินรถ
                  </label>
                  <Field
                    as="select"
                    name="route"
                    className="w-full border p-2 rounded h-10"
                  >
                    <option selected>{"โปรดเลือก"}</option>
                    {routes.map((route) => (
                      <option key={route.id} value={route.id}>
                        {route.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="route"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
                <div className="basis-1/4 h-full">
                  <button
                    type="submit"
                    className="bg-[#13C296] text-[#FFFFFF] p-2 rounded w-28 bottom-0 mt-6"
                  >
                    เพิ่ม
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        {/* Table */}
        <div className="mt-4">
          <DataTable
            columns={columns}
            data={mapCustomerSchedulesToEntries(customerSchedules)}
            customStyles={customStyles}
            noHeader
            pagination
            highlightOnHover
            striped
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerSchedulesForm;
