import apiClient from "./apiClient";
import { GetUserFilter } from "../types/user";
import { getStorage } from "../utils/storage";

const getRoutes = (payload: GetUserFilter) => {
  let filters = "?";
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      if (
        payload[key as keyof GetUserFilter] !== undefined &&
        payload[key as keyof GetUserFilter] !== null &&
        payload[key as keyof GetUserFilter] !== ""
      ) {
        filters += `${encodeURIComponent(key)}=${encodeURIComponent(
          payload[key as keyof GetUserFilter]
        )}&`;
      }
    }
  }
  filters = filters.slice(0, -1);
  const token = getStorage("token");
  return apiClient
    .get(`routes${filters}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const getRouteById = async (id: string) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.get(`routes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    });
    return response;
  } catch (e) {
    console.error("error", e);
    return e;
  }
};

const createRoute = (payload: any) => {
  const token = getStorage("token");
  return apiClient
    .post(`routes`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.error("error", e);
      return e;
    });
};

const updateRouteById = async (id: string, payload: any) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.put(`routes/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (e) {
    console.error("error", e);
    return e;
  }
};

const deleteRouteById = async (id: string) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.delete(`routes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    });
    return response;
  } catch (e) {
    console.error("error", e);
    return e;
  }
};

const getRouteByDay = async (day: number) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.get(`routes?day=${day}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    });
    return response;
  } catch (e) {
    console.error("error", e);
    return e;
  }
};

const userServices = {
  getRoutes,
  getRouteById,
  createRoute,
  updateRouteById,
  deleteRouteById,
  getRouteByDay
};
export default userServices;
