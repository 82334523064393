import { Link } from "react-router-dom";
import LayoutWeb from "../../components/LayoutWeb";
import ProductCategoryTable from "../../components/table/ProductCategoryTable";
import "../../index.css";

const ProductCategory = () => {
  return (
    <>
      <LayoutWeb>
        <div className="p-1">
          <div className="flex justify-between">
            <div>
              <h1 className="text-[24px] text-[#535252] font-bold">
                 รายการหมวดสินค้า
              </h1>
            </div>
            <div>
              <Link to="/setting/create-product-category">
                <button className="bg-[#13C296] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                  สร้างหมวดสินค้า
                </button>
              </Link>
            </div>
          </div>
          <ProductCategoryTable />
        </div>
        
      </LayoutWeb>
    </>
  );
};

export default ProductCategory;
