// components/TableComponent.tsx
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import "react-dropdown/style.css";
import DataTable, { TableStyles } from "react-data-table-component"; // Import TableStyles for typing customStyles
import "./Index.css";
import searchImage from "../../assets/icon/search.png";
import settingProductServices from "../../services/settingProduct";
import { GetProductCategoryFilter } from "../../types/productCategory";
import DropdownTable from "../dropdown/DropDownTable";
import Swal from "sweetalert2";
import { ReactComponent as Iconinfo } from "../../assets/icon/edit_info.svg";
import { ReactComponent as Trash } from "../../assets/icon/trash.svg";


interface TableProps {}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

// Define your custom styles and use TableStyles type
const customStyles: TableStyles = {
  headCells: {
    style: {
      padding: "0px",
      textAlign: "center",
      backgroundColor: "#E5E7EB",
      color: "#535252",
      "&:first-child": {
        padding: "0px",
        textAlign: "center",
        borderRadius: "15px",
      },
      "&:last-child": {
        padding: "0px",
        textAlign: "center",
        borderRadius: "15px",
      },
    },
  },
  headRow: {
    style: {
      backgroundColor: "#E5E7EB",
      color: "#FFFFFF",
      fontSize: "18px",
      fontWeight: "bold",
      position: "sticky",
      top: "0", // Stick to the top
      zIndex: 100, // Ensure it stays above other elements
    },
  },
  cells: {
    style: {
      padding: "12px",
      textAlign: "center",
      "&:first-child": {
        padding: "0px",
        textAlign: "center",
      },
      "&:last-child": {
        padding: "0px",
        textAlign: "center",
      },
    },
  },
  rows: {
    style: {
      minHeight: "50px", // Row height
      "&:hover": {
        backgroundColor: "#f1f5f9", // Hover color
      },
    },
  },
  pagination: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#E2E8F0",
      padding: "10px",
    },
  },
};

const ProductCategoryTable: React.FC<TableProps> = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const columns = [
    {
      name: "หมวดสินค้า",
      selector: (row: any) => row.name,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-left items-center truncate-text text-[15px] pl-[10px]">
          {row.name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (row: any) => (
        <div className="w-[100%] justify-start items-center">
          <DropdownTable
            list={[
              {
                svg: <Iconinfo className="w-6 h-6 mx-2 opacity-60" />,
                path: `/setting/product-category/${row.id}`,
                hoverClass: "hover:bg-[#3758F9] hover:rounded-md hover:text-white",
                label:"แก้ไขข้อมูล",
              },
              {
                svg: <Trash className="w-6 h-6 mx-2 opacity-60" />,
                path: "",
                hoverClass: "hover:bg-red-200 hover:rounded-md hover:text-white",
                label:"ลบ",
                method: () => {handleDelete(row.id)}, // Example method
              },
            ]}
          />
        </div>
      ),
      width: "200px !important",
    },
  ];

  const fetchProductCategory = async (payload: GetProductCategoryFilter) => {
    setLoading(true);
    settingProductServices.getProductCategories(payload).then(
      async (res) => {
        if (res.status === 200) {
          let result: any = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.no = i + 1 + (res.data.page - 1) * res.data.perPage;
            result.push(item);
          }
          await setPage(res.data.page);
          await setData(result);
          await setPerPage(res.data.perPage);
          await setTotalRows(res.data.total);
        }
        if (res.status === 400) {
        }
        setLoading(false);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const handleDelete = async (rowId: number) => {
    setLoading(true);
    await settingProductServices.deleteProductCategoryById(String(rowId)).then(
      async (res: any) => {
        if (res.status === 200) {
          let payload: GetProductCategoryFilter = {
            perPage: perPage,
            page: page,
            search: search,
          };
          await fetchProductCategory(payload); // fetch page 1 of users
        } else {
          let html: string = "Something went wrong"
          if (res.status === 422 && res.response?.data?.message === "InvalidInput") {
            html = "ไม่สามารถลบได้เนื่องจากมีสินค้าที่ใช้หมวดนี้อยู่"
          }
          setLoading(false);
          Swal.fire({
            position: "top",
            title: "ลบข้อมูลไม่สำเร็จ",
            html: html,
            icon: "error",
            confirmButtonColor: "#13C296",
            confirmButtonText: "ปิด",
            footer: "โปรดถ่ายภาพเพื่อความสะดวกในการช่วยเหลือ",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <Spinner />
      <div className="flex justify-center font-bold">Loader...</div>
    </div>
  );

  const handlePageChange = async (page: any) => {
    await setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    await setPerPage(newPerPage);
    await setPage(1);
  };

  const handleInputSearch = async (searchText: string) => {
    await setSearch(searchText);
    let payload: GetProductCategoryFilter = {
      perPage: perPage,
      page: page,
      search: searchText,
    };
    await fetchProductCategory(payload);
  };

  useEffect(() => {
    let payload: GetProductCategoryFilter = {
      perPage: perPage,
      page: page,
      search: search,
    };
    fetchProductCategory(payload); // fetch page 1 of users
  }, [perPage, page]);

  return (
    <div className="body-page">
      <div className="flex justify-between p-2">
        <div className="flex items-center">
        </div>
        <div>
          <div className="flex rounded-md">
            <input
              placeholder="ค้นหา"
              className="border-l-2 border-t-2 border-b-2 pl-4 py-1 rounded-l-xl"
              onBlur={(e) => handleInputSearch(e.target.value)}
            />
            <button className="bg-grey-light-1 rounded-r-md w-10 flex justify-center items-center">
              <img src={searchImage} alt="" className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
      <div className="card rounded-2xl shadow-md my-2">
        <div className="px-4">
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles} // Apply custom styles here
            pagination
            paginationServer
            paginationPerPage={perPage}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<CustomLoader />}
            progressPending={loading}
            noDataComponent={<div className="p-6">ไม่พบข้อมูล</div>}
            persistTableHead
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCategoryTable;
