import { useEffect, useMemo, useState } from "react";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";
import { TabButton } from "../../components/tabs/TabButton";
import { TabCalendar } from "../../components/tabs/TabCalendar";
import { GetRouteFilter } from "../../types/route";
import RouteService from "../../services/routeApi";
import { DAY_OF_WEEK, DAYS, WEEKDAYS } from "../../enum/day";
import { Moment } from "moment";
import DraggableTable, {
  IColumnTable,
} from "../Setting/TruckRoute/components/DraggableTable";
import { ReactComponent as Trash } from "../../assets/icon/trash.svg";
import { ReactComponent as IconPrint } from "../../assets/icon/Print.svg";
import routeWayServices from "../../services/routeWayApi";
import { GetRouteWayFilter, RouteWayData, RouteWayOrders } from "../../types/routeWay";
import CustomerPopUpTable from "../../components/table/CustomerPopUpTable";
import Swal from "sweetalert2";
import { DropdownItem } from "../../components/dropdown/DropDownTable";

const RouteWay = () => {
  const [loadingPage, setLoadingPage] = useState(false);
  const [isOpenAddRouteCustomer, setIsOpenAddRouteCustomer] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const [currentDate, setCurrentDate] = useState("");
  const [currentDay, setCurrentDay] = useState(DAYS.SUNDAY);
  const [currentRoute, setCurrentRoute] = useState("");
  const [routeTabList, setRouteTabList]: any[] = useState([]);
  const [scheduleData, setSchedulesData]: any[] = useState([]);
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [actionLists, setActionLists] = useState<DropdownItem[][]>([]);

  const [truck, setTruck] = useState("");
  const [driver, setDriver] = useState("");
  const [porterOne, setPorterOne] = useState("");
  const [porterTwo, setPorterTwo] = useState("");

  const onClickRoute = async (route_id: string) => {
    if (route_id === currentRoute) {
      return;
    }
    let payload: GetRouteWayFilter = {
      date: currentDate,
      route_id: Number(route_id),
    };
    await getRouteWays(payload);
    await setCurrentRoute(route_id);
  };

  const onClickTabsDay = (date: Moment) => {
    const _day: DAYS = WEEKDAYS[date.day()];
    if (_day === currentDay) {
      return;
    }
    let payload: GetRouteFilter = {
      day: DAY_OF_WEEK[_day],
      perPage: 1000,
      page: 1,
      startDate: "",
      endDate: "",
      search: "",
    };
    fetchRoutes(payload, getCurrentDate(date.toDate()));
    setCurrentDay(_day);
    setCurrentDate(getCurrentDate(date.toDate()));
  };

  const getWeekday = () => {
    const today = new Date().getDay();
    return WEEKDAYS[today];
  };

  const getCurrentDate = (date: Date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const togglePopup = () => {
    setIsOpenAddRouteCustomer(!isOpenAddRouteCustomer);
  };

  const orderRouteCustomer = async () => {
    
    let payload: RouteWayOrders = {
      ids: []
    }
    payload.ids = currentItems.map(item => item.id);
    if (payload.ids.length > 0) {
      await routeWayServices.updateScheduleOrder(payload).then(
        async (res: any) => {
          if (res.status === 200) {
            setReloadData(true);
          }
        },
        (error) => {
          console.log(error.message);
        }
      );
    }
  };

  const columns: IColumnTable[] = useMemo(() => {
    return [
      {
        name: "ลำดับ",
        minSize: 47,
        maxSize: 4.4,
      },
      {
        name: "รหัสลูกค้า",
        minSize: 126,
        maxSize: 11.54,
      },
      {
        name: "ชื่อลูกค้า",
        minSize: 270,
        maxSize: 24.74,
      },
      {
        name: "ชื่อสถานพยาบาล",
        minSize: 270,
        maxSize: 24.74,
      },
      {
        name: "ที่อยู่",
        minSize: 219,
        maxSize: 20.06,
      },
      {
        name: "ตำแหน่งบนแผนที่",
        minSize: 120,
        maxSize: 11.01,
      },
      {
        name: "",
        minSize: 40,
        maxSize: 40,
      },
    ];
  }, []);

  const handleDelete = async (rowId: number) => {
    await routeWayServices.deleteScheduleByID(rowId).then(
      async (res) => {
        if (res.status === 200) {
          setReloadData(true);
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const handleAdd = async (customerID: number) => {
    togglePopup();
    setLoadingPage(true);

    let payload: RouteWayData = {
      date: new Date(currentDate),
      route_id: Number(currentRoute),
      customer_id: customerID,
      is_emergency: true,
    };

    await routeWayServices.createRouteWay(payload).then(
      async (res: any) => {
        if (res.status === 200) {
          setReloadData(true);
        } else {
          setLoadingPage(false);
          Swal.fire({
            position: "top",
            title: "บันทึกข้อมูลไม่สำเร็จ",
            html: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#13C296",
            confirmButtonText: "ปิด",
            footer: "โปรดถ่ายภาพเพื่อความสะดวกในการช่วยเหลือ",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });
        }
      },
      (error) => {
        console.log(error.message);
      }
    );

    setLoadingPage(false);
  };

  const getRouteWays = async (payload: GetRouteWayFilter) => {
    setLoadingPage(true);
    routeWayServices.getRouteWays(payload).then(
      async (res: any) => {
        if (res.status === 200) {
          const resData = res.data.data;
          const data: any[] = [];
          const dropdownItems: DropdownItem[][] = [];
          
          for (const i in resData) {
            data.push({
              id: resData[i]["id"],
              order: resData[i]["priority"],
              no: Number(i) + 1,
              customerId: resData[i]["customer"]["customer_code"],
              customerName:
                resData[i]["customer"]["addresses"][0]["company_name"],
              locationName: resData[i]["customer"]["business_name"],
              address: resData[i]["customer"]["addresses"][0]["address"],
              location: "",
            });

            const dropdownItem: DropdownItem[] = [];
            dropdownItem.push({
              svg: <Trash className="w-6 h-6 mx-2 opacity-60" />,
              path: "",
              hoverClass:
                "hover:bg-red-200 hover:rounded-md hover:text-white",
              label: "ลบ",
              method: () => {
                handleDelete(resData[i]["id"]);
              },
            })
            dropdownItems.push(dropdownItem)
            setActionLists(dropdownItems)
          }

          await setSchedulesData(data);
          setLoadingPage(false);
        }
        if (res.status === 400) {
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const fetchRoutes = async (
    payload: GetRouteFilter,
    currentDateFilter: string
  ) => {
    setLoadingPage(true);
    RouteService.getRoutes(payload).then(
      async (res: any) => {
        if (res.status === 200) {
          if (res.data.data.length > 0) {
            const item = res.data.data[0];
            await setTruck(item["trucks"]["license_plate"]);
            await setDriver(item["drivers"]["full_name"]);
            await setPorterOne(item["porter_ones"]["full_name"] ? item["porter_ones"]["full_name"] : "");
            await setPorterTwo(
              item["porter_twos"] ? item["porter_twos"]["full_name"] : ""
            );
            const data: any[] = [];
            await setCurrentRoute(item["id"]);
            for (const i in res.data.data) {
              data.push({
                name: res.data.data[i]["name"],
                key: res.data.data[i]["id"],
                color: "tab-deep-blue",
              });
            }
            await setRouteTabList(data);
            let payloadSchedule: GetRouteWayFilter = {
              date: currentDateFilter,
              route_id: item["id"],
            };
            await getRouteWays(payloadSchedule);
          }
        } else {
          setLoadingPage(false);
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  useEffect(() => {
    const today = new Date();
    const weekDay = getWeekday();
    setCurrentDay(weekDay);
    setCurrentDate(getCurrentDate(today));
    let payload: GetRouteFilter = {
      day: DAY_OF_WEEK[weekDay],
      perPage: 1000,
      page: 1,
      startDate: "",
      endDate: "",
      search: "",
    };
    fetchRoutes(payload, getCurrentDate(today)); // fetch page 1 of users
  }, []);

  useEffect(() => {
    if (reloadData) {
      let payload: GetRouteWayFilter = {
        date: currentDate,
        route_id: Number(currentRoute),
      };
      getRouteWays(payload);
      setReloadData(false);
    }
  }, [reloadData]);

  return (
    <LayoutWeb>
      {isOpenAddRouteCustomer && (
        <CustomerPopUpTable handleAdd={handleAdd} togglePopup={togglePopup} />
      )}
      <div className="flex justify-between">
        <div className="text-28 px-4 py-2 font-bold text-grey-dark">
          เส้นทางเดินรถ
        </div>

        <div className="flex gap-2">
          {/* <button className="bg-white text-grey-dark font-normal py-2.5 px-4 rounded-lg text-15 border border-grey-dark flex gap-1 items-center">
            <><IconPrint className="w-6 h-6 opacity-60" /><span>แก้ไขรายการสินค้า</span></>
          </button> */}
          <button
            onClick={orderRouteCustomer}
            className="bg-green hover:bg-green-hover text-white font-bold py-2.5 px-4 rounded-lg text-15"
          >
            อัพเดทข้อมูล
          </button>
          <button
            onClick={togglePopup}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2.5 px-4 rounded-lg text-15"
          >
            เพิ่มฉุกเฉิน
          </button>
        </div>
      </div>

      <div className="card rounded-lg shadow-md my-2 p-4 border border-[#99999970]">
        <div className="flex gap-2 flex-col">
          <div className="flex overflow-x-auto gap-2">
            <TabCalendar onClick={onClickTabsDay} />
          </div>
          <div className="flex overflow-x-auto">
            {routeTabList.map((entry: any) => (
              <TabButton
                tabsData={entry}
                currentSelect={currentRoute}
                onClick={() => onClickRoute(entry.key)}
              />
            ))}
          </div>
        </div>

        <div className="my-2 border border-grey-light-1" />
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
            <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 p-4">
              <div className="flex gap-1 font-normal text-15 text-grey-dark">
                <div>รถยนต์ทะเบียน :</div>
                <div>{truck}</div>
              </div>
              <div className="flex gap-1 font-normal text-15 text-grey-dark">
                <div>พนักงานขับรถ :</div>
                <div>{driver}</div>
              </div>
              <div className="flex gap-1 font-normal text-15 text-grey-dark">
                <div>พนักงานเก็บขน(1) :</div>
                <div>{porterOne}</div>
              </div>
              <div className="flex gap-1 font-normal text-15 text-grey-dark">
                <div>พนักงานเก็บขน(2) :</div>
                <div>{porterTwo}</div>
              </div>
            </div>

            <DraggableTable
              tempData={scheduleData}
              columns={columns}
              onItemsChange={setCurrentItems}
              list={actionLists}
            />
          </>
        )}
      </div>
    </LayoutWeb>
  );
};

export default RouteWay;
