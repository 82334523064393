export enum DAYS {
  SUNDAY = "sunday",
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
}

export const DAY_OF_WEEK = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const WEEKDAYS = [
  DAYS.SUNDAY, 
  DAYS.MONDAY, 
  DAYS.THURSDAY, 
  DAYS.WEDNESDAY, 
  DAYS.THURSDAY, 
  DAYS.FRIDAY, 
  DAYS.SATURDAY
]

