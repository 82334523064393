import { cx } from "@emotion/css";

export interface ITabsList {
  name: string;
  key: string;
  color?: string;
}

interface TabButtonProps {
  tabsData: ITabsList;
  currentSelect: string;
  onClick: () => void;
}

export const TabButton = ({
  tabsData,
  currentSelect,
  onClick,
}: TabButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        "min-w-[110px] lg:min-w-[100px] mb-1 pt-2 pb-1 px-4 text-15 font-medium border-b-2 border-transparent text-center cursor-pointer",
        currentSelect === tabsData.key && tabsData.color && `${tabsData.color}`
      )}
    >
      {tabsData.name}
    </div>
  );
};
