import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import settingProductServices from "../../services/settingProduct";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";

import { ProductData, ProductValidate } from "../../types/product";
import Swal from "sweetalert2";
import ProductForm from "../../components/form/ProductForm";

const ProductDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string | undefined }>();
  const [loadingPage, setLoadingPage] = useState(true);
  const [product, setProduct] = useState<ProductData>({
    product_category_id: 0,
    name: "",
    description: "",
    product_prices: [],
  });
  const [productValidate, setProductValidate] = useState<ProductValidate>({product_prices: []});

  const fetchProductById = async () => {
    setLoadingPage(true);
    settingProductServices.getProductById(String(id)).then(
      async (res: any) => {
        if (res.status === 200) {
          const item = res.data.data;
          Object.entries(item).map(([key, value]) => (
            setProduct((prevFields) => ({
              ...prevFields,
              [key]: value
            }))
          )); 
          setLoadingPage(false);
        } else {
          setLoadingPage(false);
          Swal.fire({
            position: "top",
            title: "ดึงข้อมูลไม่สำเร็จ",
            html: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#13C296",
            confirmButtonText: "ปิด",
            footer: "โปรดถ่ายภาพเพื่อความสะดวกในการช่วยเหลือ",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/setting/product`, { replace: true });
            }
          });
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const handleSubmit = async (data: ProductData) => {
    setLoadingPage(true);
    setProductValidate({product_prices: []});
    
    let payload: ProductData = {
      product_category_id: data.product_category_id,
      name: data.name,
      description: data.description,
      product_prices: data.product_prices,
    };
    
    settingProductServices.updateProductById(String(id), payload).then(
      async (res: any) => {
        if (res.status === 200) {
          navigate(`/setting/product`, { replace: true });
        } else if (res.status === 422 && res.response?.data?.message === "InvalidInput") {
          res.response.data.errors.map((element: any) => {
            let message: string = element.message
            if (message === "duplicate") {
              message = "ชื่อหมวดสินค้าซ้ำ"
            }
            setProductValidate((prevFields) => ({
              ...prevFields,
              [element.field]: message
            }));        
          });   
          
          Object.entries(data).map(([key, value]) => (
            setProduct((prevFields) => ({
              ...prevFields,
              [key]: value
            }))
          ));  
          
          setLoadingPage(false);
        } else {
          setLoadingPage(false);
          Swal.fire({
            position: "top",
            title: "แก้ไขข้อมูลไม่สำเร็จ",
            html: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#13C296",
            confirmButtonText: "ปิด",
            footer: "โปรดถ่ายภาพเพื่อความสะดวกในการช่วยเหลือ",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  useEffect(() => {
    fetchProductById();
  }, [id]);
  return (
    <>
      <LayoutWeb>
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
            <ProductForm
              data={product}
              handleSubmit={handleSubmit}
              validation={productValidate}
            />
          </>
        )}
      </LayoutWeb>
    </>
  );
};

export default ProductDetail;
