import React from "react";
import { ReactComponent as NameTag } from "../../assets/icon/name_tag.svg";
import SelectionListComponent from "./SelectionListComponent";
import { CustomerData } from "../../types/customer";

interface CustomerDetailProps {
  customer: CustomerData | null;
  onBack: () => void;
  isReadonly?: boolean; // Add a prop to control the behavior
}

const haederCustomer: React.FC<CustomerDetailProps> = ({
  customer,
  onBack,
  isReadonly = false, // Default to false (normal behavior)
}) => {
  const status_items = [
    {
      classNameActive: "bg-[#CCFBF1] text-[#115E59]",
      classNameDropdown:
        "bg-[#FFFFFF] text-[#6B7280] hover:bg-[#CCFBF1] hover:text-[#115E59]",
      label: "ปกติ",
      onClick: () => console.log("Normal selected"),
      isActive: customer?.status === 0,
      status: 0, // Add a status property for matching
    },
    {
      classNameActive: "bg-[#FEE2E2] text-[#991B1B]",
      classNameDropdown:
        "bg-[#FFFFFF] text-[#6B7280] hover:bg-[#FEE2E2] hover:text-[#991B1B]",
      label: "ยกเลิก",
      onClick: () => console.log("Cancelled selected"),
      isActive: customer?.status === 1,
      status: 1, // Add a status property for matching
    },
    {
      classNameActive: "bg-[#FEF9C3] text-[#854D0E]",
      classNameDropdown:
        "bg-[#FFFFFF] text-[#6B7280] hover:bg-[#FEF9C3] hover:text-[#854D0E]",
      label: "งดเก็บ",
      onClick: () => console.log("Paused selected"),
      isActive: customer?.status === 2,
      status: 2, // Add a status property for matching
    },
  ];

  // Determine items to display based on isReadonly prop
  const items_to_display = isReadonly
    ? status_items.filter((item) => item.status === customer?.status) // Filtered items for readonly mode
    : status_items; // All items for normal mode

  return (
    <div className="flex justify-between items-center">
      <div className="grid grid-rows-2 grid-flow-col items-center text-blue-600 text-xl">
        <div className="flex items-center -mb-5">
          <NameTag />
          <span className="ml-2 text-sm">รายละเอียดข้อมูลลูกค้า</span>
        </div>
        <div className="flex items-center text-2xl text-blue-600">
          {customer ? (
            <>
              {customer?.customer_code} {customer?.business_name}
              <span className="bg-green-100 text-green-700 px-3 py-1 rounded-full text-sm w-28">
                <SelectionListComponent items={items_to_display} />
              </span>
            </>
          ) : (
            <span className="text-gray-500 text-sm animate-pulse">
              Loading customer data
            </span>
          )}
        </div>
      </div>
      <div>
        <button
          onClick={onBack}
          className="border border-gray-400 text-gray-700 px-4 py-1 rounded-lg"
        >
          ปิดหน้าต่าง
        </button>
      </div>
    </div>
  );
};

export default haederCustomer;
