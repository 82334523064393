import apiClient from "./apiClient";
import { getStorage } from "../utils/storage";
import { GetRouteWayFilter, RouteWayOrders } from "../types/routeWay";

const getRouteWays = (payload: GetRouteWayFilter) => {
  let filters = "?";
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      if (
        payload[key as keyof GetRouteWayFilter] !== undefined &&
        payload[key as keyof GetRouteWayFilter] !== null &&
        payload[key as keyof GetRouteWayFilter] !== ""
      ) {
        filters += `${encodeURIComponent(key)}=${encodeURIComponent(
          payload[key as keyof GetRouteWayFilter]
        )}&`;
      }
    }
  }
  filters = filters.slice(0, -1);
  const token = getStorage("token");
  return apiClient
    .get(`route-customers${filters}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const createRouteWay = async (payload: any) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.post(`route-customers`, payload, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

const deleteScheduleByID = async (id: number) => {
  const token = getStorage("token");
  return apiClient
    .delete(`route-customers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const updateScheduleOrder = async (payload: RouteWayOrders) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.post(
      `route-customers/order`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

const routeWayServices = {
  getRouteWays,
  createRouteWay,
  deleteScheduleByID,
  updateScheduleOrder,
};
export default routeWayServices;
