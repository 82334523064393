import { cx } from "@emotion/css";
import { useDrag, useDrop } from "react-dnd";
import { ReactComponent as IconMove } from "../../../../../../assets/icon/icon-move.svg";
import DropdownTable, { DropdownItem } from "../../../../../../components/dropdown/DropDownTable";

enum DRAG_TABLE {
  ROW = "row",
}

interface DnDRowProps {
  row: Object;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  list: DropdownItem[]
}

const DnDRow = ({ row, index, moveRow, list }: DnDRowProps) => {
  const [{ isDragging }, drag] = useDrag({
    type: DRAG_TABLE.ROW,
    item: { index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: DRAG_TABLE.ROW,
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveRow(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <tr
      ref={(node) => drag(drop(node))}
      className={cx(
        "border-b transition-colors hover:bg-gray-50 h-[50px]",
        isDragging ? "opacity-50 bg-blue-300" : ""
      )}
    >
      <td className="px-2.5 py-3 text-center">
        <IconMove className="flex items-center w-5 h-5" />
      </td>

      {Object.entries(row).map(([key, value]) =>
        key === "id" || key === "order" ? (
          <></>
        ) : (
          <td
            key={key}
            className={cx(
              "mx-auto px-2.5 py-3 items-center text-15 text-grey-dark font-light",
              key === "no" && "text-center"
            )}
          >
            {value}
          </td>
        )
      )}
      { list ?
        (<DropdownTable list={list} />) : (null)
      }
      
    </tr>
  );
};

export default DnDRow;
