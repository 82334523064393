import React, { useState } from 'react';

interface SelectionItem {
  isActive?: unknown;
  classNameActive?: string;
  classNameDropdown: string;
  label: string;
  onClick: () => void;
}

interface SelectionDropdownProps {
  items: SelectionItem[];
}

const SelectionDropdown: React.FC<SelectionDropdownProps> = ({ items }) => {
  const [activeItem, setActiveItem] = useState<SelectionItem | null>(
    items.find((item) => item.isActive) || items[0]
  );

  const handleItemClick = (item: SelectionItem) => {
    setActiveItem(item);
    item.onClick(); // Execute the item's onClick method
  };

  return (
    <div className="relative inline-block w-full">
      {/* Dropdown items using <select> */}
      <select
        className={`text-center py-1 rounded-md cursor-pointer w-full ${activeItem?.classNameActive}`}
        value={activeItem?.label}
        onChange={(e) => {
          const selectedItem = items.find((item) => item.label === e.target.value);
          if (selectedItem) handleItemClick(selectedItem);
        }}
      >
        {items.map((item, index) => (
          <option
            key={index}
            value={item.label}
            className={item.classNameDropdown}
          >
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectionDropdown;
