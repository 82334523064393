import React, { useEffect, useState } from "react";
import ApiService from "../../services/truckApi";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";
import { useNavigate } from "react-router-dom";

const Create: React.FC = () => {
  const [loadingPage, setLoadingPage] = useState(false);
  const navigate = useNavigate();
  const [licensePlate, setLicensePlate] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [loadCapacity, setLoadCapacity] = useState(0);
  const [truckType, setTruckType] = useState("small");

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingPage(true);
    const payload = new FormData();
    payload.append("license_plate", licensePlate);
    payload.append("brand", brand);
    payload.append("model", model);
    payload.append("load_capacity", String(loadCapacity));
    payload.append("type", String(truckType));

    ApiService.createTruck(payload).then(
      async (res: any) => {
        if (res.status === 200) {
          console.log(res);
          navigate(`/setting/truck/${res.data.data["id"]}`, { replace: true });
        } else {
          setLoadingPage(false);
        }
      },
      (error: any) => {
        console.log(error.message);
      }
    );
  };

  const handleCancel = () => {
    navigate(`/setting/truck`, { replace: true });
  };

  return (
    <>
      <LayoutWeb>
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
            <form onSubmit={onSubmit}>
              <div className="p-1">
                <div className="flex justify-between">
                  <div>
                    <h1 className="text-[24px] text-[#3B82F6] font-bold">
                      สร้างข้อมูลรถยนต์
                    </h1>
                  </div>
                  <div className="flex">
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="mx-2 w-36 h-10 rounded-lg text-lg font-semibold leading-6 text-[#6B7280] bg-white border  border-[#6B7280]  px-3 py-1 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      ยกเลิก
                    </button>
                    <button
                      type="submit"
                      className="mx-2 w-36 h-10 rounded-lg px-3 py-1 text-lg font-semibold text-white shadow-sm bg-[#13C296] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      บันทึกข้อมูล
                    </button>
                  </div>
                </div>
                <div className="card rounded-lg shadow-md m-4">
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                      <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 p-2">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="username"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            ทะเบียนรถยนต์:
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="license_plate"
                              id="license_plate"
                              value={licensePlate}
                              onChange={(e) => setLicensePlate(e.target.value)}
                              autoComplete="license_plate"
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              required
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            ยี่ห้อรถยนต์:
                          </label>
                          <div className="mt-2">
                            <input
                              id="brand"
                              name="brand"
                              type="text"
                              value={brand}
                              onChange={(e) => setBrand(e.target.value)}
                              autoComplete="brand"
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              required
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="full-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            รุ่นรถยนต์:
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="model"
                              id="model"
                              value={model}
                              onChange={(e) => setModel(e.target.value)}
                              autoComplete="model"
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              required
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="full-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            จำนวนน้ำหนักที่บรรทุกได้(กิโลกรัม):
                          </label>
                          <div className="mt-2">
                            <input
                              type="number"
                              name="Load_capacity"
                              id="Load_capacity"
                              value={loadCapacity}
                              onChange={(e) =>
                                setLoadCapacity(Number(e.target.value))
                              }
                              autoComplete="Load_capacity"
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              required
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3 px-2">
                          <label
                            htmlFor="status"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            ประเภทรถยนต์:
                          </label>
                          <div className="mt-2">
                            <select
                              id="truck-type"
                              name="truck-type"
                              value={truckType}
                              onChange={(e) => setTruckType(e.target.value)}
                              autoComplete="truck-type"
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value="small">รถเล็ก</option>
                              <option value="big">รถใหญ่</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </LayoutWeb>
    </>
  );
};

export default Create;
