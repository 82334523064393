import React from "react";

interface UserTabMenuProps {
  tabMenu: string;
  setTabMenu: (tab: string) => void;
}

const UserTabMenu: React.FC<UserTabMenuProps> = ({ tabMenu, setTabMenu }) => {
  const activateTabClass =
    "inline-flex items-center justify-center p-4 px-6 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group shadow-sm";
  const activateIconClass = "w-4 h-4 me-2 text-blue-600 dark:text-blue-500";
  const defaultTabClass =
    "inline-flex items-center justify-center p-4 px-6 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group";
  const defaultIconClass =
    "w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300";

  return (
    <div className="border-b border-gray-200 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        <li className="">
          <button
            onClick={() => setTabMenu("Profile")}
            className={
              tabMenu === "Profile" ? activateTabClass : defaultTabClass
            }
          >
            <svg
              className={
                tabMenu === "Profile" ? activateIconClass : defaultIconClass
              }
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            Profile
          </button>
        </li>
        <li className="me-2">
          <button
            onClick={() => setTabMenu("Permission")}
            className={
              tabMenu === "Permission" ? activateTabClass : defaultTabClass
            }
          >
            <svg
              className={
                tabMenu === "Permission" ? activateIconClass : defaultIconClass
              }
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
            </svg>
            Permission
          </button>
        </li>
      </ul>
    </div>
  );
};

export default UserTabMenu;
