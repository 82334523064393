import React, { useEffect, useState } from "react";
import { ProductCategoryData, ProductCategoryProps, ProductCategoryValidate } from "../../types/productCategory";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as IconPencil } from "../../assets/icon/Pencil.svg";
import { ReactComponent as IconBoxes } from "../../assets/icon/Boxes.svg";

const ProductCategoryForm: React.FC<ProductCategoryProps> = ({
  data,
  handleSubmit,
  validation
}) => {
  const { id } = useParams<{ id: string | undefined }>();
  const [productCategory, setProductCategory] = useState<ProductCategoryData>({
    name: data.name,
  });
  const [errors, setErrors] = useState<ProductCategoryValidate>({});

  useEffect(() => {
    Object.entries(validation).map(([key, value]) => (
      setErrors((prevFields) => ({
        ...prevFields,
        [key]: value
      }))
    ));
  }, [validation]);
  
  const handleSubmitForm = () => {
    let validationErrors: ProductCategoryValidate = {};

    if (!productCategory.name.trim()) {
      validationErrors.name = 'กรุณาระบุชื่อหมวดสินค้า';
    }
    
    Object.entries(validationErrors).map(([key, value]) => (
      setErrors((prevFields) => ({
        ...prevFields,
        [key]: value
      }))
    ));

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      handleSubmit({
        name: productCategory.name
      });
    }
  };

  return (
    <div className="p-1">
      <div className="flex justify-between">
        <div>
          <div className="text-[26px] text-grey-dark font-normal flex">
           {id ? (<><IconPencil className="w-6 h-6 opacity-60" />แก้ไขหมวดสินค้า</>) : (<><IconBoxes className="w-6 h-6 opacity-60" />สร้างหมวดสินค้า</>)}
          </div>
        </div>
        <div>
            <Link to="/setting/product-category">
              <button className="bg-white hover:bg-white text-[#535252] mr-2 border border-[#6B7280] font-bold py-2 px-4 rounded">
              ยกเลิก
              </button>
            </Link>
            <button 
              onClick={handleSubmitForm}
              className="bg-[#13C296] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              บันทึกข้อมูล
            </button>
        </div>
      </div>
      <div className="card rounded-lg shadow-md m-4">
        <div className="p-4">
          <div className="border-gray-900/10">
            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  ชื่อหมวดสินค้า:
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={productCategory.name}
                    onChange={(e) => setProductCategory({name:e.target.value})}
                    autoComplete="name"
                    placeholder="ระบุชื่อหมวดสินค้า"
                    className="block w-full ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.name && <p className="text-red-500 text-sm mb-2">{errors.name}</p>}
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default ProductCategoryForm;
