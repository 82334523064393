import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RouteService from "../../services/routeApi";
import UserService from "../../services/userApi";
import TruckService from "../../services/truckApi";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";

import { GetTruckFilter } from "../../types/truck";
import { GetUserFilter } from "../../types/user";

const UserDetail: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const [loadingPage, setLoadingPage] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [truckLoading, setTruckLoading] = useState(true);
  const [routeLoading, setRouteLoading] = useState(true);
  const [truckList, setTruckList]: any = useState(null);
  const [employeeList, setEmployeeList]: any = useState(null);
  const [name, setName] = useState("");
  const [day, setDay] = useState("0");
  const [truckId, setTruckId]: any = useState(null);
  const [driverId, setDriverId]: any = useState(null);
  const [porterOneId, setPorterOneId]: any = useState(null);
  const [porterTwoId, setPorterTwoId]: any = useState(null);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingPage(true);
    const payload = new FormData(); // Create a new FormData object

    payload.append("name", name);
    payload.append("day", day);
    if (truckId) {
      payload.append("truck_id", String(truckId));
    }
    if (driverId) {
      payload.append("driver_id", String(driverId));
    }
    if (porterOneId) {
      payload.append("porter_one_id", String(porterOneId));
    }
    if (porterTwoId) {
      payload.append("porter_two_id", String(porterTwoId));
    }

    RouteService.updateRouteById(String(id), payload).then(
      async (res: any) => {
        console.log(res);
        if (res.status === 200) {
          await fetchById();
        } else {
          setLoadingPage(false);
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
    // Handle form submission (e.g., make an API call to update the user profile)
  };

  const getUser = async () => {
    setUserLoading(true);
    let payload: GetUserFilter = {
      perPage: 10000,
      page: 1,
      startDate: "",
      endDate: "",
      search: "",
    };
    UserService.getUsers(payload).then(
      async (res: any) => {
        console.log(res);
        if (res.message === "Unauthorized") {
          // dispatch(logout());
          // navigate("/login", { replace: true });
        }
        if (res.status === 200) {
          await setEmployeeList(res.data.data);
        }
        await setUserLoading(false);
      },
      (error: any) => {
        console.log(error.message);
      }
    );
  };

  const getTruck = async () => {
    setTruckLoading(true);
    let payload: GetTruckFilter = {
      perPage: 10000,
      page: 1,
      startDate: "",
      endDate: "",
      search: "",
    };
    TruckService.getTrucks(payload).then(
      async (res: any) => {
        console.log(res);
        if (res.message === "Unauthorized") {
          // dispatch(logout());
          // navigate("/login", { replace: true });
        }
        if (res.status === 200) {
          await setTruckList(res.data.data);
        }
        await setTruckLoading(false);
      },
      (error: any) => {
        console.log(error.message);
      }
    );
  };

  const fetchById = async () => {
    setRouteLoading(true);
    RouteService.getRouteById(String(id)).then(
      async (res: any) => {
        if (res.status === 200) {
          const item = res.data.data;
          await setName(item["name"]);
          await setDay(item["day"]);
          await setTruckId(item["truck_id"]);
          await setDriverId(item["driver_id"]);
          await setPorterOneId(item["porter_one_id"]);
          await setPorterTwoId(item["porter_two_id"]);
          setRouteLoading(false);
        }
        if (res.status === 400) {
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  useEffect(() => {
    fetchById();
    getUser();
    getTruck();
  }, [id]);

  useEffect(() => {
    if (!userLoading && !truckLoading && !routeLoading) {
      setLoadingPage(false);
    }
  }, [userLoading, truckLoading, routeLoading]);

  return (
    <>
      <LayoutWeb>
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
            <form onSubmit={onSubmit}>
              <div className="p-1">
                <div className="flex justify-between">
                  <div>
                    <h1 className="text-[24px] text-[#3B82F6] font-bold">
                      สร้างเส้นทางเดินรถ
                    </h1>
                  </div>
                  <div className="flex">
                    <button
                      type="button"
                      onClick={() => window.location.reload()}
                      className="mx-2 w-36 h-10 rounded-lg text-lg font-semibold leading-6 text-[#6B7280] bg-white border  border-[#6B7280]  px-3 py-1 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      ยกเลิก
                    </button>
                    <button
                      type="submit"
                      className="mx-2 w-36 h-10 rounded-lg px-3 py-1 text-lg font-semibold text-white shadow-sm bg-[#13C296] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      บันทึกข้อมูล
                    </button>
                  </div>
                </div>
                <div className="card rounded-lg shadow-md m-4">
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                      <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 p-2">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="username"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            ชื่อเส้นทาง:
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="route_name"
                              id="route_name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              autoComplete="route_name"
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              required
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="status"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            วันที่เข้าเก็บ:
                          </label>
                          <div className="mt-2">
                            <select
                              id="is-activated"
                              name="is-activated"
                              value={day}
                              onChange={(e) => setDay(e.target.value)}
                              autoComplete="is-activated-name"
                              className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value="0">วันอาทิตย์</option>
                              <option value="1">วันจันทร์</option>
                              <option value="2">วันอังคาร</option>
                              <option value="3">วันพุธ</option>
                              <option value="4">วันพฤหัสบดี</option>
                              <option value="5">วันศุกร์</option>
                              <option value="6">วันเสาร์</option>
                            </select>
                          </div>
                        </div>
                        {truckList ? (
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="status"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              รถยนต์ทะเบียน
                            </label>
                            <div className="mt-2">
                              <select
                                id="is-activated"
                                name="is-activated"
                                value={String(truckId)}
                                onChange={(e) => setTruckId(e.target.value)}
                                autoComplete="is-activated-name"
                                className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option value="">กรุณาเลือกรถ</option>
                                {truckList.map((truck: any) => (
                                  <option key={truck.id} value={truck.id}>
                                    {truck.license_plate}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {employeeList ? (
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="status"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              พนักงานขับรถ:
                            </label>
                            <div className="mt-2">
                              <select
                                id="is-activated"
                                name="is-activated"
                                value={String(driverId)}
                                onChange={(e) => setDriverId(e.target.value)}
                                autoComplete="is-activated-name"
                                className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option value="">กรุณาเลือกคนขับรถ</option>
                                {employeeList.map((employee: any) => (
                                  <option key={employee.id} value={employee.id}>
                                    {employee.full_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {employeeList && driverId !== null ? (
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="status"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              พนักงานเก็บขน(1):
                            </label>
                            <div className="mt-2">
                              <select
                                id="is-activated"
                                name="is-activated"
                                value={String(porterOneId)}
                                onChange={(e) => setPorterOneId(e.target.value)}
                                autoComplete="is-activated-name"
                                className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option value="">กรุณาเลือกพนักงาน</option>
                                {employeeList.map((employee: any) =>
                                  String(driverId) !== String(employee.id) ? (
                                    <option
                                      key={employee.id}
                                      value={employee.id}
                                    >
                                      {employee.id}
                                      {employee.full_name}
                                    </option>
                                  ) : null
                                )}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {employeeList && porterOneId !== null ? (
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="status"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              พนักงานเก็บขน(2):
                            </label>
                            <div className="mt-2">
                              <select
                                id="is-activated"
                                name="is-activated"
                                value={String(porterTwoId)}
                                onChange={(e) => setPorterTwoId(e.target.value)}
                                autoComplete="is-activated-name"
                                className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option value="">กรุณาเลือกพนักงาน</option>
                                {employeeList.map((employee: any) =>
                                  String(driverId) !== String(employee.id) &&
                                  String(porterOneId) !==
                                    String(employee.id) ? (
                                    <option
                                      key={employee.id}
                                      value={employee.id}
                                    >
                                      {employee.id}
                                      {employee.full_name}
                                    </option>
                                  ) : null
                                )}
                              </select>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </LayoutWeb>
    </>
  );
};

export default UserDetail;
