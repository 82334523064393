import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserService from "../../services/userApi";
import PermissionService from "../../services/permissionApi";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";
import UserTabMenu from "../../components/tabmenu/UserTab";
import { useNavigate } from "react-router-dom";

import UserProfileTab from "../../components/tabdetail/UserProfileTab";

import { UserProfileData } from "../../types/user";

const Create: React.FC = () => {
  const [loadingPage, setLoadingPage] = useState(false);
  const navigate = useNavigate();
  const [tabMenu, setTabMenu] = useState("Profile");
  const [profile, setProfile] = useState<UserProfileData>({
    username: "",
    password: "",
    fullName: "",
    email: "",
    isActivated: true,
    profileImage: null,
    imageUrl: "",
  });
  const handleUpdateProfileForm = async (formData: UserProfileData) => {
    setLoadingPage(true);
    const payload = new FormData();
    payload.append("username", formData.username);
    payload.append("password", formData.password);
    payload.append("email", formData.email);
    payload.append("full_name", formData.fullName);
    payload.append("is_activated", formData.isActivated ? "1" : "0");
    if (formData.profileImage) {
      payload.append("images", formData.profileImage); // Append the file
    }
    UserService.createUser(payload).then(
      async (res: any) => {
        if (res.status === 200) {
          console.log(res);
          navigate(`/users/${res.data.data["id"]}`, { replace: true });
        } else {
          setLoadingPage(false);
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  return (
    <>
      <LayoutWeb>
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
            <div className="p-1">
              <h1 className="text-[24px] text-[#3B82F6] font-bold">
                สร้างข้อมูลผู้ใช้งานระบบ
              </h1>
              <div className="card rounded-lg shadow-md m-4">
                <UserTabMenu tabMenu={"tabMenu"} setTabMenu={setTabMenu} />
                <div className="p-4">
                  <UserProfileTab
                    isCreate={true}
                    data={profile}
                    handleSubmit={handleUpdateProfileForm}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </LayoutWeb>
    </>
  );
};

export default Create;
