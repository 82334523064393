import React, { ReactNode, useState } from "react";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";

interface LayoutWebProps {
  children: ReactNode;
}

const LayoutWeb: React.FC<LayoutWebProps> = ({ children }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <>
      <div className="max-w-ful flex flex-row min-h-screen bg-blue-800/95 font-kanit">
        <Sidebar isSubMenuOpen={isSubMenuOpen} />

        <div className="w-full flex-col bg-[#fafafa] rounded-l-[30px] overflow-x-hidden">
          <Navbar handleSubMenuToggle={handleSubMenuToggle} />
          <div className="flex flex-1">
            <main className="flex-1 p-4 overflow-x-auto">{children}</main>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutWeb;
