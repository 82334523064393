import React, { useState } from "react";
import "./ThaiDatePicker.css";
import {
  showDatetimeFormatTh,
  showDatetimeFormat,
  getDate,
} from "../../utils/date";

interface ThaiDatePickerProps {
  value: string;
  onDateSelect: (date: string) => void;
}

const ThaiDatePicker: React.FC<ThaiDatePickerProps> = ({
  value,
  onDateSelect,
}) => {
  const dateValue = getDate(value);
  const [selectedDate, setSelectedDate] = useState<string>(
    showDatetimeFormatTh(dateValue)
  );
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth()
  );
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear() + 543
  ); // ปี พ.ศ.

  const daysInMonth = (month: number, year: number): number => {
    return new Date(year - 543, month + 1, 0).getDate();
  };

  const getStartDayOfWeek = (month: number, year: number): number => {
    return new Date(year - 543, month, 1).getDay();
  };

  const handleSetMonth = (monthStep: number) => {
    if (monthStep < 0) {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
      return;
    }
    if (monthStep > 11) {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(0);
      return;
    }
    setCurrentMonth(monthStep);
    return;
  };

  const handleDateClick = (day: number) => {
    const dayString = String(day).padStart(2, "0");
    const monthString = String(currentMonth + 1).padStart(2, "0");
    const selectedDateChange = new Date(
      `${currentYear - 543}-${monthString}-${dayString}T00:00:00`
    );
    const formattedDate = `${dayString}-${monthString}-${currentYear}`;
    setSelectedDate(formattedDate);
    onDateSelect(showDatetimeFormat(selectedDateChange));
    setShowCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const daysOfWeek = ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"];

  const thaiMonths = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1; // เดือนก่อนหน้า
  const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear; // ปีของเดือนก่อนหน้า
  const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1; // เดือนถัดไป
  const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear; // ปีของเดือนถัดไป

  const prevMonthDays = daysInMonth(prevMonth, prevYear); // จำนวนวันในเดือนก่อนหน้า
  const startDayOfWeek = getStartDayOfWeek(currentMonth, currentYear); // วันเริ่มต้นของเดือนปัจจุบัน
  const currentMonthDays = daysInMonth(currentMonth, currentYear); // จำนวนวันในเดือนปัจจุบัน

  const totalDaysInWeek = 7;
  const remainingDaysAfterCurrentMonth =
    (totalDaysInWeek -
      ((startDayOfWeek + currentMonthDays) % totalDaysInWeek)) %
    totalDaysInWeek; // คำนวณวันที่เหลือในสัปดาห์หลังจากสิ้นเดือน

  return (
    <div
      className="date-picker"
      onBlur={() => setTimeout(() => setShowCalendar(false), 200)}
    >
      <div className="input-wrapper">
        <input
          type="text"
          readOnly
          value={selectedDate}
          onClick={toggleCalendar}
          placeholder="เลือกวันที่"
          className="date-input text-[#637381]"
        />
        <div className="calendar-icon" onClick={toggleCalendar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#637381"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="icon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 7V3m8 4V3m-6 0h6M4 7h16a2 2 0 012 2v11a2 2 0 01-2 2H4a2 2 0 01-2-2V9a2 2 0 012-2z"
            />
          </svg>
        </div>
      </div>
      {showCalendar && (
        <div className="calendar">
          <div className="header">
            <button
              onClick={() => handleSetMonth(currentMonth - 1)}
              className="nav-button"
            >
              &lt;
            </button>
            <span className="current-month">{`${thaiMonths[currentMonth]} ${currentYear}`}</span>
            <button
              onClick={() => handleSetMonth(currentMonth + 1)}
              className="nav-button"
            >
              &gt;
            </button>
          </div>
          <div className="days-of-week">
            {daysOfWeek.map((day, index) => (
              <div key={index} className="day-of-week">
                {day}
              </div>
            ))}
          </div>
          <div className="days">
            {/* เติมวันที่ของเดือนก่อนหน้า */}
            {Array.from({ length: startDayOfWeek }, (_, index) => (
              <div key={index} className="prev-month-day">
                {prevMonthDays - startDayOfWeek + index + 1}
              </div>
            ))}
            {/* วันที่ของเดือนปัจจุบัน */}
            {Array.from({ length: currentMonthDays }, (_, index) => {
              const day = index + 1;
              const isSelected =
                selectedDate ===
                `${String(day).padStart(2, "0")}-${String(
                  currentMonth + 1
                ).padStart(2, "0")}-${currentYear}`;
              return (
                <button
                  key={day}
                  onClick={() => handleDateClick(day)}
                  className={`day-button ${isSelected ? "selected" : ""}`}
                >
                  {day}
                </button>
              );
            })}
            {/* เติมวันที่ของเดือนถัดไป */}
            {Array.from(
              { length: remainingDaysAfterCurrentMonth },
              (_, index) => (
                <div key={index} className="next-month-day">
                  {index + 1}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ThaiDatePicker;
