import React, { useState } from "react";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";
import CreateNewCustomerForm from "../../components/form/CreateNewCustomerForm";

const Create: React.FC = () => {
  const [loadingPage, setLoadingPage] = useState(false);
  return (
    <LayoutWeb>
      {loadingPage ? (
        <LoaderPage />
      ) : (
        <>
        <div className="flex justify-between">
            <div className="">
              <h1 className="text-3xl font-bold text-blue-600 py-2">
                เพิ่มข้อมูลลูกค้าใหม่
              </h1>
            </div>
          </div>
          <div className="card rounded-2xl shadow-md my-2 p-4 bg-white">
            <CreateNewCustomerForm />
          </div>
          {/* <div className="card rounded-2xl shadow-md my-2 p-4">
            <CreateNewCustomerMapData/>
          </div>
          <div className="card rounded-2xl shadow-md my-2 p-4">
            <CreateNewCustomerProductPrice/>
          </div>  */}
        </>
      )}
    </LayoutWeb>
  );
};

export default Create;
