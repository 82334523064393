import React, { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DnDRow from "./DnDRow";
import { DropdownItem } from "../../../../../components/dropdown/DropDownTable";

export interface RowData {
  // Recommended to use no. when dragging row to show on hover row table
  no: string;
}

export interface IColumnTable {
  name: string;
  minSize: number;
  maxSize: number;
}

interface DraggableTableProps {
  tempData: RowData[];
  columns: IColumnTable[];
  onItemsChange?: (items: RowData[]) => void;
  list: DropdownItem[][]
}

const DraggableTable: React.FC<DraggableTableProps> = ({
  tempData,
  columns,
  onItemsChange,
  list,
}) => {
  const [items, setItems] = useState<RowData[]>(tempData);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const draggedRow = items[dragIndex];
      const newItems = [...items];
      newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, draggedRow);
      setItems(newItems);
    },
    [items]
  );

  useEffect(() => {
    onItemsChange?.(items);
  }, [items, onItemsChange]);

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <div className="w-full overflow-x-scroll">
        <table className="w-full border-collapse bg-white shadow-sm rounded-lg overflow-hidden">
          <thead className="bg-grey-light-1">
            <tr className="h-[30px]">
              <th className="text-center w-10"></th>
              {Object.entries(columns).map(([key, value]) => (
                <th
                  key={`route-setting-${key}-`}
                  className="text-center text-15 font-normal"
                  style={{
                    width: `${value.maxSize}%`,
                    minWidth: `${value.minSize}px`,
                  }}
                >
                  {value.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <DnDRow
                key={item.no}
                row={item}
                index={index}
                moveRow={moveRow}
                list={list[index]}
              />
            ))}
          </tbody>
        </table>
      </div>
    </DndProvider>
  );
};

export default DraggableTable;
