import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AuthService from "../../services/authApi";
import { logout } from "../../stores/slices/Auth";
import ChangePasswordForm from "../../components/form/ChangePasswordForm"; // Import LoginForm component
import LayoutWeb from "../../components/LayoutWeb";
import "../../index.css";

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state: any) => state.auth);

  const handleChangePassword = async (e: any) => {
    e.preventDefault();
    const fromInput = e.currentTarget.elements;
    console.log(
      fromInput.current_password.value,
      fromInput.new_password.value,
      fromInput.confirm_password.value
    );
    const current_password = fromInput.current_password.value;
    const new_password = fromInput.new_password.value;
    const confirm_password = fromInput.confirm_password.value;
    if (new_password !== confirm_password) {
      return;
    }
    AuthService.changePassword(current_password, new_password).then(
      async (res) => {
        console.log(res);
        if (res.status === 200) {
          dispatch(logout());
          navigate("/login", { replace: true });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log(resMessage);
      }
    );
  };

  return (
    <LayoutWeb>
      <div className=" flex flex-col items-center justify-center min-h-screen md:py-4">
        <ChangePasswordForm handleChangePassword={handleChangePassword} />
      </div>
    </LayoutWeb>
  );
};

export default ChangePasswordPage;
