import React, { useState, useRef, useEffect, ReactNode } from "react";
import "./Tooltip.css";

interface TooltipProps {
  body: ReactNode; // Allows any JSX content to be passed to `body`
}

const Tooltip: React.FC<TooltipProps> = ({ body }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const showTooltip = () => setIsOpen(true);
  const hideTooltip = () => setIsOpen(false);

  const adjustTooltipPosition = () => {
    const tooltip = tooltipRef.current;
    const button = buttonRef.current;

    if (tooltip && button) {
      const buttonRect = button.getBoundingClientRect();
      const tooltipHeight = tooltip.offsetHeight;
      const tooltipWidth = tooltip.offsetWidth;

      const shouldMoveUp =
        buttonRect.bottom + tooltipHeight > window.innerHeight;
      const shouldMoveLeft = buttonRect.left + tooltipWidth > window.innerWidth;

      tooltip.style.top = shouldMoveUp
        ? `${buttonRect.top - tooltipHeight}px`
        : `${buttonRect.bottom-20}px`;
      tooltip.style.left = shouldMoveLeft
        ? `${buttonRect.left - tooltipWidth + 30}px`
        : `${buttonRect.left+ 30}px`;
    }
  };

  useEffect(() => {
    if (isOpen) {
      adjustTooltipPosition();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative w-5 flex items-center">
      <button className=""
        ref={buttonRef}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <svg
          className="w-4 h-4 text-gray-800 dark:text-gray-500 cursor-pointer"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </button>
      {isOpen && (
        <div
        className="z-10"
          ref={tooltipRef}
          style={{ position: "fixed" }}
        >
          {body}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
