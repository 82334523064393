import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./DropdownTable.css";

export interface DropdownItem {
  hoverClass: string;
  svg: React.ReactElement; // SVG component
  path: string; // Link path
  label: string;
  method?: () => void; // Optional method to execute on click
}

interface DropdownTableProps {
  list: DropdownItem[]; // List of dropdown items
}

const DropdownTable: React.FC<DropdownTableProps> = ({ list }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const adjustDropdownPosition = () => {
    const dropdown = dropdownRef.current;
    const button = buttonRef.current;

    if (dropdown && button) {
      const buttonRect = button.getBoundingClientRect();
      const dropdownHeight = dropdown.offsetHeight;
      const dropdownWidth = dropdown.offsetWidth;

      const shouldMoveUp =
        buttonRect.bottom + dropdownHeight > window.innerHeight;
      const shouldMoveLeft =
        buttonRect.left + dropdownWidth > window.innerWidth;

      dropdown.style.top = shouldMoveUp
        ? `${buttonRect.top - dropdownHeight}px`
        : `${buttonRect.bottom}px`;
      dropdown.style.left = shouldMoveLeft
        ? `${buttonRect.left - dropdownWidth + 30}px`
        : `${buttonRect.left}px`;
    }
  };

  useEffect(() => {
    if (isOpen) {
      adjustDropdownPosition();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative">
      <button ref={buttonRef} onClick={toggleDropdown}>
        {/* SVG Icon for Dropdown Button */}
        <svg
          width="25"
          height="26"
          viewBox="0 0 25 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.32734 15.3461C6.45587 15.3461 7.37074 14.4028 7.37074 13.2392C7.37074 12.0756 6.45587 11.1323 5.32734 11.1323C4.1988 11.1323 3.28394 12.0756 3.28394 13.2392C3.28394 14.4028 4.1988 15.3461 5.32734 15.3461Z"
            fill="#000000"
          />
          <path
            d="M12.4792 15.3461C13.6077 15.3461 14.5226 14.4028 14.5226 13.2392C14.5226 12.0756 13.6077 11.1323 12.4792 11.1323C11.3507 11.1323 10.4358 12.0756 10.4358 13.2392C10.4358 14.4028 11.3507 15.3461 12.4792 15.3461Z"
            fill="#000000"
          />
          <path
            d="M19.631 15.3461C20.7596 15.3461 21.6744 14.4028 21.6744 13.2392C21.6744 12.0756 20.7596 11.1323 19.631 11.1323C18.5025 11.1323 17.5876 12.0756 17.5876 13.2392C17.5876 14.4028 18.5025 15.3461 19.631 15.3461Z"
            fill="#000000"
          />
        </svg>
      </button>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="z-50 my-4 px-2 text-base list-none bg-current divide-y shadow block menu-dropdown-header-table rounded-md bg-white"
          style={{ position: "fixed" }}
        >
          <ul className="py-1">
            {list.map((item, index) => (
              <li key={index}>
                {item.path ? (
                  <Link
                    to={item.path}
                    className={`flex items-center w-full text-left mx-1 py-2 ${item.hoverClass}`}
                  >
                    {item.svg}
                    <div className="mx-2">{item.label}</div>
                  </Link>
                ) : (
                  <button
                    onClick={item.method}
                    className={`flex items-center w-full text-left px-1 py-2 ${item.hoverClass}`}
                  >
                    {item.svg}
                    <div className="mx-2">{item.label}</div>
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownTable;