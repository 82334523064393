import React, { useEffect, useState } from "react";
import { ProductData, ProductPriceData, ProductProps, ProductValidate } from "../../types/product";
import { Link, useParams } from "react-router-dom";
import { ProductCategoryData } from "../../types/productCategory";
import settingProductServices from "../../services/settingProduct";
import { GetProductCategoryFilter } from "../../types/productCategory";
import { ReactComponent as IconProduct } from "../../assets/icon/Product.svg";
import { ReactComponent as IconPencil } from "../../assets/icon/Pencil.svg";

const ProductForm: React.FC<ProductProps> = ({
  data,
  handleSubmit,
  validation
}) => {
  const { id } = useParams<{ id: string | undefined }>();
  const [product, setProduct] = useState<ProductData>({
    product_category_id: data.product_category_id,
    name: data.name,
    description: data.description,
    product_prices: data.product_prices,
    code: data.code,
  });
  const [errors, setErrors] = useState<ProductValidate>({product_prices: []});
  const [productCategory, setProductCategory] = useState<ProductCategoryData[]>([]);

  useEffect(() => {
    Object.entries(validation).map(([key, value]) => (
      setErrors((prevFields) => ({
        ...prevFields,
        [key]: value
      }))
    ));
  }, [validation]);

  useEffect(() => {
    fetchProductCategories();
  }, []);

  const fetchProductCategories = async () => {
    let payload: GetProductCategoryFilter = {
      perPage: 0,
      page: 0,
      search: "",
    };
    
    settingProductServices.getProductCategories(payload).then(
      async (res) => {
        if (res.status === 200) {
          let result: ProductCategoryData[] = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let item: ProductCategoryData = {
              id: res.data.data[i].id,
              name: res.data.data[i].name,
            };
            result.push(item);
          }
          await setProductCategory(result);
        }
        if (res.status === 400) {
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };
  
  const handleSubmitForm = () => {
    let isValidationErrors = false;
    let validationErrors: ProductValidate = {
      product_prices: [{}]
    };
    
    if (product.product_category_id === 0) {
      validationErrors.product_category_id = 'กรุณาระบุหมวดสินค้า';
    }
    if (!product.name.trim()) {
      validationErrors.name = 'กรุณาระบุชื่อสินค้า';
    }
    if (!product.description.trim()) {
      validationErrors.description = 'กรุณาระบุรายละเอียดของสินค้า';
    }
    
    let productPrices = [...product.product_prices];
    let previousMaxUnit = 0;

    for (let i = 0; i < product.product_prices.length; i++) {
      const currentMaxUnit = product.product_prices[i].max_unit;
      const currentPrice = product.product_prices[i].price;
      if (product.product_prices[i]?.price === undefined && product.product_prices[i]?.max_unit === undefined) {
        productPrices.splice(i, 1)
      }
      
      if (i === product.product_prices.length - 1) {
        if (currentPrice === undefined) {
          isValidationErrors = true
          validationErrors.product_prices = [...validationErrors.product_prices, { price: "กรุณาระบุราคาขาย" }];
        }
      } else {
        if (currentPrice === undefined && currentMaxUnit !== undefined) {
          isValidationErrors = true
          validationErrors.product_prices = [...validationErrors.product_prices, { price: "กรุณาระบุราคาขาย" }];
        } else if (currentMaxUnit === undefined && currentPrice !== undefined) {
          isValidationErrors = true
          validationErrors.product_prices = [...validationErrors.product_prices, { max_unit: "กรุณาระบุจำนวนน้ำหนักสูงสุดในช่วง" }];
        } else if (currentMaxUnit !== undefined && previousMaxUnit !== undefined && currentMaxUnit <= previousMaxUnit) {
          isValidationErrors = true
          validationErrors.product_prices = [...validationErrors.product_prices, { max_unit: "จำนวนน้ำหนักต้องมากกว่าขั้นก่อนหน้า" }];
        }
      }

      if (validationErrors.product_prices.length != i + 1) {
        validationErrors.product_prices = [...validationErrors.product_prices, {}];
      }
      if (currentMaxUnit !== undefined) {
        previousMaxUnit = currentMaxUnit
      }
      
    }
    
    Object.entries(validationErrors).map(([key, value]) => (
      setErrors((prevFields) => ({
        ...prevFields,
        [key]: value
      }))
    ));

    if (Object.keys(validationErrors).length > 1) {
      setErrors(validationErrors);
    } else if (isValidationErrors) {
      setErrors(validationErrors);
    } else {
      handleSubmit({
        product_category_id: product.product_category_id,
        name: product.name,
        description: product.description,
        product_prices: productPrices,
      });
    }
  };

  const handleProductPriceChange = async(index: number, field: keyof ProductPriceData, value: number) => {
    setProduct((prevProduct) => {
      const updatedPrices = [...prevProduct.product_prices];
      updatedPrices[index] = { ...updatedPrices[index], [field]: value };
      return { ...prevProduct, product_prices: updatedPrices };
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "e" || 
      e.key === "E" || 
      e.key === "." || 
      e.key === "-" || 
      e.key === "+"
    ) {
      e.preventDefault();
    }
  };

  const addPriceField = () => {
    setProduct((prevProduct) => {
      const updatedPrices = [...prevProduct.product_prices];
      updatedPrices.splice(updatedPrices.length - 1, 0, {});
      return { ...prevProduct, product_prices: updatedPrices };
    });
  };
  
  return (
    <div className="p-1">
      <div className="flex justify-between">
        <div className="p-4">
          <div className="text-[15px] text-[#535252] font-normal flex">
            {id ? (<><IconPencil className="w-6 h-6 opacity-60" />แก้ไขรายการสินค้า</>) : (<><IconProduct className="w-6 h-6 opacity-60" />สร้างรายการสินค้า</>)}
          </div>
          <div className="text-[26px] text-[#535252] font-normal">
            {product.code}
          </div>
        </div>
        <div>
            <Link to="/setting/product">
              <button className="bg-white hover:bg-white text-[#535252] mr-2 border border-[#6B7280] font-medium py-2 px-4 rounded">
              ยกเลิก
              </button>
            </Link>
            <button 
              onClick={handleSubmitForm}
              className="bg-[#13C296] hover:bg-green-700 text-white font-medium py-2 px-4 rounded">
              บันทึกข้อมูล
            </button>
        </div>
      </div>
      <div className="card rounded-lg shadow-md m-4">
        <div className="p-4">
          <div className="text-[18px] text-grey-dark font-medium">
            ข้อมูลสินค้า
          </div>
          <div className="border-b border-gray-900/10">
            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="product-category-id"
                  className="block text-sm font-normal leading-6 text-gray-900"
                >
                  หมวดสินค้า:
                </label>
                <div className="mt-2">
                  <select
                    id="product-category-id"
                    name="product-category-id"
                    value={product.product_category_id}
                    onChange={(e) => setProduct((prevFields) => ({
                      ...prevFields,
                      product_category_id: Number(e.target.value)
                    }))}
                    autoComplete="product-category-id"
                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-light"
                  >
                    <option value={0} key={0}>
                      ระบุหมวดสินค้า
                    </option>
                    {productCategory.map((productCategory) => (
                      <option
                        key={productCategory.id}
                        value={productCategory.id}
                      >
                        {productCategory.name}
                      </option>
                    ))}
                  </select>
                  {errors.product_category_id && <p className="text-red-500 text-sm mb-2">{errors.product_category_id}</p>}
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-normal leading-6 text-gray-900"
                >
                  ชื่อสินค้า:
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={product.name}
                    onChange={(e) => setProduct((prevFields) => ({
                      ...prevFields,
                      name: e.target.value
                    }))}
                    autoComplete="name"
                    placeholder="ระบุชื่อสินค้า"
                    className="block w-full ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.name && <p className="text-red-500 text-sm mb-2">{errors.name}</p>}
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="description"
                  className="block text-sm font-normal leading-6 text-gray-900"
                >
                  รายละเอียดของสินค้า:
                </label>
                <div className="mt-2">
                  <textarea
                    name="description"
                    id="description"
                    rows={4}
                    value={product.description}
                    onChange={(e) => setProduct((prevFields) => ({
                      ...prevFields,
                      description: e.target.value
                    }))}
                    autoComplete="description"
                    className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.description && <p className="text-red-500 text-sm mb-2">{errors.description}</p>}
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="text-[18px] text-grey-dark font-medium">
            ข้อมูลราคา
          </div>
          <br></br>
          <div className="text-[15px] text-grey-dark font-normal">
            ราคาขายเงื่อนไขตามราคา
          </div>
          <div className="text-[13px] text-grey-dark font-light">
            *ให้ระบุจำนวนน้ำหนักที่สูงที่สุดในช่วงนั้นเพียงจำนวนเดียว
          </div>
          <div className="border-gray-900/10">
            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {product.product_prices.map((field, index) => (
                index !== product.product_prices.length - 1 ? (
                  <React.Fragment key={index}>
                      <div className="sm:col-span-3">
                        <label
                          htmlFor={`max_unit-${index}`}
                          className="block text-sm font-normal leading-6 text-gray-900"
                        >
                          จำนวนน้ำหนักสูงสุด(กก.):
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name={`max_unit-${index}`}
                            id={`max_unit-${index}`}
                            value={field.max_unit}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => handleProductPriceChange(index, 'max_unit', Number(e.target.value))}
                            autoComplete={`max_unit-${index}`}
                            placeholder="ระบุจำนวนน้ำหนักสูงสุดในช่วง"
                            className="block w-full ${errors.product_prices[index]?.max_unit ? 'border-red-500' : 'border-gray-300'} rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {errors.product_prices[index]?.max_unit && <p className="text-red-500 text-sm mb-2">{errors.product_prices[index]?.max_unit}</p>}
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label
                          htmlFor={`price-${index}`}
                          className="block text-sm font-normal leading-6 text-gray-900"
                        >
                          ราคาขาย:
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name={`price-${index}`}
                            id={`price-${index}`}
                            value={field.price}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => handleProductPriceChange(index, 'price', Number(e.target.value))}
                            autoComplete={`price-${index}`}
                            placeholder="ระบุราคาขาย"
                            className="block w-full ${errors.product_prices?[index].price ? 'border-red-500' : 'border-gray-300'} rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {errors.product_prices[index]?.price && <p className="text-red-500 text-sm mb-2">{errors.product_prices[index]?.price}</p>}
                        </div>
                      </div>
                  </React.Fragment>
                ) : (null)
              ))}      
            </div>
          </div>
          <button
            type="button"
            onClick={addPriceField}
            className="mt-4 text-blue-1 hover:text-blue-2 font-normal"
          >
            + เพิ่มช่วงราคา
          </button>
          <div className="border-gray-900/10">
            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">           
              <div className="sm:col-span-3">
                <label
                  htmlFor="price-last"
                  className="block text-sm font-normal leading-6 text-gray-900"
                >
                  ราคาขาย/หน่วย(กก.):
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="price-last"
                    id="price-last"
                    value={product.product_prices[product.product_prices.length - 1].price}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => handleProductPriceChange(product.product_prices.length - 1, 'price', Number(e.target.value))}
                    autoComplete="price-last"
                    placeholder="ระบุราคาขาย"
                    className="block w-full ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {errors.product_prices[product.product_prices.length - 1]?.price && <p className="text-red-500 text-sm mb-2">{errors.product_prices[product.product_prices.length - 1]?.price}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
