import React, { useState } from "react";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";
import CustomerSchedulesForm from "../../components/form/CustomerSchedulesForm";

const CustomerSchedulesPage: React.FC = () => {
  const [loadingPage, setLoadingPage] = useState(false);
  return (
    <LayoutWeb>
      {loadingPage ? (
        <LoaderPage />
      ) : (
        <>
          
          <div className="bg-white">
            <CustomerSchedulesForm />
          </div>
        </>
      )}
    </LayoutWeb>
  );
};

export default CustomerSchedulesPage;
