import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import settingProductServices from "../../services/settingProduct";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";

import { GetProductFilter, ProductData, ProductValidate } from "../../types/product";
import Swal from "sweetalert2";
import ProductForm from "../../components/form/ProductForm";

const ProductCreate: React.FC = () => {
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(false);
  const [product, setProduct] = useState<ProductData>({
    product_category_id: 0,
    name: "",
    description: "",
    product_prices: [
      {},
      {},
    ],
    code: ""
  });
  const [productValidate, setProductValidate] = useState<ProductValidate>(
    {
      product_prices: [
        {},
        {},
      ]
    }
  );

  const handleSubmit = async (data: ProductData) => {
    setLoadingPage(true);
    setProductValidate(
      {
        product_prices: [
          {},
          {},
        ]
      }
    );   
  
    let payload: ProductData = {
      product_category_id: data.product_category_id,
      name: data.name,
      description: data.description,
      product_prices: data.product_prices,
    };
    
    await settingProductServices.createProduct(payload).then(
      async (res: any) => {
        console.log(res.response)
        if (res.status === 200) {
          navigate(`/setting/product`, { replace: true });
        } else if (res.status === 422 && res.response?.data?.message === "InvalidInput") {
          res.response.data.errors.map((element: any) => {
            let message: string = element.message
            if (message === "duplicate") {
              message = "ชื่อหมวดสินค้าซ้ำ"
            }
            setProductValidate((prevFields) => ({
              ...prevFields,
              [element.field]: message
            }));        
          });   
          
          Object.entries(data).map(([key, value]) => (
            setProduct((prevFields) => ({
              ...prevFields,
              [key]: value
            }))
          ));  
          
          setLoadingPage(false);
        } else {
          setLoadingPage(false);
          Swal.fire({
            position: "top",
            title: "บันทึกข้อมูลไม่สำเร็จ",
            html: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#13C296",
            confirmButtonText: "ปิด",
            footer: "โปรดถ่ายภาพเพื่อความสะดวกในการช่วยเหลือ",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  useEffect(() => {
    fetchLastProduct();
  }, [product.code]);

  const fetchLastProduct = async () => {
    setLoadingPage(true);
    let payload: GetProductFilter = {
      perPage: 1,
      page: 1,
      search: "",
      product_category_id: 0,
      is_include_delete: true,
    };
    settingProductServices.getProducts(payload).then(
      async (res) => {
        if (res.status === 200) {
          let productData = product
          const prefix = res.data.data[0].code.slice(0, 3);
          const numberPart = res.data.data[0].code.slice(3);
          const incrementedNumber = (parseInt(numberPart, 10) + 1).toString().padStart(numberPart.length, '0');
          productData.code = prefix + incrementedNumber
          setProduct(productData);
        }
        if (res.status === 400) {
        }
        setLoadingPage(false);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  return (
    <>
      <LayoutWeb>
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
            <ProductForm
              data={product}
              handleSubmit={handleSubmit} 
              validation={productValidate}
            />
          </>
        )}
      </LayoutWeb>
    </>
  );
};

export default ProductCreate;
