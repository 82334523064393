import LayoutWeb from "../../components/LayoutWeb";
import CustomerTable from "../../components/table/CustomerTable";
import "../../index.css";

const Home = () => {
  return (
    <>
      <LayoutWeb>
        <div className="flex justify-between">
          <div>
            <h1 className="text-3xl font-bold text-blue-600 py-2">
              ข้อมูลลูกค้าทั้งหมด
            </h1>
          </div>
        </div>
        <CustomerTable newCustomer={false} />
      </LayoutWeb>
    </>
  );
};

export default Home;
