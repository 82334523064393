import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserService from "../../services/userApi";
import PermissionService from "../../services/permissionApi";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";
import UserTabMenu from "../../components/tabmenu/UserTab";
import UserPermissionTab from "../../components/tabdetail/UserPermissionTab";
import UserProfileTab from "../../components/tabdetail/UserProfileTab";

import { UserProfileData } from "../../types/user";

const UserDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string | undefined }>();
  const [loadingPage, setLoadingPage] = useState(true);
  const [tabMenu, setTabMenu] = useState("Profile");
  const [profile, setProfile] = useState<UserProfileData>({
    username: "",
    password: "",
    fullName: "",
    email: "",
    isActivated: false,
    profileImage: null,
    imageUrl: "",
  });
  const [permissionList, setPermissionList] = useState([]);
  const [userPermission, setUserPermission] = useState([]);

  const handleUpdateProfileForm = async (formData: UserProfileData) => {
    setLoadingPage(true);
    const payload = new FormData(); // Create a new FormData object

    // Append data to the FormData object
    payload.append("full_name", formData.fullName);
    payload.append("is_activated", formData.isActivated ? "1" : "0"); // Convert boolean to string
    if (formData.profileImage) {
      payload.append("images", formData.profileImage); // Append the file
    }
    UserService.updateUserById(String(id), payload).then(
      async (res: any) => {
        console.log(res);
        if (res.status === 200) {
          navigate("/users", { replace: true });
        } else {
          setLoadingPage(false);
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
    // Handle form submission (e.g., make an API call to update the user profile)
  };

  const handlePermissionsSubmit = (
    permissions: Record<string, Record<string, Record<string, any>>>
  ) => {
    setLoadingPage(true);
    const items: any = [];
    for (const group in permissions) {
      const permission = permissions[group];
      for (const key in permission) {
        permission[key]["user_id"] = Number(id);
        items.push(permission[key]);
      }
    }
    UserService.updateUserPermissions(String(id), { permissions: items }).then(
      async (res: any) => {
        if (res.status === 200) {
          await setTabMenu("Profile");
          await fetchUserById();
        } else {
          setLoadingPage(false);
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const renderTabContent = () => {
    switch (tabMenu) {
      case "Profile":
        return (
          <UserProfileTab
            isCreate={false}
            data={profile}
            handleSubmit={handleUpdateProfileForm}
          />
        );
      case "Permission":
        return (
          <UserPermissionTab
            permissionList={permissionList}
            data={userPermission}
            onSubmit={handlePermissionsSubmit}
          />
        );
      default:
        return null;
    }
  };

  const fetchUserById = async () => {
    setLoadingPage(true);
    UserService.getUserById(String(id)).then(
      async (res: any) => {
        if (res.status === 200) {
          const item = res.data.data;
          await setUserPermission(item["user_permissions"]);
          await setProfile({
            username: item["username"],
            password: "",
            fullName: item["full_name"],
            email: item["email"],
            isActivated: item["is_activated"],
            profileImage: null,
            imageUrl: item["image_path"],
          });
          setLoadingPage(false);
        }
        if (res.status === 400) {
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const fetchPermissions = async () => {
    PermissionService.getPermissions().then(
      async (res: any) => {
        if (res.status === 200) {
          await setPermissionList(res.data.data);
        }
        if (res.status === 400) {
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  useEffect(() => {
    fetchPermissions();
    fetchUserById();
  }, [id]);
  return (
    <>
      <LayoutWeb>
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
            <div className="p-1">
              <h1 className="text-[24px] text-[#3B82F6] font-bold">
                แก้ไขข้อมูลผู้ใช้งานระบบ
              </h1>
              <div className="card rounded-lg shadow-md m-4">
                <UserTabMenu tabMenu={tabMenu} setTabMenu={setTabMenu} />
                <div className="p-4">{renderTabContent()}</div>
              </div>
            </div>
          </>
        )}
      </LayoutWeb>
    </>
  );
};

export default UserDetail;
