import apiClient from "./apiClient";
import { getStorage } from "../utils/storage";
import { GetProductFilter } from "../types/product";
import { ApiResponse, CustomerProductResponse } from "../types/customerProduct";
import { AxiosError } from "axios";

const createCustomerProduct = async (payload: any): Promise<ApiResponse<CustomerProductResponse> | null> => {
    try {
      const token = getStorage("token");
      const response = await apiClient.post(`customer-products`, payload, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      });
      return response;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        console.error("API Error in createCustomerProduct:", error.response);
        return error.response; // Return the full response object on error
      }
      console.error("Unexpected error in createCustomerProduct:", error);
      throw new Error("An unexpected error occurred while creating the product.");
    }
  };

  const getCustomerProducts = (payload: GetProductFilter) => {
    let filters = "?";
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (
          payload[key as keyof GetProductFilter] !== undefined &&
          payload[key as keyof GetProductFilter] !== null &&
          payload[key as keyof GetProductFilter] !== ""
        ) {
          filters += `${encodeURIComponent(key)}=${encodeURIComponent(
            payload[key as keyof GetProductFilter]
          )}&`;
        }
      }
    }
    filters = filters.slice(0, -1);
    const token = getStorage("token");
    return apiClient
      .get(`customer-products${filters}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      })
      .then((response) => {
        return response;
      })
      .catch((e) => {
        return e.response.data;
      });
  };

  const getCustomerProductById = async (id: string) => {
    try {
      const token = getStorage("token");
      const response = await apiClient.get(`customer-products/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      });
      return response;
    } catch (e) {
      console.error("error", e);
      return e;
    }
  };

  const updateCustomerProductById = async (id: string, payload: any) => {
    try {
      const token = getStorage("token");
      const response = await apiClient.put(`customer-products/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      });
      return response;
    } catch (e) {
      return e;
    }
  };

  const deleteCustomerProductById = async (id: number) => {
    try {
      const token = getStorage("token");
      const response = await apiClient.delete(`customer-products/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      });
      return response
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        console.error("API Error in createCustomerProduct:", error.response);
        return error.response; // Return the full response object on error
      }
      console.error("Unexpected error in createCustomerProduct:", error);
      throw new Error("An unexpected error occurred while creating the product.");
    }
  };

  const customerProductServices = {
    createCustomerProduct,
    getCustomerProducts,
    getCustomerProductById,
    updateCustomerProductById,
    deleteCustomerProductById,
};
export default customerProductServices;
