import React, { useEffect, useState } from 'react'
import LayoutWeb from '../../components/LayoutWeb'
import LoaderPage from '../../components/LoaderPage';
import SettingDocs from '../../components/form/SettingDocs';

const SetUpDocuments = () => {
    const [loadingPage, setLoadingPage] = useState(true);
    useEffect(() => {
        // Simulate loading data, set it to false after some time or condition
        const timer = setTimeout(() => {
          setLoadingPage(false);
        }, 100); // Simulating 1 second load time
    
        return () => clearTimeout(timer);
      }, []);
  return (
    <LayoutWeb>
      {loadingPage ? (
        <LoaderPage />
      ) : (
        <>
        <div className="card rounded-2xl shadow-md my-2 p-4">
          <SettingDocs />
        </div>
        </>
      )}
    </LayoutWeb>
  )
}

export default SetUpDocuments