import React, { useState, useEffect } from "react";

import PermissionItem from "../widget/PermissionItem";
import PermissionGroup from "../widget/PermissionGroup";

interface UserPermissionData {
  name: string;
  userId: number;
  permissionId: number;
  isActivated: boolean;
}

interface UserPermissionTabProps {
  permissionList: any;
  data: any; // Specify the actual type if known
  onSubmit: (
    permissions: Record<string, Record<string, { isActivated: boolean }>>
  ) => void;
}

const SvgIcon = (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 9H11M13 15H17M9 7V17M15 7V17M7.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V7.2C20 6.0799 20 5.51984 19.782 5.09202C19.5903 4.71569 19.2843 4.40973 18.908 4.21799C18.4802 4 17.9201 4 16.8 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.07989 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.07989 20 7.2 20Z"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UserPermissionTab: React.FC<UserPermissionTabProps> = ({
  permissionList,
  data,
  onSubmit,
}) => {
  const [permissions, setPermissions] = useState<any>({});

  // Handle checkbox change
  const handleCheckboxChange =
    (group: string, permission: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target; // Get the checked status of the checkbox

      // Update the state with the new value for is_activated
      setPermissions((prevPermissions: any) => ({
        ...prevPermissions,
        [group]: {
          ...prevPermissions[group],
          [permission]: {
            ...prevPermissions[group][permission],
            is_activated: checked, // Update the is_activated status
          },
        },
      }));
    };
  // Handle form submission
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault(); // Prevent form from reloading the page
    onSubmit(permissions); // Call onSubmit with the current permissions
  };

  useEffect(() => {
    var temp: any = {};
    var userPermissionTemp: any = {};
    for (var index in data) {
      userPermissionTemp[data[index]["permissions"]["name"]] = {
        id: data[index]["permissions"]["id"],
        name: data[index]["permissions"]["name"],
        title: data[index]["permissions"]["title"],
        is_activated: data[index]["is_activated"],
      };
    }
    permissionList.forEach((permission: any) => {
      var status = false;
      if (userPermissionTemp[permission["name"]] !== undefined) {
        status = userPermissionTemp[permission["name"]]["is_activated"];
      }
      if (temp[permission["title"]] === undefined) {
        temp[permission["title"]] = {};
      }
      temp[permission["title"]][permission["name"]] = {
        permission_id: permission["id"],
        group: permission["title"],
        name: permission["name"],
        is_activated: status,
      };
    });
    setPermissions(temp);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="h-50 w-full rounded-lg bg-white ">
        <div className="flex items-center justify-between border-b">
          <div className="p-3 text-gray-700 text-lg font-bold">
            สิทธิ์การเข้าใช้งาน
          </div>
          <div className="p-3 flex"></div>
        </div>
        <div className="py-2 px-10 ">
          {Object.keys(permissions).map((groupKey, index) => {
            const permissionGroup = permissions[groupKey];
            return (
              <PermissionGroup title={groupKey}>
                {Object.keys(permissionGroup).map((key, _) => {
                  const permission = permissionGroup[key];
                  return (
                    <PermissionItem
                      label={key}
                      icon={SvgIcon}
                      checked={permission["is_activated"]}
                      onChange={handleCheckboxChange(groupKey, key)}
                    />
                  );
                })}
              </PermissionGroup>
            );
          })}
        </div>
        <div className="p-4 flex justify-end">
          <button
            type="submit"
            className="w-36 px-4 py-2 bg-blue-600 text-white rounded-lg"
          >
            บันทึก
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserPermissionTab;
