export const getFormatDatetime = (dateString: string): string => {
  const dateObject = new Date(dateString);
  return dateObject.toLocaleString(); // Default format or custom options
};

export const showDatetimeFormat = (dateObject: Date): string => {
  // Extract date parts
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Zero-indexed, so add 1
  const day = String(dateObject.getDate()).padStart(2, "0");
  // const hour = String(dateObject.getHours()).padStart(2, "0");

  // Return the custom format "YYYY-MM-DDTHH"
  return `${year}-${month}-${day}`;
};

export const showDatetimeFormatTh = (dateObject: Date): string => {
  // Extract date parts
  const year = dateObject.getFullYear() + 543;
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Zero-indexed, so add 1
  const day = String(dateObject.getDate()).padStart(2, "0");
  // const hour = String(dateObject.getHours()).padStart(2, "0");

  // Return the custom format "YYYY-MM-DDTHH"
  return `${day}-${month}-${year}`;
};

export const getDate = (dateString: string): Date => {
  const date = new Date(dateString);

  // ตรวจสอบว่า date ถูกต้องหรือไม่
  if (isNaN(date.getTime())) {
    // throw new Error("Invalid date string format");
    return new Date();
  }

  return date;
};
