import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

// Validation schema
const validationSchema = Yup.object().shape({
  businessName: Yup.string().required("กรุณากรอกชื่อธุรกิจ"),
  address: Yup.string().required("กรุณากรอกที่อยู่"),
  taxId: Yup.string()
    .matches(/^\d{13}$/, "เลขประจำตัวผู้เสียภาษีต้องมี 13 หลัก")
    .required("กรุณากรอกเลขประจำตัวผู้เสียภาษี"),
  officePhone: Yup.string().required("กรุณากรอกเบอร์สำนักงาน"),
  mobilePhone: Yup.string().required("กรุณากรอกเบอร์มือถือ"),
  website: Yup.string()
    .url("กรุณากรอก URL ที่ถูกต้อง")
    .required("กรุณากรอกเว็บไซต์"),
});

const SettingBusInfo = () => {
  return (
    <>
      <div className="container mx-auto p-4">
        <Formik
          initialValues={{
            businessName: "",
            address: "",
            taxId: "",
            officeType: "main",
            officePhone: "",
            mobilePhone: "",
            website: "",
            logo: null,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values); // Handle form submission
          }}
        >
          {({ setFieldValue }) => (
            <Form className="space-y-6">
              <h2 className="text-xl font-semibold mb-4">ข้อมูลธุรกิจ</h2>

              {/* Business Information */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="text-gray-700 mb-2">ชื่อธุรกิจ:</label>
                  <Field
                    type="text"
                    name="businessName"
                    className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="บริษัท มีเอกสาร ขาย เชียงใหม่ จำกัด"
                  />
                  <ErrorMessage
                    name="businessName"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-gray-700 mb-2">ที่อยู่:</label>
                  <Field
                    as="textarea"
                    name="address"
                    className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="389/40 หมู่ 2 ตำบลแม่เหียะ อำเภอเมืองเชียงใหม่ จังหวัดเชียงใหม่ 50300"
                    rows={3}
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-gray-700 mb-2">
                    เลขประจำตัวผู้เสียภาษี:
                  </label>
                  <Field
                    type="text"
                    name="taxId"
                    className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="0505563001372"
                  />
                  <ErrorMessage
                    name="taxId"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-gray-700 mb-2">สำนัก:</label>
                  <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center">
                      <Field
                        type="radio"
                        className="form-radio h-5 w-5 text-blue-600"
                        name="officeType"
                        value="main"
                      />
                      <span className="ml-2">สำนักงานใหญ่</span>
                    </label>
                    <label className="inline-flex items-center">
                      <Field
                        type="radio"
                        className="form-radio h-5 w-5 text-blue-600"
                        name="officeType"
                        value="branch"
                      />
                      <span className="ml-2">สาขา</span>
                    </label>
                  </div>
                </div>
              </div>

              {/* Contact Information */}
              <h3 className="text-lg font-semibold mt-6 mb-4">ข้อมูลติดต่อ</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="text-gray-700 mb-2">เบอร์สำนักงาน:</label>
                  <Field
                    type="text"
                    name="officePhone"
                    className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="053-106163"
                  />
                  <ErrorMessage
                    name="officePhone"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-gray-700 mb-2">เบอร์มือถือ:</label>
                  <Field
                    type="text"
                    name="mobilePhone"
                    className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="081-5959004"
                  />
                  <ErrorMessage
                    name="mobilePhone"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-gray-700 mb-2">เว็บไซต์:</label>
                  <Field
                    type="text"
                    name="website"
                    className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="www.chokumnuai.com"
                  />
                  <ErrorMessage
                    name="website"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              {/* Company Logo */}
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-4">โลโก้บริษัท</h3>
                <div className="flex items-center justify-center border-dashed border-2 border-gray-300 p-6 rounded-md">
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      className="hidden"
                      onChange={(event) => {
                        if (
                          event.target.files &&
                          event.target.files.length > 0
                        ) {
                          setFieldValue("logo", event.target.files[0]);
                        }
                      }}
                    />
                    <div className="text-center">
                      <img
                        src="https://placehold.co/1400x300"
                        alt="Upload"
                        className="h-20 mx-auto mb-4"
                      />
                      <p className="text-gray-500">คลิกเพื่อเลือกรูป</p>
                      <p className="text-gray-400 text-sm">
                        ขนาดแนะนำ 1400x300px และขนาดไฟล์ไม่เกิน 3MB
                      </p>
                    </div>
                  </label>
                </div>
              </div>

              {/* Save Button */}
              <div className="mt-6 text-right">
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-all duration-300"
                >
                  บันทึกข้อมูล
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SettingBusInfo;
