import { useCallback, useMemo, useState } from "react";
import { DAYS } from "../../../enum/day";
import { IColumnTable, RowData } from "./components/DraggableTable";

export interface ITabsList {
  name: string;
  key: string;
  color?: string;
}

interface IRowTableData {
  no: string;
  customerId: string;
  customerName: string;
  locationName: string;
  address: string;
  location: string;
}

export const useViewModel = () => {
  const [currentDay, setCurrentDay] = useState(DAYS.SUNDAY);
  const [currentRoute, setCurrentRoute] = useState("A");
  const [dayRouteMap, setDayRouteMap] = useState<Record<DAYS, string>>(
    {} as Record<DAYS, string>
  );
  const [currentItems, setCurrentItems] = useState<RowData[]>([]);

  const dayTabsList: ITabsList[] = [
    { name: "วันอาทิตย์", key: DAYS.SUNDAY, color: "tab-red" },
    { name: "วันจันทร์", key: DAYS.MONDAY, color: "tab-yellow" },
    { name: "วันอังคาร", key: DAYS.TUESDAY, color: "tab-pink" },
    { name: "วันพุธ", key: DAYS.WEDNESDAY, color: "tab-green" },
    { name: "วันพฤหัสบดี", key: DAYS.THURSDAY, color: "tab-orange" },
    { name: "วันศุกร์", key: DAYS.FRIDAY, color: "tab-blue" },
    { name: "วันเสาร์", key: DAYS.SATURDAY, color: "tab-purple" },
  ];

  const routeTabsList = [
    { name: "เส้นทาง A", key: "A", color: "tab-deep-blue" },
    { name: "เส้นทาง B", key: "B", color: "tab-deep-blue" },
    { name: "เส้นทาง C", key: "C", color: "tab-deep-blue" },
    { name: "เส้นทาง D", key: "D", color: "tab-deep-blue" },
    { name: "เส้นทาง E", key: "E", color: "tab-deep-blue" },
    { name: "เส้นทาง F", key: "F", color: "tab-deep-blue" },
    { name: "เส้นทาง G", key: "G", color: "tab-deep-blue" },
    { name: "เส้นทาง G", key: "G", color: "tab-deep-blue" },
    { name: "เส้นทาง G", key: "G", color: "tab-deep-blue" },
    { name: "เส้นทาง G", key: "G", color: "tab-deep-blue" },
    { name: "เส้นทาง G", key: "G", color: "tab-deep-blue" },
    { name: "เส้นทาง G", key: "G", color: "tab-deep-blue" },
    { name: "เส้นทาง G", key: "G", color: "tab-deep-blue" },
    { name: "เส้นทาง G", key: "G", color: "tab-deep-blue" },
    { name: "เส้นทาง G", key: "G", color: "tab-deep-blue" },
  ];

  const onClickTabsDay = useCallback(
    (day: DAYS) => {
      if (dayRouteMap[day] && dayRouteMap[day] !== "A") {
        setCurrentRoute("A");
      } else {
        setCurrentRoute(dayRouteMap[day] || "A");
      }
      setCurrentDay(day);
    },
    [dayRouteMap]
  );

  const onClickRoute = useCallback(
    (route: string) => {
      setCurrentRoute(route);
      setDayRouteMap((prevMap) => ({
        ...prevMap,
        [currentDay]: route,
      }));
    },
    [currentDay]
  );

  const tempData: IRowTableData[] = [
    {
      no: "1",
      customerId: "CNXXX2401001",
      customerName: "บริษัท มีความรวย จำกัด",
      locationName: "คลินิกกำลังดี",
      address: "22/41 ม.5 ต.สุเทพ อ.เมือง จ.เชียงใหม่ 50200",
      location: "https://www.google.co.th",
    },
    {
      no: "2",
      customerId: "CNXXX2401002",
      customerName: "หจก.เพื่อนรัก",
      locationName: "คลินิกนวดแผนโบราณ",
      address: "22/41 ม.5 ต.สุเทพ อ.เมือง จ.เชียงใหม่ 50200",
      location: "https://www.google.co.th",
    },
    {
      no: "3",
      customerId: "CNXXX2401003",
      customerName: "บริษัท 9999 จำกัด",
      locationName: "คลินิกเพื่อนสัตว์",
      address: "22/41 ม.5 ต.สุเทพ อ.เมือง จ.เชียงใหม่ 50200",
      location: "https://www.google.co.th",
    },
    {
      no: "4",
      customerId: "CNXXX2401004",
      customerName: "บริษัท มีความปัง จำกัด",
      locationName: "คลินิกเวชกรรมนางฟ้า",
      address: "22/41 ม.5 ต.สุเทพ อ.เมือง จ.เชียงใหม่ 50200",
      location: "https://www.google.co.th",
    },
  ];

  const columns: IColumnTable[] = useMemo(() => {
    return [
      {
        name: "ลำดับ",
        minSize: 47,
        maxSize: 4.4,
      },
      {
        name: "รหัสลูกค้า",
        minSize: 126,
        maxSize: 11.54,
      },
      {
        name: "ชื่อลูกค้า",
        minSize: 270,
        maxSize: 24.74,
      },
      {
        name: "ชื่อสถานพยาบาล",
        minSize: 270,
        maxSize: 24.74,
      },
      {
        name: "ที่อยู่",
        minSize: 219,
        maxSize: 20.06,
      },
      {
        name: "ตำแหน่งบนแผนที่",
        minSize: 120,
        maxSize: 11.01,
      },
    ];
  }, []);

  const onSaveTruckRoute = () => {
    console.log("Current items:", currentItems);
  };

  return {
    currentDay,
    currentRoute,
    dayTabsList,
    routeTabsList,
    onClickTabsDay,
    onClickRoute,
    tempData,
    columns,
    onSaveTruckRoute,
    setCurrentItems,
  };
};
