import { Link } from "react-router-dom";
import LayoutWeb from "../../components/LayoutWeb";
import UserTable from "../../components/table/UserTable";
import "../../index.css";

const User = () => {
  return (
    <>
      <LayoutWeb>
        <div className="p-1">
          <div className="flex justify-between">
            <div>
              <h1 className="text-[24px] text-[#3B82F6] font-bold">
                ข้อมูลผู้ใช้งานระบบ
              </h1>
            </div>
            <div>
              <Link to="/create-users">
                <button className="bg-[#13C296] hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                  เพิ่มผู้ใช้งานระบบ
                </button>
              </Link>
            </div>
          </div>
          <UserTable />
        </div>
      </LayoutWeb>
    </>
  );
};

export default User;
