import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewCustomerRegister from "../pages/NewCustomerRegister";
import Home from "../pages/Home";
import Customer from "../pages/Customer/Index";
import User from "../pages/User/Index";
import CreateUser from "../pages/User/Create";
import NewCustomer from "../pages/NewCustomer/Index";
import CreateCustomerDetail from "../pages/Customer/Create";
import LoginPage from "../pages/Login/Index";
import ChangePasswordPage from "../pages/ChangePassword/Index";
import PrivateRoute from "./privateRoute";
import { useDispatch } from "react-redux";
import { getAuth } from "../stores/slices/Auth";
import ForgotPassword from "../pages/ForgotPassowrd/Index";
import UserDetail from "../pages/User/[id]";
import BusinessInformation from "../pages/Setting/BusinessInformation";
import SetUpDocuments from "../pages/Setting/SetUpDocuments";
import ProductCategory from "../pages/SettingProductCategory/Index";
import ProductCategoryDetail from "../pages/SettingProductCategory/[id]";
import ProductCategoryCreate from "../pages/SettingProductCategory/Create";
import CustomerDetail from "../pages/Customer/[id]";
import TruckRoute from "../pages/Setting/TruckRoute";
import CustomerSchedulesPage from "../pages/Customer/CustomerSchedules";
import TruckPage from "../pages/Truck/Index";
import CreateTruck from "../pages/Truck/Create";
import TruckDetailPage from "../pages/Truck/[id]";
import RoutePage from "../pages/Route/Index";
import CreateRoute from "../pages/Route/Create";
import RouteDetailPage from "../pages/Route/[id]";
import Product from "../pages/SettingProduct/Index";
import ProductDetail from "../pages/SettingProduct/[id]";
import RouteWay from "../pages/RouteWay";
import ProductCreate from "../pages/SettingProduct/Create";
import CustomerProductsPrice from "../pages/Customer/CustomerProductsPrice";

const Web = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuth());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<NewCustomerRegister />} />
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<User />} />
          <Route path="/create-users" element={<CreateUser />} />
          <Route path="/users/:id" element={<UserDetail />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />

          <Route path="/new-customers" element={<NewCustomer />} />
          <Route path="/customers" element={<Customer />} />
          <Route path="/customer">
            <Route path="create" element={<CreateCustomerDetail />} />
            <Route path=":id" element={<CustomerDetail />} />
            <Route path="schedule/:id" element={<CustomerSchedulesPage />} />
            <Route path="product/:id" element={<CustomerProductsPrice />} />
          </Route>
          <Route path="/route-way" element={<RouteWay />} />

          <Route path="/setting">
            <Route
              path="BusinessInformation"
              element={<BusinessInformation />}
            />
            <Route path="setupdoc" element={<SetUpDocuments />} />
            <Route
              path="create-product-category"
              element={<ProductCategoryCreate />}
            />
            <Route path="product-category" element={<ProductCategory />} />
            <Route
              path="product-category/:id"
              element={<ProductCategoryDetail />}
            />
            <Route path="product" element={<Product />} />
            <Route path="create-product" element={<ProductCreate />} />
            <Route path="product/:id" element={<ProductDetail />} />
            <Route path="create-users" element={<CreateUser />} />
            <Route path="truck" element={<TruckPage />} />
            <Route path="create-trucks" element={<CreateTruck />} />
            <Route path="truck/:id" element={<TruckDetailPage />} />
            <Route path="truck-route" element={<TruckRoute />} />
            <Route path="route" element={<RoutePage />} />
            <Route path="create-routes" element={<CreateRoute />} />
            <Route path="route/:id" element={<RouteDetailPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Web;
