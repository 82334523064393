import React, { useState, useEffect, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

interface Submenu {
  name: string;
  url: string;
  isActivate: boolean;
}

interface DropdownMenuProps {
  title: string;
  submenus: Submenu[];
  isSubMenuOpen: boolean;
  icon: ReactNode;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  title,
  submenus,
  isSubMenuOpen,
  icon,
}) => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let count = 0;
    submenus.forEach((data) => {
      if (data.isActivate) {
        count += 1;
      }
      if (isActive(data.url)) {
        setIsOpen(true);
      }
    });
    if (count === 0) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, [submenus]);

  return (
    <>
      {showMenu ? (
        <>
          <button
            type="button"
            className="flex items-center w-full p-2 text-base text-white transition duration-75 group py-2.5 px-4 hover:bg-blue-600 my-2"
            onClick={toggleDropdown}
          >
            <span className="w-6 h-7 mr-2">{icon}</span>
            <span className="flex-1 text-left whitespace-nowrap">
              {isSubMenuOpen ? "" : title}
            </span>
            <svg
              className={`w-6 h-6 transition-transform duration-500 ${
                isOpen ? "rotate-180" : ""
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>

          {/* Dropdown menu with smooth animation */}
          <div
            className={`transition-[max-height] duration-500 ease-in-out overflow-hidden ${
              isOpen ? "max-h-full" : "max-h-0"
            }`}
          >
            <ul className="py-2 space-y-2">
              {submenus.map((submenu, index) => (
                <li key={index}>
                  {submenu.isActivate === true ? (
                    <Link
                      to={submenu.url}
                      className={`flex items-center p-2 text-base text-white transition duration-75 pl-11 group hover:bg-blue-600 ${
                        isActive(`${submenu.url}`) ? "bg-white/20" : ""
                      }`}
                    >
                      {submenu.name}
                    </Link>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}
    </>
  );
};

export default DropdownMenu;
