// components/TableComponent.tsx
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import "react-dropdown/style.css";
import DataTable, { TableStyles } from "react-data-table-component"; // Import TableStyles for typing customStyles
import "./Index.css";
import searchImage from "../../assets/icon/search.png";
import settingProductServices from "../../services/settingProduct";
import {
  GetProductFilter,
  ProductData,
  ProductPriceData,
} from "../../types/product";
import DropdownTable from "../dropdown/DropDownTable";
import Swal from "sweetalert2";
import { ReactComponent as Iconinfo } from "../../assets/icon/edit_info.svg";
import { ReactComponent as Trash } from "../../assets/icon/trash.svg";
import { ReactComponent as ArrowDown } from "../../assets/icon/arrow-down.svg";
import Tooltip from "../tooltip/Tooltip";
import {
  GetProductCategoryFilter,
  ProductCategoryData,
} from "../../types/productCategory";
import { cx } from "@emotion/css";

interface TableProps {}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

// Define your custom styles and use TableStyles type
const customStyles: TableStyles = {
  headCells: {
    style: {
      padding: "0px",
      textAlign: "center",
      backgroundColor: "#E5E7EB",
      color: "#535252",
      "&:first-child": {
        padding: "0px",
        textAlign: "center",
        borderRadius: "15px",
      },
      "&:last-child": {
        padding: "0px",
        textAlign: "center",
        borderRadius: "15px",
      },
    },
  },
  headRow: {
    style: {
      backgroundColor: "#E5E7EB",
      color: "#FFFFFF",
      fontSize: "18px",
      fontWeight: "bold",
      position: "sticky",
      top: "0", // Stick to the top
      zIndex: 100, // Ensure it stays above other elements
    },
  },
  cells: {
    style: {
      padding: "12px",
      textAlign: "center",
      "&:first-child": {
        padding: "0px",
        textAlign: "center",
      },
      "&:last-child": {
        padding: "0px",
        textAlign: "center",
      },
    },
  },
  rows: {
    style: {
      minHeight: "50px", // Row height
      "&:hover": {
        backgroundColor: "#f1f5f9", // Hover color
      },
    },
  },
  pagination: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#E2E8F0",
      padding: "10px",
    },
  },
};

const ProductTable: React.FC<TableProps> = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const [productCategoryID, setProductCategoryID] = useState(0);
  const [productCategory, setProductCategory] = useState<ProductCategoryData[]>(
    []
  );
  const columns = [
    {
      name: "รหัสสินค้า",
      selector: (row: any) => row.code,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-center items-center truncate-text text-[15px]">
          {row.code}
        </div>
      ),
      sortable: true,
      width: "150px !important",
    },
    {
      name: "รายการสินค้า",
      selector: (row: any) => row.name,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-left items-center truncate-text text-[15px]">
          <Tooltip
              body={
                <div className="bg-[#264CA2] p-4 rounded-lg mx-auto shadow-lg text-[10px] max-w-[400px]">
                  <div className="text-left font-normal text-white text-wrap line-clamp-4">
                    {row.description}
                  </div>
                </div>
              }
            />
          {row.name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "ราคาต่อหน่วย",
      selector: (row: any) => row.product_prices[0]?.price,
      cell: (row: ProductData) => (
        <div className="w-[100%] flex justify-end items-center truncate-text text-[15px] gap-1">
          {row.product_prices[0]?.price}
          {row.product_prices.length > 1 ? (
            <Tooltip
              body={
                <div className="bg-[#264CA2] p-4 rounded-lg mx-auto shadow-lg text-[10px]">
                  <div className="text-left font-normal text-white">
                    ค่าบริการเก็บขนและกำจัดมูลฝอยติดเชื้อรายเดือน
                  </div>
                  <div className="">
                    {row.product_prices.map((item, index) =>
                      index !== row.product_prices.length - 1 ? (
                        <React.Fragment key={index}>
                          <div key={index} className="flex gap-1 text-white">
                            <span>
                              {minUnit(index, row.product_prices)}
                            </span>
                            <span>-</span>
                            <span>
                              {item.max_unit}
                            </span>
                            <span>กิโลกรัม</span>
                            <span>
                              {item.price}
                            </span>
                            <span>บาท</span>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment key={index}>
                          <div
                            key={row.product_prices.length - 1}
                            className="flex text-white gap-1"
                          >
                            <span>
                              ส่วนเกินคิดกิโลกรัมละ
                            </span>
                            <span>
                              {item.price}
                            </span>
                            <span>บาท</span>
                          </div>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </div>
              }
            />
          ) : (
            <div className="w-5"></div>
          )}
        </div>
      ),
      sortable: true,
      width: "125px !important",
    },
    {
      name: "หมวดสินค้า",
      selector: (row: any) => row.product_category.id,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-left items-center truncate-text text-[15px]">
          {row.product_category.name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "สถานะ",
      selector: (row: any) => row.deleted_at,
      cell: (row: any) => (
        <div
          className={cx(
            " w-[100%] flex justify-left items-center truncate-text text-[15px]",
            row.deleted_at === null ? "text-black" : "text-red-500"
          )}
        >
          {row.deleted_at === null ? "ปกติ" : "ยกเลิกการใช้"}
        </div>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (row: any) => (
        <div className="w-[100%] justify-start items-center">
          {row.deleted_at === null ? (
            <DropdownTable
              list={[
                {
                  svg: <Iconinfo className="w-6 h-6 mx-2 opacity-60" />,
                  path: `/setting/product/${row.id}`,
                  hoverClass:
                    "hover:bg-[#3758F9] hover:rounded-md hover:text-white",
                  label: "แก้ไขข้อมูล",
                },
                {
                  svg: <Trash className="w-6 h-6 mx-2 opacity-60" />,
                  path: "",
                  hoverClass:
                    "hover:bg-red-200 hover:rounded-md hover:text-white",
                  label: "ลบ",
                  method: () => {
                    handleDelete(row.id);
                  }, // Example method
                },
              ]}
            />
          ) : (
            <DropdownTable
              list={[
                {
                  svg: <Trash className="w-6 h-6 mx-2 opacity-60" />,
                  path: "",
                  hoverClass:
                    "hover:bg-red-200 hover:rounded-md hover:text-white",
                  label: "กู้คืน",
                  method: () => {
                    // handleDelete(row.id);
                  }, // Example method
                },
              ]}
            />
          )}
        </div>
      ),
      width: "100px !important",
    },
  ];

  const minUnit = (index: number, data: ProductPriceData[]) => {
    if (index === 0) {
      return 0;
    }
    const prevMaxUnit = data[index - 1].max_unit;
    if (prevMaxUnit !== undefined) {
      return prevMaxUnit + 1;
    }
  };

  const fetchProductCategories = async () => {
    let payload: GetProductCategoryFilter = {
      perPage: 0,
      page: 0,
      search: "",
    };

    settingProductServices.getProductCategories(payload).then(
      async (res) => {
        if (res.status === 200) {
          let result: ProductCategoryData[] = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let item: ProductCategoryData = {
              id: res.data.data[i].id,
              name: res.data.data[i].name,
            };
            result.push(item);
          }
          await setProductCategory(result);
        }
        if (res.status === 400) {
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const fetchProduct = async (payload: GetProductFilter) => {
    setLoading(true);
    settingProductServices.getProducts(payload).then(
      async (res) => {
        if (res.status === 200) {
          let result: any = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.no = i + 1 + (res.data.page - 1) * res.data.perPage;
            result.push(item);
          }
          await setPage(res.data.page);
          await setData(result);
          await setPerPage(res.data.perPage);
          await setTotalRows(res.data.total);
        }
        if (res.status === 400) {
        }
        setLoading(false);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const handleDelete = async (rowId: number) => {
    setLoading(true);
    await settingProductServices.deleteProductById(String(rowId)).then(
      async (res: any) => {
        if (res.status === 200) {
          let payload: GetProductFilter = {
            perPage: perPage,
            page: page,
            search: search,
            product_category_id: productCategoryID,
            is_include_delete: true,
          };
          await fetchProduct(payload); // fetch page 1 of users
        } else {
          let html: string = "Something went wrong";
          if (
            res.status === 422 &&
            res.response?.data?.message === "InvalidInput"
          ) {
            html = "ไม่สามารถลบได้เนื่องจากมีสินค้าที่ใช้หมวดนี้อยู่";
          }
          setLoading(false);
          Swal.fire({
            position: "top",
            title: "ลบข้อมูลไม่สำเร็จ",
            html: html,
            icon: "error",
            confirmButtonColor: "#13C296",
            confirmButtonText: "ปิด",
            footer: "โปรดถ่ายภาพเพื่อความสะดวกในการช่วยเหลือ",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <Spinner />
      <div className="flex justify-center font-bold">Loader...</div>
    </div>
  );

  const handlePageChange = async (page: any) => {
    await setPage(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    await setPerPage(newPerPage);
    await setPage(1);
  };

  const handleInputSearch = async (searchText: string) => {
    await setSearch(searchText);
    let payload: GetProductFilter = {
      perPage: perPage,
      page: page,
      search: searchText,
      product_category_id: productCategoryID,
      is_include_delete: true,
    };
    await fetchProduct(payload);
  };

  useEffect(() => {
    fetchProductCategories();
  }, []);

  useEffect(() => {
    let payload: GetProductFilter = {
      perPage: perPage,
      page: page,
      search: search,
      product_category_id: productCategoryID,
      is_include_delete: true,
    };
    fetchProduct(payload); // fetch page 1 of users
  }, [perPage, page, productCategoryID]);

  return (
    <div className="body-page">
      <div className="flex justify-between p-2">
        <div className="flex items-center">
          <label
            htmlFor="filter-product-category-id"
            className="block text-sm font-normal leading-6 text-grey-dark mr-2"
          >
            หมวดสินค้า:
          </label>
          <div className="relative">
            <select
              id="filter-product-category-id"
              name="filter-product-category-id"
              value={productCategoryID}
              onChange={(e) => setProductCategoryID(Number(e.target.value))}
              autoComplete="filter-product-category-id"
              className="min-w-[240px] block w-full rounded-md border-0 py-1.5 px-4 text-grey-dark shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 appearance-none cursor-pointer"
            >
              <option value={0} key={0}>
                แสดงทั้งหมด
              </option>
              {productCategory.map((productCategory) => (
                <option key={productCategory.id} value={productCategory.id}>
                  {productCategory.name}
                </option>
              ))}
            </select>
            <ArrowDown className="bg-white w-3 h-3 absolute top-1/2 right-2 translate-y-[-50%] translate-x-[-50%] fill-[#6B7280] pointer-events-none" />
          </div>
        </div>
        <div>
          <div className="flex rounded-md">
            <input
              placeholder="ค้นหา"
              className="border-l-2 border-t-2 border-b-2 pl-4 py-1 rounded-l-xl"
              onBlur={(e) => handleInputSearch(e.target.value)}
            />
            <button className="bg-grey-light-1 rounded-r-md w-10 flex justify-center items-center">
              <img src={searchImage} alt="" className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
      <div className="card rounded-2xl shadow-md my-2">
        <div className="px-4">
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles} // Apply custom styles here
            pagination
            paginationServer
            paginationPerPage={perPage}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<CustomLoader />}
            progressPending={loading}
            noDataComponent={<div className="p-6">ไม่พบข้อมูล</div>}
            persistTableHead
          />
        </div>
      </div>
    </div>
  );
};

export default ProductTable;
