import LayoutWeb from "../../../components/LayoutWeb";
import { DAY_OF_WEEK, DAYS } from "../../../enum/day";
import DraggableTable from "./components/DraggableTable";
import { TabButton } from "../../../components/tabs/TabButton";
import { useViewModel } from "./viewModel";
import ScheduleService from "../../../services/scheduleApi";
import RouteService from "../../../services/routeApi";
import { GetScheduleFilter } from "../../../types/schedule";
import { GetRouteFilter } from "../../../types/route";
import { useEffect, useState } from "react";
import LoaderPage from "../../../components/LoaderPage";

const TruckRoute = () => {
  const {
    // currentDay,
    // currentRoute,
    dayTabsList,
    // routeTabsList,
    // onClickTabsDay,
    // onClickRoute,
    // tempData,
    columns,
    // onSaveTruckRoute,
    // setCurrentItems,
  } = useViewModel();

  // const [dayRouteMap, setDayRouteMap] = useState<Record<DAYS, string>>(
  //   {} as Record<DAYS, string>
  // );

  const [loadingPage, setLoadingPage] = useState(true);
  const [currentDay, setCurrentDay] = useState(DAYS.SUNDAY);
  const [currentRoute, setCurrentRoute] = useState("");
  const [routeTabList, setRouteTabList]: any[] = useState([]);
  const [scheduleData, setSchedulesData]: any[] = useState([]);
  const [currentItems, setCurrentItems] = useState<any[]>([]);

  const [truck, setTruck] = useState("");
  const [driver, setDriver] = useState("");
  const [porterOne, setPorterOne] = useState("");
  const [porterTwo, setPorterTwo] = useState("");

  const onClickTabsDay = (_day: DAYS) => {
    if (_day === currentDay) {
      return;
    }
    let payload: GetRouteFilter = {
      day: DAY_OF_WEEK[_day],
      perPage: 1000,
      page: 1,
      startDate: "",
      endDate: "",
      search: "",
    };
    fetchRoutes(payload);
    setCurrentDay(_day);
  };

  const onClickRoute = (route: string) => {
    if (route === currentRoute) {
      return;
    }
    let payload: GetScheduleFilter = {
      day: DAY_OF_WEEK[currentDay],
      routerId: Number(route),
      perPage: 1000,
      page: 1,
      startDate: "",
      endDate: "",
      search: "",
    };
    getSchedules(payload);
    setCurrentRoute(route);
  };

  const getSchedules = async (payload: GetScheduleFilter) => {
    setLoadingPage(true);
    ScheduleService.getSchedules(payload).then(
      async (res: any) => {
        if (res.status === 200) {
          const schedules = res.data.data;
          const data: any[] = [];
          for (const i in schedules) {
            data.push({
              id: schedules[i]["id"],
              order: schedules[i]["order"],
              no: Number(i) + 1,
              customerId: schedules[i]["customer_id"],
              customerName: schedules[i]["customer_name"],
              locationName: schedules[i]["location_name"],
              address: schedules[i]["address"],
              location: schedules[i]["location"],
            });
          }

          setSchedulesData(data);
          setLoadingPage(false);
        }
        if (res.status === 400) {
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const fetchRoutes = async (payload: GetRouteFilter) => {
    setLoadingPage(true);
    RouteService.getRoutes(payload).then(
      async (res: any) => {
        if (res.status === 200) {
          if (res.data.data.length > 0) {
            const item = res.data.data[0];
            await setTruck(item["trucks"]["license_plate"]);
            await setDriver(item["drivers"]["full_name"]);
            await setPorterOne(item["porter_ones"]["full_name"]);
            await setPorterTwo(
              item["porter_twos"] ? item["porter_twos"]["full_name"] : ""
            );
            const data: any[] = [];
            await setCurrentRoute(item["id"]);
            for (const i in res.data.data) {
              data.push({
                name: res.data.data[i]["name"],
                key: res.data.data[i]["id"],
                color: "tab-deep-blue",
              });
            }
            await setRouteTabList(data);
            let payloadSchedule: GetScheduleFilter = {
              day: payload.day,
              routerId: item["id"],
              perPage: 1000,
              page: 1,
              startDate: "",
              endDate: "",
              search: "",
            };
            getSchedules(payloadSchedule);
          }
        } else {
          setLoadingPage(false);
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const onSaveTruckRoute = () => {
    let tempItems = [];

    for (const i in currentItems) {
      tempItems.push({
        id: currentItems[i]["id"],
        order: Number(i),
      });
    }
    const payload: any = {
      customer_schedule_order: tempItems,
    };
    ScheduleService.updateScheduleOrder(payload).then(
      async (res: any) => {
        if (res.status === 200) {
          let payloadSchedule: GetScheduleFilter = {
            day: DAY_OF_WEEK[currentDay],
            routerId: Number(currentRoute),
            perPage: 1000,
            page: 1,
            startDate: "",
            endDate: "",
            search: "",
          };
          getSchedules(payloadSchedule);
        } else {
          setLoadingPage(false);
        }
      },
      (error: any) => {
        console.log(error.message);
      }
    );
  };

  useEffect(() => {
    let payload: GetRouteFilter = {
      day: DAY_OF_WEEK[currentDay],
      perPage: 1000,
      page: 1,
      startDate: "",
      endDate: "",
      search: "",
    };
    fetchRoutes(payload); // fetch page 1 of users
  }, []);

  return (
    <LayoutWeb>
      <div className="flex justify-between">
        <div className="text-28 px-4 py-2 font-bold text-grey-dark">
          เส้นทางการเดินรถ
        </div>

        <div className="flex gap-2">
          <button className="bg-green hover:bg-green-hover text-white font-bold py-2.5 px-4 rounded-lg text-15">
            อัพเดทข้อมูล
          </button>
          <button
            onClick={onSaveTruckRoute}
            className="bg-orange hover:bg-orange-hover text-white font-bold py-2.5 px-4 rounded-lg text-15"
          >
            บันทึกข้อมูล
          </button>
        </div>
      </div>

      <div className="card rounded-lg shadow-md my-2 p-4 border border-[#99999970]">
        <div className="flex gap-2 flex-col">
          <div className="flex overflow-x-auto">
            {dayTabsList.map((entry) => (
              <TabButton
                tabsData={entry}
                currentSelect={currentDay}
                onClick={() => onClickTabsDay(entry.key as DAYS)}
              />
            ))}
          </div>
          <div className="flex overflow-x-auto">
            {routeTabList.map((entry: any) => (
              <TabButton
                tabsData={entry}
                currentSelect={currentRoute}
                onClick={() => onClickRoute(entry.key)}
              />
            ))}
          </div>
        </div>

        <div className="my-2 border border-grey-light-1" />
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
            <div className="grid grid-cols-2 gap-4 lg:grid-cols-4 p-4">
              <div className="flex gap-1 font-normal text-15 text-grey-dark">
                <div>รถยนต์ทะเบียน :</div>
                <div>{truck}</div>
              </div>
              <div className="flex gap-1 font-normal text-15 text-grey-dark">
                <div>พนักงานขับรถ :</div>
                <div>{driver}</div>
              </div>
              <div className="flex gap-1 font-normal text-15 text-grey-dark">
                <div>พนักงานเก็บขน(1) :</div>
                <div>{porterOne}</div>
              </div>
              <div className="flex gap-1 font-normal text-15 text-grey-dark">
                <div>พนักงานเก็บขน(2) :</div>
                <div>{porterTwo}</div>
              </div>
            </div>

            <DraggableTable
              tempData={scheduleData}
              columns={columns}
              onItemsChange={setCurrentItems}
              list={[]}
            />
          </>
        )}
      </div>
    </LayoutWeb>
  );
};

export default TruckRoute;
