import React from "react";

interface PermissionGroupData {
  title: string;
  children: any;
}

const PermissionGroup = (permissionGroup: PermissionGroupData) => (
  <div className="px-3 text-lg text-gray-600 border-b">
    <div className="border-b py-4 px-2">{permissionGroup.title}</div>
    {permissionGroup.children}
  </div>
);

export default PermissionGroup;
