import { cx } from "@emotion/css";
import { useViewModel } from "./viewModel";
import { Moment } from "moment";

interface TabCalendarProps {
  onClick: (date: Moment) => void;
}

export const TabCalendar = ({ onClick }: TabCalendarProps) => {
  const { selectedDate, onChangeWeek, weekDates, handleDateClick } =
    useViewModel();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-1">
        <button
          className="hover:bg-grey-light-1 px-2 rounded-full"
          onClick={() => onChangeWeek("prev")}
        >{`\u003C`}</button>
        <div className="flex gap-2 items-center">
          <span className="text-15 text-grey-dark">
            {`${weekDates[0].format("D MMM YYYY")} - ${weekDates[6].format(
              "D MMM YYYY"
            )}`}
          </span>
        </div>

        <button
          className="hover:bg-grey-light-1 px-2 rounded-full"
          onClick={() => onChangeWeek("next")}
        >{`\u003E`}</button>
      </div>

      <div className="flex gap-2 ">
        {weekDates.map((date, index) => (
          <div
            key={index}
            onClick={() => handleDateClick(date, onClick)}
            className={cx(
              "flex flex-col items-center gap-1 rounded-[5px] py-1.5 px-1 cursor-pointer min-w-[120px] h-[60px] justify-center",
              date.isSame(selectedDate)
                ? "bg-blue-1 text-white"
                : "bg-blue-3 text-grey-dark"
            )}
          >
            <div className="text-18 font-medium">{date.format("D")}</div>
            <div className="text-15 font-medium">วัน{date.format("dddd")}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
