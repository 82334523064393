import React, { useEffect, useRef, useState } from "react";
import {
  Amphuren,
  AnotherAddress,
  MainAddress,
  Province,
  Tambon,
} from "../../types/address";
import { BusinessType, CategoryTypes } from "../../types/businessType";
import { CustomerData } from "../../types/customer";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import { useNavigate, useParams } from "react-router-dom"; // Assuming you us
import * as Yup from "yup";
import CustomerHaederlForm from "../widget/haederCustomerForm";
import LoaderPage from "../LoaderPage";
import newCustomerRegisterServices from "../../services/newCustomerApi";
import Swal from "sweetalert2";

interface FormValues {
  contactType: string;
  office: string;
  branchOffice: string;
  branch_code: string;
  company_name: string;
  tax_id: string;
  category_type: string;
  business_type: string;
  business_name: string;
  post_code: string;
  province: string;
  amphuren: string;
  tambon: string;
  address: string;
  signer_full_name: string;
  signer_position: string;
  signer_phone: string;
  signer_email: string;
  signer_line_id: string;
  coordinator_full_name: string;
  coordinator_position: string;
  coordinator_phone: string;
  FormDoc: string;
  officeDoc: string;
  branch_codeDoc: string;
  branchOfficeDoc: string;
  contactTypeDoc: string;
  phoneNumber: string;
  signer_phoneDoc: string;
  addressDoc: string;
  post_codeDoc: string;
  provinceDoc: string;
  amphurenDoc: string;
  tambonDoc: string;
  tax_idDoc: string;
  company_nameDoc: string;
}
interface FormDataType {
  category_type_id: number;
  business_type_id: number;
  business_name: string;
  tax_id: string;
  signer_full_name: string;
  signer_position: string;
  signer_phone: string;
  signer_email: string;
  signer_line_id: string;
  coordinator_full_name: string;
  coordinator_position: string;
  coordinator_phone: string;
  is_use_another_address: boolean;
  main_address: MainAddress;
  another_address?: AnotherAddress; // This is optional
}
const validationSchema = Yup.object({
  contactType: Yup.string().required("กรุณาเลือกประเภทผู้ติดต่อ"),
  office: Yup.string().when("contactType", {
    is: (contactType: string) => contactType === "นิติบุคคล",
    then: (schema: Yup.StringSchema) =>
      schema.required("กรุณาระบุเลือกสำนักงาน"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  branch_code: Yup.string()
    .when("office", {
      is: (office: string) => {
        return office === "no";
      },
      then: (schema: Yup.StringSchema) => schema.required("กรุณาระบุรหัสสาขา"),
      otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
    })
    .matches(/^[0-9]+$/, "ต้องเป็นตัวเลขเท่านั้น")
    .length(5, "ต้องมี 5 หลัก"),
  branchOffice: Yup.string().when("office", {
    is: (office: string) => office === "no",
    then: (schema: Yup.StringSchema) => schema.required("กรุณาระบุชื่อสาขา"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  company_name: Yup.string().required("กรุณากรอกข้อมูล"),
  tax_id: Yup.string()
    .matches(/^[0-9]+$/, "ต้องเป็นตัวเลขเท่านั้น")
    .length(13, "ต้องมี 13 หลัก")
    .required("กรุณากรอกข้อมูล"),
  category_type: Yup.string().required("กรุณาเลือกหมวดหมู่"),
  business_type: Yup.string().required("กรุณาเลือกสถานพยาบาล"),
  business_name: Yup.string().required("กรุณากรอกข้อมูล"),
  post_code: Yup.string()
    .matches(/^[0-9]+$/, "ต้องเป็นตัวเลขเท่านั้น")
    .length(5, "ต้องมี 5 หลัก")
    .required("กรุณารหัสไปรษณีย์"),
  province: Yup.string().required("กรุณาเลือกจังหวัด"),
  amphuren: Yup.string().required("กรุณาเลือกอำเภอ"),
  tambon: Yup.string().required("กรุณาเลือกตำบล"),
  address: Yup.string().required("กรุณากรอกข้อมูล"),
  signer_full_name: Yup.string().required("กรุณากรอกข้อมูล"),
  signer_position: Yup.string().required("กรุณากรอกข้อมูล"),
  signer_phone: Yup.string()
    .matches(/^[0-9]+$/, "ต้องเป็นตัวเลขเท่านั้น")
    .min(9, "ต้องมี 9-10 หลัก")
    .max(10, "ต้องมี 9-10 หลัก")
    .required("กรุณากรอกข้อมูล"),
  signer_email: Yup.string().required("กรุณากรอกข้อมูล"),
  coordinator_phone: Yup.string()
    .matches(/^[0-9]+$/, "ต้องเป็นตัวเลขเท่านั้น")
    .min(9, "ต้องมี 9-10 หลัก")
    .max(10, "ต้องมี 9-10 หลัก"),
  FormDoc: Yup.string().required("กรุณาเลือกการใช้ข้อมูล"),
  contactTypeDoc: Yup.string().when("FormDoc", {
    is: (FormDoc: string) => {
      return FormDoc === "ใช้ข้อมูลอื่น";
    },
    then: (schema: Yup.StringSchema) =>
      schema.required("กรุณาระบุประเภทผู้ติดต่อ"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  officeDoc: Yup.string().when("FormDoc", {
    is: (FormDoc: string) => FormDoc === "ใช้ข้อมูลอื่น",
    then: (schema: Yup.StringSchema) =>
      schema.when("contactTypeDoc", {
        is: (contactTypeDoc: string) => contactTypeDoc === "นิติบุคคล",
        then: (schema: Yup.StringSchema) =>
          schema.required("กรุณาระบุสำนักงาน"),
        otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
      }),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  branch_codeDoc: Yup.string().when(["FormDoc", "contactTypeDoc"], {
    is: (FormDoc: string, contactTypeDoc: string) =>
      FormDoc === "ใช้ข้อมูลอื่น" && contactTypeDoc === "นิติบุคคล",
    then: (schema: Yup.StringSchema) =>
      schema.when("officeDoc", {
        is: (officeDoc: string) => officeDoc === "no",
        then: (schema: Yup.StringSchema) =>
          schema
            .required("กรุณาระบุรหัสสาขา")
            .matches(/^[0-9]+$/, "ต้องเป็นตัวเลขเท่านั้น")
            .length(5, "ต้องมี 5 หลัก"),
        otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
      }),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),

  branchOfficeDoc: Yup.string().when(["FormDoc", "contactTypeDoc"], {
    is: (FormDoc: string, contactTypeDoc: string) =>
      FormDoc === "ใช้ข้อมูลอื่น" && contactTypeDoc === "นิติบุคคล",
    then: (schema: Yup.StringSchema) =>
      schema.when("officeDoc", {
        is: (officeDoc: string) => officeDoc === "no",
        then: (schema: Yup.StringSchema) =>
          schema.required("กรุณาระบุชื่อสาขา"),
        otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
      }),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  company_nameDoc: Yup.string().when("FormDoc", {
    is: (FormDoc: string) => FormDoc === "ใช้ข้อมูลอื่น",
    then: (schema: Yup.StringSchema) => schema.required("กรุณากรอกข้อมูล"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  tax_idDoc: Yup.string()
    .when("FormDoc", {
      is: (FormDoc: string) => FormDoc === "ใช้ข้อมูลอื่น",
      then: (schema: Yup.StringSchema) => schema.required("กรุณากรอกข้อมูล"),
      otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
    })
    .matches(/^[0-9]+$/, "ต้องเป็นตัวเลขเท่านั้น")
    .length(13, "ต้องมี 13 หลัก"),
  post_codeDoc: Yup.string()
    .when("FormDoc", {
      is: (FormDoc: string) => FormDoc === "ใช้ข้อมูลอื่น",
      then: (schema: Yup.StringSchema) => schema.required("กรุณารหัสไปรษณีย์"),
      otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
    })
    .matches(/^[0-9]+$/, "ต้องเป็นตัวเลขเท่านั้น")
    .length(5, "ต้องมี 5 หลัก"),
  provinceDoc: Yup.string().when("FormDoc", {
    is: (FormDoc: string) => FormDoc === "ใช้ข้อมูลอื่น",
    then: (schema: Yup.StringSchema) => schema.required("กรุณาเลือกจังหวัด"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  amphurenDoc: Yup.string().when("FormDoc", {
    is: (FormDoc: string) => FormDoc === "ใช้ข้อมูลอื่น",
    then: (schema: Yup.StringSchema) => schema.required("กรุณาเลือกอำเภอ"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  tambonDoc: Yup.string().when("FormDoc", {
    is: (FormDoc: string) => FormDoc === "ใช้ข้อมูลอื่น",
    then: (schema: Yup.StringSchema) => schema.required("กรุณาเลือกตำบล"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  addressDoc: Yup.string().when("FormDoc", {
    is: (FormDoc: string) => FormDoc === "ใช้ข้อมูลอื่น",
    then: (schema: Yup.StringSchema) =>
      schema.required("กรุณาเลือกการใช้ข้อมูล"),
    otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
  }),
  signer_phoneDoc: Yup.string()
    .when("FormDoc", {
      is: (FormDoc: string) => FormDoc === "ใช้ข้อมูลอื่น",
      then: (schema: Yup.StringSchema) =>
        schema.required("กรุณาเลือกการใช้ข้อมูล"),
      otherwise: (schema: Yup.StringSchema) => schema.notRequired(),
    })
    .matches(/^[0-9]+$/, "ต้องเป็นตัวเลขเท่านั้น")
    .min(9, "ต้องมี 9-10 หลัก")
    .max(10, "ต้องมี 9-10 หลัก"),
});
const EditCustomerForm: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const [categoryTypes, setCategoryTypes] = useState<CategoryTypes[]>([]);
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [provincesDoc, setProvincesDoc] = useState<Province[]>([]);
  const [amphures, setAmphure] = useState<Amphuren[]>([]);
  const [amphuresDoc, setAmphureDoc] = useState<Amphuren[]>([]);
  const [tambons, setTambon] = useState<Tambon[]>([]);
  const [tambonsDoc, setTambonDoc] = useState<Tambon[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<string>("");
  const [selectedProvinceDoc, setSelectedProvinceDoc] = useState<string>("");
  const [newCustomer, setNewCustomer] = useState<CustomerData | null>(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const navigate = useNavigate();

  const initialValues: FormValues = {
    contactType: newCustomer?.addresses?.[0]?.is_natural_person
      ? "บุคคลธรรมดา"
      : "นิติบุคคล",
    office: newCustomer?.addresses?.[0]?.is_head_office ? "yes" : "no",
    branchOffice: newCustomer?.addresses?.[0]?.branch_name || "",
    branch_code: newCustomer?.addresses?.[0]?.branch_code || "",
    company_name: newCustomer?.addresses?.[0]?.company_name || "",
    tax_id: newCustomer?.addresses?.[0]?.tax_id || "",
    category_type: newCustomer?.category_type_id?.toString() || "",
    business_type: newCustomer?.business_type_id?.toString() || "",
    business_name: newCustomer?.business_name || "",
    post_code: newCustomer?.addresses?.[0]?.postal_code || "",
    province: newCustomer?.addresses?.[0]?.province_id?.toString() || "",
    amphuren: newCustomer?.addresses?.[0]?.amphure_id?.toString() || "",
    tambon: newCustomer?.addresses?.[0]?.tambon_id?.toString() || "",
    address: newCustomer?.addresses?.[0]?.address || "",
    signer_full_name: newCustomer?.signer_full_name || "",
    signer_position: newCustomer?.signer_position || "",
    signer_phone: newCustomer?.signer_phone || "",
    signer_email: newCustomer?.signer_email || "",
    signer_line_id: newCustomer?.signer_line_id || "",
    coordinator_full_name: newCustomer?.coordinator_full_name || "",
    coordinator_position: newCustomer?.coordinator_position || "",
    coordinator_phone: newCustomer?.coordinator_phone || "",
    FormDoc: newCustomer?.is_use_another_address
      ? "ใช้ข้อมูลอื่น"
      : "ใช้ข้อมูลข้างต้น",
    officeDoc:
      newCustomer?.is_use_another_address &&
      newCustomer?.addresses?.[1]?.is_head_office
        ? "yes"
        : "no",
    branch_codeDoc: newCustomer?.is_use_another_address
      ? newCustomer?.addresses?.[1]?.branch_code || ""
      : "",
    branchOfficeDoc: newCustomer?.is_use_another_address
      ? newCustomer?.addresses?.[1]?.branch_name || ""
      : "",
    contactTypeDoc:
      newCustomer?.is_use_another_address &&
      newCustomer?.addresses?.[1]?.is_natural_person
        ? "บุคคลธรรมดา"
        : "นิติบุคคล",
    phoneNumber: newCustomer?.addresses?.[0]?.phone || "",
    signer_phoneDoc: newCustomer?.is_use_another_address
      ? newCustomer?.addresses?.[1]?.phone || ""
      : "",
    addressDoc: newCustomer?.is_use_another_address
      ? newCustomer?.addresses?.[1]?.address || ""
      : "",
    post_codeDoc: newCustomer?.is_use_another_address
      ? newCustomer?.addresses?.[1]?.postal_code || ""
      : "",
    provinceDoc: newCustomer?.is_use_another_address
      ? newCustomer?.addresses?.[1]?.province_id?.toString() || ""
      : "",
    amphurenDoc: newCustomer?.is_use_another_address
      ? newCustomer?.addresses?.[1]?.amphure_id?.toString() || ""
      : "",
    tambonDoc: newCustomer?.is_use_another_address
      ? newCustomer?.addresses?.[1]?.tambon_id?.toString() || ""
      : "",
    tax_idDoc: newCustomer?.is_use_another_address
      ? newCustomer?.addresses?.[1]?.tax_id || ""
      : "",
    company_nameDoc: newCustomer?.is_use_another_address
      ? newCustomer?.addresses?.[1]?.company_name || ""
      : "",
  };

  const contactTypeRef = useRef<HTMLInputElement>(null);
  const officeRef = useRef<HTMLInputElement>(null);
  const branchOfficeRef = useRef<HTMLInputElement>(null);
  const branch_codeRef = useRef<HTMLInputElement>(null);
  const company_nameRef = useRef<HTMLInputElement>(null);
  const tax_idRef = useRef<HTMLInputElement>(null);
  const category_typeRef = useRef<HTMLInputElement>(null);
  const business_typeRef = useRef<HTMLInputElement>(null);
  const business_nameRef = useRef<HTMLInputElement>(null);
  const post_codeRef = useRef<HTMLInputElement>(null);
  const provinceRef = useRef<HTMLInputElement>(null);
  const amphurenRef = useRef<HTMLInputElement>(null);
  const tambonRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const signer_full_nameRef = useRef<HTMLInputElement>(null);
  const signer_positionRef = useRef<HTMLInputElement>(null);
  const signer_phoneRef = useRef<HTMLInputElement>(null);
  const signer_emailRef = useRef<HTMLInputElement>(null);
  const coordinator_phoneRef = useRef<HTMLInputElement>(null);
  const FormDocRef = useRef<HTMLInputElement>(null);
  const officeDocRef = useRef<HTMLInputElement>(null);
  const branch_codeDocRef = useRef<HTMLInputElement>(null);
  const branchOfficeDocRef = useRef<HTMLInputElement>(null);
  const contactTypeDocRef = useRef<HTMLInputElement>(null);
  const company_nameDocRef = useRef<HTMLInputElement>(null);
  const tax_idDocRef = useRef<HTMLInputElement>(null);
  const post_codeDocRef = useRef<HTMLInputElement>(null);
  const provinceDocRef = useRef<HTMLInputElement>(null);
  const amphurenDocRef = useRef<HTMLInputElement>(null);
  const tambonDocRef = useRef<HTMLInputElement>(null);
  const addressDocRef = useRef<HTMLInputElement>(null);
  const signer_phoneDocRef = useRef<HTMLInputElement>(null);
  const focusFirstError = (errors: FormikErrors<FormValues>) => {
    if (errors.contactType && contactTypeRef.current) {
      contactTypeRef.current.focus();
    } else if (errors.office && officeRef.current) {
      officeRef.current.focus();
    } else if (errors.branch_code && branch_codeRef.current) {
      branch_codeRef.current.focus();
    } else if (errors.branchOffice && branchOfficeRef.current) {
      branchOfficeRef.current.focus();
    } else if (errors.company_name && company_nameRef.current) {
      company_nameRef.current.focus();
    } else if (errors.tax_id && tax_idRef.current) {
      tax_idRef.current.focus();
    } else if (errors.category_type && category_typeRef.current) {
      category_typeRef.current.focus();
    } else if (errors.business_type && business_typeRef.current) {
      business_typeRef.current.focus();
    } else if (errors.business_name && business_nameRef.current) {
      business_nameRef.current.focus();
    } else if (errors.post_code && post_codeRef.current) {
      post_codeRef.current.focus();
    } else if (errors.province && provinceRef.current) {
      provinceRef.current.focus();
    } else if (errors.amphuren && amphurenRef.current) {
      amphurenRef.current.focus();
    } else if (errors.tambon && tambonRef.current) {
      tambonRef.current.focus();
    } else if (errors.address && addressRef.current) {
      addressRef.current.focus();
    } else if (errors.signer_full_name && signer_full_nameRef.current) {
      signer_full_nameRef.current.focus();
    } else if (errors.signer_position && signer_positionRef.current) {
      signer_positionRef.current.focus();
    } else if (errors.signer_phone && signer_phoneRef.current) {
      signer_phoneRef.current.focus();
    } else if (errors.signer_email && signer_emailRef.current) {
      signer_emailRef.current.focus();
    } else if (errors.FormDoc && FormDocRef.current) {
      FormDocRef.current.focus();
    } else if (errors.officeDoc && officeDocRef.current) {
      officeDocRef.current.focus();
    } else if (errors.branch_codeDoc && branch_codeDocRef.current) {
      branch_codeDocRef.current.focus();
    } else if (errors.branchOfficeDoc && branchOfficeDocRef.current) {
      branchOfficeDocRef.current.focus();
    } else if (errors.contactTypeDoc && contactTypeDocRef.current) {
      contactTypeDocRef.current.focus();
    } else if (errors.company_nameDoc && company_nameDocRef.current) {
      company_nameDocRef.current.focus();
    } else if (errors.tax_idDoc && tax_idDocRef.current) {
      tax_idDocRef.current.focus();
    } else if (errors.post_codeDoc && post_codeDocRef.current) {
      post_codeDocRef.current.focus();
    } else if (errors.provinceDoc && provinceDocRef.current) {
      provinceDocRef.current.focus();
    } else if (errors.amphurenDoc && amphurenDocRef.current) {
      amphurenDocRef.current.focus();
    } else if (errors.tambonDoc && tambonDocRef.current) {
      tambonDocRef.current.focus();
    } else if (errors.addressDoc && addressDocRef.current) {
      addressDocRef.current.focus();
    } else if (errors.signer_phoneDoc && signer_phoneDocRef.current) {
      signer_phoneDocRef.current.focus();
    }
  };
  const fetchCategoryTypes = async () => {
    try {
      const response = await newCustomerRegisterServices.getCategoryTypes();
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch provinces", err);
    }
  };
  const setUpCategoryTypes = async () => {
    let category = await fetchCategoryTypes();
    setCategoryTypes(category);
    if (newCustomer?.category_type_id) {
      let catId = newCustomer.category_type_id;
      handleSelectCateegory(catId.toString());
    }
  };

  const fetchBusinessType = async (value: string) => {
    try {
      const response = await newCustomerRegisterServices.getBusinessTypes(
        value
      );
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetchBusinessType", err);
    }
  };

  const handleSelectCateegory = async (value: string) => {
    let newListBusinessType = await fetchBusinessType(value);
    setBusinessTypes(newListBusinessType);
  };
  const setUpProvices = async () => {
    let provinces = await fetchProvinces();
    setProvinces(provinces);
  };
  const setUpProvicesDoc = async () => {
    let provinces = await fetchProvinces();
    setProvincesDoc(provinces);
  };
  const fetchProvinces = async () => {
    try {
      const response = await newCustomerRegisterServices.getProvices();
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        console.log("else setProvinces", response);
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch provinces", err);
    }
  };
  const fetchAmphure = async (value: string) => {
    try {
      const response = await newCustomerRegisterServices.getAmphure(value);
      if (Array.isArray(response.data)) {
        return response.data;
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch fetchAmphure", err);
    }
  };
  const handleInputZipCode = async (valueZipCodeInput: string) => {
    let newProvinces = await fetchProvinces();
    if (valueZipCodeInput) {
      const response = await newCustomerRegisterServices.postcodeAddresses(
        valueZipCodeInput
      );
      if (Array.isArray(response.data) && response.data.length > 0) {
        const listIdProvinceByZipCode: Set<number> = new Set();
        const listIdDritrictByZipCode: Set<number> = new Set();
        const listIdTombonByZipCode: Set<number> = new Set();
        response.data.forEach((element: any) => {
          if (element.amphure && element.amphure.province) {
            listIdProvinceByZipCode.add(element.amphure.province.id);
            listIdDritrictByZipCode.add(element.amphure.id);
          }
          listIdTombonByZipCode.add(element.id);
        });
        const filteredProvinces = await newProvinces.filter(
          (province: { id: number }) => listIdProvinceByZipCode.has(province.id)
        );
        handleSelectProvinces(filteredProvinces[0].id);
        await setProvinces(filteredProvinces);
        return;
      }
    }
    await setProvinces(newProvinces);
  };
  const handleInputZipCodeDoc = async (valueZipCodeInput: string) => {
    let newProvinces = await fetchProvinces();
    if (valueZipCodeInput) {
      const response = await newCustomerRegisterServices.postcodeAddresses(
        valueZipCodeInput
      );
      if (Array.isArray(response.data) && response.data.length > 0) {
        const listIdProvinceByZipCode: Set<number> = new Set();
        const listIdDritrictByZipCode: Set<number> = new Set();
        const listIdTombonByZipCode: Set<number> = new Set();
        response.data.forEach((element: any) => {
          if (element.amphure && element.amphure.province) {
            listIdProvinceByZipCode.add(element.amphure.province.id);
            listIdDritrictByZipCode.add(element.amphure.id);
          }
          listIdTombonByZipCode.add(element.id);
        });
        const filteredProvinces = await newProvinces.filter(
          (province: { id: number }) => listIdProvinceByZipCode.has(province.id)
        );
        handleSelectProvincesDoc(filteredProvinces[0].id);
        await setProvincesDoc(filteredProvinces);
        return;
      }
    }
    await setProvincesDoc(newProvinces);
  };
  const handleSelectProvinces = async (value: string) => {
    setSelectedProvince(value);
    let newListAmphure = await fetchAmphure(value);
    setAmphure(newListAmphure);
  };
  const handleSelectProvincesDoc = async (value: string) => {
    setSelectedProvinceDoc(value);
    let newListAmphure = await fetchAmphure(value);
    setAmphureDoc(newListAmphure);
  };
  const handleSelectDistrict = async (value: string) => {
    if (!selectedProvince) {
      return;
    }
    try {
      const response = await newCustomerRegisterServices.getTambon(
        selectedProvince,
        value
      );
      if (Array.isArray(response.data)) {
        setTambon(response.data);
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch handleSelectDistrict", err);
    }
  };
  const handleSelectDistrictDoc = async (value: string) => {
    if (!selectedProvinceDoc) {
      return;
    }
    try {
      const response = await newCustomerRegisterServices.getTambon(
        selectedProvinceDoc,
        value
      );
      if (Array.isArray(response.data)) {
        setTambonDoc(response.data);
      } else {
        throw new Error("Unexpected data structure");
      }
    } catch (err) {
      console.error("Failed to fetch handleSelectDistrict", err);
    }
  };
  const handleSubmitRegisterNewCustomer = async (values: FormValues) => {
    let formData: FormDataType = {
      category_type_id: Number(values.category_type),
      business_type_id: Number(values.business_type),
      business_name: values.business_name,
      tax_id: values.tax_id,
      signer_full_name: values.signer_full_name,
      signer_position: values.signer_position,
      signer_phone: values.signer_phone,
      signer_email: values.signer_email,
      signer_line_id: values.signer_line_id,
      coordinator_full_name: values.coordinator_full_name,
      coordinator_position: values.coordinator_position,
      coordinator_phone: values.coordinator_phone,
      main_address: {
        id: Number(newCustomer?.addresses?.[0]?.id),
        is_natural_person: values.contactType === "บุคคลธรรมดา" ? true : false,
        is_head_office: values.office === "yes" ? true : false,
        branch_name: values.branchOffice,
        branch_code: values.branch_code,
        company_name: values.company_name,
        tax_id: values.tax_id,
        postal_code: values.post_code,
        province_id: Number(values.province),
        amphure_id: Number(values.amphuren),
        tambon_id: Number(values.tambon),
        address: values.address,
        phone: values.phoneNumber,
      },
      is_use_another_address: values.FormDoc === "ใช้ข้อมูลอื่น" ? true : false,
      another_address:
        values.FormDoc === "ใช้ข้อมูลข้างต้น"
          ? undefined
          : {
              ...(newCustomer?.addresses?.[1]?.id
                ? { id: Number(newCustomer.addresses[1].id) }
                : {}), // Conditionally add 'id' only if it exists
              is_natural_person:
                values.contactTypeDoc === "บุคคลธรรมดา" ? true : false,
              is_head_office: values.officeDoc === "yes" ? true : false,
              branch_name: values.branchOfficeDoc,
              branch_code: values.branch_codeDoc,
              company_name: values.company_nameDoc,
              tax_id: values.tax_idDoc,
              postal_code: values.post_codeDoc,
              province_id: Number(values.provinceDoc),
              amphure_id: Number(values.amphurenDoc),
              tambon_id: Number(values.tambonDoc),
              address: values.addressDoc,
              phone: values.signer_phoneDoc,
            },
    };
    try {
      const customerId = id ?? "";
      const addCustomerResponse =
        await newCustomerRegisterServices.updateCustomer(customerId, formData);

      if (addCustomerResponse.status === 200) {
        Swal.fire({
          position: "top",
          title: "แก้ไขข้อมูลสำเร็จแล้ว",
          icon: "success",
          confirmButtonColor: "#3758F9",
          confirmButtonText: "เสร็จสิ้น",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      } else if (addCustomerResponse.status === 400) {
        Swal.fire({
          position: "top",
          title: "แก้ไขข้อมูลไม่สำเร็จ",
          html: addCustomerResponse.response.data.message,
          icon: "error",
          confirmButtonColor: "#3758F9",
          confirmButtonText: "ปิด",
          footer: "โปรดถ่ายภาพเพื่อความสะดวกในการช่วยเหลือ",
        });
      } else {
        Swal.fire({
          position: "top",
          icon: "error",
          title: "แก้ไขข้อมูลไม่สำเร็จ",
          html: addCustomerResponse.response.data.message,
          text: "โปรดลงติดต่อ System admin",
          footer: "โปรดถ่ายภาพเพื่อความรวดเร็วในการช่วยเหลือ",
        });
      }
    } catch (error) {
      console.error("Error occurred during customer registration:", error);
    }
  };

  const getNewCustomerById = async (id: string) => {
    try {
      const responseCutomer =
        await newCustomerRegisterServices.getNewCustomerByid(id);
      return responseCutomer.data; // Return the customer data
    } catch (error) {
      console.log("Error in getNewCustomerById:", error);
      throw error; // Optionally rethrow the error for further handling
    }
  };
  const setNewCustomerById = async () => {
    try {
      if (id) {
        const newCustomer = await getNewCustomerById(id);
        setNewCustomer(newCustomer);
        // let newListAmphure = await fetchAmphure(newCustomer.addresses[0].province_id.toString());
        setAmphure(
          await fetchAmphure(newCustomer.addresses[0].province_id.toString())
        );
        let listTombon = await newCustomerRegisterServices.getTambon(
          newCustomer.addresses[0].province_id.toString(),
          newCustomer.addresses[0].amphure_id.toString()
        );
        setTambon(listTombon.data);
        setAmphureDoc(
          await fetchAmphure(newCustomer.addresses[1].province_id.toString())
        );
        listTombon = await newCustomerRegisterServices.getTambon(
          newCustomer.addresses[1].province_id.toString(),
          newCustomer.addresses[1].amphure_id.toString()
        );
        setTambonDoc(listTombon.data);
      }
    } catch (error) {}
  };
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setUpCategoryTypes();
    setNewCustomerById();
    setLoadingPage(false);
    setUpProvices();
    setUpProvicesDoc();
  }, [id]);
  return (
    <>
      {loadingPage ? (
        <LoaderPage />
      ) : (
        <>
          <div className="container mx-auto ">
            <CustomerHaederlForm customer={newCustomer} onBack={handleBack} />
            <div className="p-4 card rounded-2xl shadow-md my-2">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  handleSubmitRegisterNewCustomer(values);
                }}
              >
                {({ setFieldValue, values, errors, touched, handleSubmit }) => (
                  <Form
                    className="space-y-6"
                    onSubmit={(e) => {
                      console.log("Errors: ", errors); // Log validation errors
                      console.log("Touched: ", touched); // Log touched fields
                      e.preventDefault();
                      focusFirstError(errors); // Focus on the first invalid input
                      handleSubmit(e);
                    }}
                  >
                    <div className="flex justify-between">
                      <div className="text-2xl mb-4 w-full">
                        ข้อมูลการทำสัญญา
                      </div>
                      <div className="flex m-auto justify-end gap-2 w-full">
                        <button
                          type="reset"
                          className="mt-6 w-fit md:w-[20%] bg-white text-[#535252] py-2 px-4 border-[1px] rounded-[6px] border-[#6B7280] "
                        >
                          ยกเลิก
                        </button>
                        <button
                          type="submit"
                          className="mt-6 w-fit md:w-[20%] bg-[#13C296] text-white py-2 px-4 rounded-md hover:bg-[#11AE87] "
                        >
                          บันทึก
                        </button>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 md:grid-cols-8 xl:grid-cols-10 gap-4 items-center">
                        <div className="col-span-2 md:col-span-8 xl:col-span-10">
                          <label className="block text-md font-medium text-gray-700 mb-2">
                            ประเภทผู้ติดต่อ :
                          </label>
                        </div>
                        <div className="md:col-span-2 xl:col-span-2">
                          <div className="flex space-x-4">
                            <label className="inline-flex items-center">
                              <Field
                                type="radio"
                                name="contactType"
                                value="บุคคลธรรมดา"
                                innerRef={contactTypeRef}
                                className="form-radio"
                              />
                              <span className="ml-2 text-md">บุคคลธรรมดา</span>
                            </label>
                          </div>
                        </div>
                        <div className="md:col-span-2 xl:col-span-2">
                          <div className="flex space-x-4">
                            <label className="inline-flex items-center">
                              <Field
                                type="radio"
                                name="contactType"
                                value="นิติบุคคล"
                                className="form-radio"
                              />
                              <span className="ml-2 text-md">นิติบุคคล</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-span-full">
                          <ErrorMessage
                            name="contactType"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        {values.contactType === "นิติบุคคล" && (
                          <>
                            <div className="md:col-start-3 md:col-span-2 xl:col-start-3 xl:col-span-2">
                              <div className="flex space-x-4">
                                <label className="inline-flex items-center">
                                  <Field
                                    type="radio"
                                    name="office"
                                    value="yes"
                                    innerRef={contactTypeRef}
                                    className="form-radio"
                                    onChange={(e: {
                                      target: { value: string };
                                    }) => {
                                      setFieldValue("office", e.target.value);
                                    }}
                                  />
                                  <span className="h-9 ml-2 text-md flex items-center">
                                    สำนักงานใหญ่
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="md:col-span-1 xl:col-span-1">
                              <div className="flex space-x-4">
                                <label className="inline-flex items-center">
                                  <Field
                                    type="radio"
                                    name="office"
                                    value="no"
                                    className="form-radio"
                                    onChange={(e: {
                                      target: { value: string };
                                    }) => {
                                      setFieldValue("office", e.target.value);
                                    }}
                                  />
                                  <span className="h-9 ml-2 text-md flex items-center">
                                    สาขา
                                  </span>
                                </label>
                              </div>
                            </div>
                            {values.office === "no" && (
                              <>
                                <div className="col-span-2 md:col-span-3 xl:col-span-5 md:flex ">
                                  <Field
                                    name="branch_code"
                                    innerRef={branch_codeRef}
                                    className={`h-8 mt-1 block w-full md:w-[45%]  border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md border-[1px] rounded-[6px] ${
                                      errors.branch_code && touched.branch_code
                                        ? "border-red-500"
                                        : ""
                                    }`}
                                    placeholder="   รหัสสาขา"
                                    maxLength={5}
                                  />
                                  <Field
                                    name="branchOffice"
                                    innerRef={branchOfficeRef}
                                    className={`h-8 md:ml-2 mt-3 md:mt-1 block w-full md:w-[45%]  border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md border-[1px] rounded-[6px] ${
                                      errors.branchOffice &&
                                      touched.branchOffice
                                        ? "border-red-500"
                                        : ""
                                    }`}
                                    placeholder="   ชื่อสาขา"
                                  />
                                </div>
                              </>
                            )}
                            <div className="md:col-start-3 md:col-span-2 xl:col-start-3 xl:col-span-3">
                              <ErrorMessage
                                name="office"
                                component="div"
                                className="text-red-500 text-sm"
                              />
                            </div>
                            <div className="col-span-2 md:col-span-2 xl:col-span-2">
                              <ErrorMessage
                                name="branch_code"
                                component="div"
                                className="text-red-500 text-sm"
                              />
                            </div>
                            <div className="col-span-2 md:pl-[20%] md:col-span-2 xl:pl-[14%] xl:col-span-2">
                              <ErrorMessage
                                name="branchOffice"
                                component="div"
                                className="text-red-500 text-sm"
                              />
                            </div>
                          </>
                        )}
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          {values.contactType === "บุคคลธรรมดา"
                            ? "ชื่อ - นามสกุล"
                            : "ห้างหุ้นส่วน/บริษัท"}
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          name="company_name"
                          innerRef={company_nameRef}
                          className={`h-8 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md border-[1px] rounded-[6px] ${
                            errors.company_name && touched.company_name
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="company_name"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          {values.contactType === "บุคคลธรรมดา"
                            ? "เลขประจำตัวประชาชน"
                            : "เลขทะเบียนนิติบุคคล"}
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          name="tax_id"
                          innerRef={tax_idRef}
                          maxLength={13}
                          className={`h-8 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.tax_id && touched.tax_id
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="tax_id"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div>
                        <label className="block text-md font-medium text-gray-700">
                          หมวดหมู่สถานพยาบาล
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          as="select"
                          name="category_type"
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.category_type && touched.category_type
                              ? "border-red-500"
                              : ""
                          }`}
                          innerRef={category_typeRef}
                          onChange={(e: { target: { value: string } }) => {
                            setFieldValue("category_type", e.target.value);
                            handleSelectCateegory(e.target.value);
                          }}
                        >
                          <option value={0} selected>
                            เลือกหมวดหมู่
                          </option>
                          {categoryTypes.map((categoryType) => (
                            <option
                              key={categoryType.id}
                              value={categoryType.id}
                            >
                              {categoryType.name}
                            </option>
                          ))}
                          <ErrorMessage
                            name="category_type"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </Field>
                      </div>

                      <div>
                        <label className="block text-md font-medium text-gray-700">
                          สถานพยาบาล
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          as="select"
                          name="business_type"
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.business_type && touched.business_type
                              ? "border-red-500"
                              : ""
                          }`}
                          innerRef={business_typeRef}
                        >
                          <option selected>
                            {"เลือกสถานพยาบาล (เลือกหมวดหมู่ก่อน)"}
                          </option>
                          {businessTypes.map((businessType) => (
                            <option
                              key={businessType.id}
                              value={businessType.id}
                            >
                              {businessType.name}
                            </option>
                          ))}
                          <ErrorMessage
                            name="business_type"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </Field>
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          ชื่อ <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          name="business_name"
                          innerRef={business_nameRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.business_name && touched.business_name
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="business_name"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          รหัสไปรษณีย์{" "}
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          name="post_code"
                          innerRef={post_codeRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.post_code && touched.post_code
                              ? "border-red-500"
                              : ""
                          }`}
                          maxLength={5}
                          onBlur={(e: { target: { value: string } }) =>
                            handleInputZipCode(e.target.value)
                          }
                        />
                        <ErrorMessage
                          name="post_code"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div>
                        <label className="block text-md font-medium text-gray-700">
                          จังหวัด
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          as="select"
                          name="province"
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.province && touched.province
                              ? "border-red-500"
                              : ""
                          }`}
                          innerRef={provinceRef}
                          onChange={(e: { target: { value: any } }) => {
                            setFieldValue("province", e.target.value);
                            handleSelectProvinces(e.target.value);
                          }}
                        >
                          <option selected>{"เลือกจังหวัด"}</option>
                          {provinces.map((provinces) => (
                            <option key={provinces.id} value={provinces.id}>
                              {provinces.name_th}
                            </option>
                          ))}
                          <ErrorMessage
                            name="province"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </Field>
                      </div>
                      <div>
                        <label className="block text-md font-medium text-gray-700">
                          อำเภอ
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          as="select"
                          name="amphuren"
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.amphuren && touched.amphuren
                              ? "border-red-500"
                              : ""
                          }`}
                          innerRef={amphurenRef}
                          onChange={(e: { target: { value: any } }) => {
                            setFieldValue("amphuren", e.target.value);
                            handleSelectDistrict(e.target.value);
                          }}
                        >
                          <option disabled>
                            {"เลือกอำเภอ (เลือกจังหวัดก่อน)"}
                          </option>
                          {amphures.map((amphure) => (
                            <option key={amphure.id} value={amphure.id}>
                              {amphure.name_th}
                            </option>
                          ))}
                          <ErrorMessage
                            name="amphuren"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </Field>
                      </div>
                      <div>
                        <label className="block text-md font-medium text-gray-700">
                          ตำบล
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          as="select"
                          name="tambon"
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.tambon && touched.tambon
                              ? "border-red-500"
                              : ""
                          }`}
                          innerRef={tambonRef}
                        >
                          <option selected>
                            {"เลือกตำบล (เลือกอำเภอก่อน)"}
                          </option>
                          {tambons.map((tambon) => (
                            <option key={tambon.id} value={tambon.id}>
                              {tambon.name_th}
                            </option>
                          ))}
                          <ErrorMessage
                            name="tambon"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </Field>
                      </div>

                      {/* Address */}
                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          ที่อยู่<span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          name="address"
                          as="textarea"
                          innerRef={addressRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.address && touched.address
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          ผู้มีอำนาจรับผิดชอบ/ลงนามสัญญา
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          name="signer_full_name"
                          innerRef={signer_full_nameRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.signer_full_name && touched.signer_full_name
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="signer_full_name"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          ตำแหน่ง
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          name="signer_position"
                          innerRef={signer_positionRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.signer_position && touched.signer_position
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="signer_position"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          เบอร์โทรศัพท์เพื่อติดต่อ
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          name="signer_phone"
                          innerRef={signer_phoneRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.signer_phone && touched.signer_phone
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="signer_phone"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          อีเมล
                          <span className="text-red-500 ml-1">*</span>
                        </label>
                        <Field
                          type="email"
                          name="signer_email"
                          innerRef={signer_emailRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.signer_email && touched.signer_email
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="signer_email"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          LIND ID
                        </label>
                        <Field
                          name="signer_line_id"
                          // innerRef={signer_line_idRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.signer_line_id && touched.signer_line_id
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="signer_line_id"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          ผู้ประสานงาน
                        </label>
                        <Field
                          name="coordinator_full_name"
                          // innerRef={coordinator_full_nameRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.coordinator_full_name &&
                            touched.coordinator_full_name
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="coordinator_full_name"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          ตำแหน่ง
                        </label>
                        <Field
                          name="coordinator_position"
                          // innerRef={coordinator_full_nameRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.coordinator_position &&
                            touched.coordinator_position
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="coordinator_position"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      {/* Phone Number */}
                      <div className="items-center m-1">
                        <label className="block text-md font-medium text-gray-700">
                          เบอรโทรศัพท์เพื่อติดต่อ
                        </label>
                        <Field
                          name="coordinator_phone"
                          innerRef={coordinator_phoneRef}
                          className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                            errors.coordinator_phone &&
                            touched.coordinator_phone
                              ? "border-red-500"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>
                    <div className="col-span-full w-full border-t border-gray-300"></div>
                    <div className="text-2xl text-gray-600 mb-4 my-2 p-4">
                      ข้อมูลใช้สำหรับการออกเอกสารใบแจ้งหนี้/ใบเสร็จรับเงิน
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 md:grid-cols-8 xl:grid-cols-10 gap-4 items-center">
                        <div className="md:col-span-2 xl:col-span-2">
                          <div className="flex space-x-4">
                            <label className="inline-flex items-center">
                              <Field
                                type="radio"
                                name="FormDoc"
                                innerRef={FormDocRef}
                                value="ใช้ข้อมูลข้างต้น"
                                className="form-radio"
                              />
                              <span className="ml-2 text-md">
                                ใช้ข้อมูลข้างต้น
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="md:col-span-2 xl:col-span-2">
                          <div className="flex space-x-4">
                            <label className="inline-flex items-center">
                              <Field
                                type="radio"
                                name="FormDoc"
                                value="ใช้ข้อมูลอื่น"
                                className="form-radio"
                              />
                              <span className="ml-2 text-md">
                                ใช้ข้อมูลอื่น
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {values.FormDoc === "ใช้ข้อมูลอื่น" && (
                        <>
                          <div className="md:col-span-2 xl:col-span-2 grid grid-cols-2 md:grid-cols-8 xl:grid-cols-10 gap-4 items-center">
                            <div className="col-span-2 md:col-span-8 xl:col-span-10">
                              <label className="block text-md font-medium text-gray-700 mb-2">
                                ประเภทผู้ติดต่อ :
                              </label>
                            </div>
                            <div className="md:col-span-2 xl:col-span-2">
                              <div className="flex space-x-4">
                                <label className="inline-flex items-center">
                                  <Field
                                    type="radio"
                                    name="contactTypeDoc"
                                    value="บุคคลธรรมดา"
                                    innerRef={contactTypeDocRef}
                                    className="form-radio"
                                  />
                                  <span className="ml-2 text-md">
                                    บุคคลธรรมดา
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="md:col-span-2 xl:col-span-2">
                              <div className="flex space-x-4">
                                <label className="inline-flex items-center">
                                  <Field
                                    type="radio"
                                    name="contactTypeDoc"
                                    value="นิติบุคคล"
                                    className="form-radio"
                                  />
                                  <span className="ml-2 text-md">
                                    นิติบุคคล
                                  </span>
                                </label>
                              </div>
                            </div>
                            {values.contactTypeDoc === "นิติบุคคล" && (
                              <>
                                <div className="md:col-start-3 md:col-span-2 xl:col-start-3 xl:col-span-2">
                                  <div className="flex space-x-4">
                                    <label className="inline-flex items-center">
                                      <Field
                                        type="radio"
                                        name="officeDoc"
                                        value="yes"
                                        innerRef={officeDocRef}
                                        className="form-radio"
                                      />
                                      <span className="h-9 ml-2 text-md flex items-center">
                                        สำนักงานใหญ่
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="md:col-span-1 xl:col-span-1">
                                  <div className="flex space-x-4">
                                    <label className="inline-flex items-center">
                                      <Field
                                        type="radio"
                                        name="officeDoc"
                                        value="no"
                                        className="form-radio"
                                      />
                                      <span className="h-9 ml-2 text-md flex items-center">
                                        สาขา
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                {values.officeDoc === "no" && (
                                  <>
                                    <div className="col-span-2 md:col-span-3 xl:col-span-5 md:flex ">
                                      <Field
                                        name="branch_codeDoc"
                                        innerRef={branch_codeDocRef}
                                        className={`h-8 mt-1 block w-full md:w-[45%]  border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md border-[1px] rounded-[6px] ${
                                          errors.branch_codeDoc &&
                                          touched.branch_codeDoc
                                            ? "border-red-500"
                                            : ""
                                        }`}
                                        placeholder="   รหัสสาขา"
                                      />
                                      <Field
                                        name="branchOfficeDoc"
                                        innerRef={branchOfficeDocRef}
                                        className={`h-8 md:ml-2 mt-3 md:mt-1 block w-full md:w-[45%]  border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md border-[1px] rounded-[6px] ${
                                          errors.branchOfficeDoc &&
                                          touched.branchOfficeDoc
                                            ? "border-red-500"
                                            : ""
                                        }`}
                                        placeholder="   ชื่อสาขา"
                                      />
                                    </div>
                                  </>
                                )}
                                <div className="md:col-start-3 md:col-span-2 xl:col-start-3 xl:col-span-3">
                                  <ErrorMessage
                                    name="officeDoc"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </div>
                                <div className="col-span-2 md:col-span-2 xl:col-span-2">
                                  <ErrorMessage
                                    name="branch_codeDoc"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </div>
                                <div className="col-span-2 md:pl-[20%] md:col-span-2 xl:pl-[14%] xl:col-span-2">
                                  <ErrorMessage
                                    name="branchOfficeDoc"
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          <div className="items-center m-1">
                            <label className="block text-md font-medium text-gray-700">
                              {values.contactTypeDoc === "บุคคลธรรมดา"
                                ? "ชื่อ - นามสกุล"
                                : "ห้างหุ้นส่วน/บริษัท"}
                              <span className="text-red-500 ml-1">*</span>
                            </label>
                            <Field
                              name="company_nameDoc"
                              innerRef={company_nameDocRef}
                              className={`h-8 mt-1 block w-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md border-[1px] rounded-[6px] ${
                                errors.company_nameDoc &&
                                touched.company_nameDoc
                                  ? "border-red-500"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="company_nameDoc"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>

                          <div className="items-center m-1">
                            <label className="block text-md font-medium text-gray-700">
                              {values.contactTypeDoc === "บุคคลธรรมดา"
                                ? "เลขประจำตัวประชาชน"
                                : "เลขทะเบียนนิติบุคคล"}
                              <span className="text-red-500 ml-1">*</span>
                            </label>
                            <Field
                              name="tax_idDoc"
                              innerRef={tax_idDocRef}
                              maxLength={13}
                              className={`h-8 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                                errors.tax_idDoc && touched.tax_idDoc
                                  ? "border-red-500"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="tax_idDoc"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="items-center m-1">
                            <label className="block text-md font-medium text-gray-700">
                              รหัสไปรษณีย์{" "}
                              <span className="text-red-500 ml-1">*</span>
                            </label>
                            <Field
                              name="post_codeDoc"
                              innerRef={post_codeDocRef}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                                errors.post_codeDoc && touched.post_codeDoc
                                  ? "border-red-500"
                                  : ""
                              }`}
                              maxLength={5}
                              onBlur={(e: { target: { value: string } }) =>
                                handleInputZipCodeDoc(e.target.value)
                              }
                            />
                            <ErrorMessage
                              name="post_codeDoc"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>

                          <div>
                            <label className="block text-md font-medium text-gray-700">
                              จังหวัด
                              <span className="text-red-500 ml-1">*</span>
                            </label>
                            <Field
                              as="select"
                              name="provinceDoc"
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                                errors.provinceDoc && touched.provinceDoc
                                  ? "border-red-500"
                                  : ""
                              }`}
                              innerRef={provinceDocRef}
                              onChange={(e: { target: { value: any } }) => {
                                setFieldValue("provinceDoc", e.target.value);
                                handleSelectProvincesDoc(e.target.value);
                              }}
                            >
                              <option selected>{"เลือกจังหวัด"}</option>
                              {provincesDoc.map((provinces) => (
                                <option key={provinces.id} value={provinces.id}>
                                  {provinces.name_th}
                                </option>
                              ))}
                              <ErrorMessage
                                name="provinceDoc"
                                component="div"
                                className="text-red-500 text-sm"
                              />
                            </Field>
                          </div>
                          <div>
                            <label className="block text-md font-medium text-gray-700">
                              อำเภอ
                              <span className="text-red-500 ml-1">*</span>
                            </label>
                            <Field
                              as="select"
                              name="amphurenDoc"
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                                errors.amphurenDoc && touched.amphurenDoc
                                  ? "border-red-500"
                                  : ""
                              }`}
                              innerRef={amphurenDocRef}
                              onChange={(e: { target: { value: any } }) => {
                                setFieldValue("amphurenDoc", e.target.value);
                                handleSelectDistrictDoc(e.target.value);
                              }}
                            >
                              <option selected>
                                {"เลือกอำเภอ (เลือกจังหวัดก่อน)"}
                              </option>
                              {amphuresDoc.map((amphure) => (
                                <option key={amphure.id} value={amphure.id}>
                                  {amphure.name_th}
                                </option>
                              ))}
                              <ErrorMessage
                                name="amphurenDoc"
                                component="div"
                                className="text-red-500 text-sm"
                              />
                            </Field>
                          </div>
                          <div>
                            <label className="block text-md font-medium text-gray-700">
                              ตำบล
                              <span className="text-red-500 ml-1">*</span>
                            </label>
                            <Field
                              as="select"
                              name="tambonDoc"
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                                errors.tambonDoc && touched.tambonDoc
                                  ? "border-red-500"
                                  : ""
                              }`}
                              innerRef={tambonDocRef}
                            >
                              <option selected>
                                {"เลือกตำบล (เลือกอำเภอก่อน)"}
                              </option>
                              {tambonsDoc.map((tambon) => (
                                <option key={tambon.id} value={tambon.id}>
                                  {tambon.name_th}
                                </option>
                              ))}
                              <ErrorMessage
                                name="tambonDoc"
                                component="div"
                                className="text-red-500 text-sm"
                              />
                            </Field>
                          </div>

                          {/* Address */}
                          <div className="items-center m-1">
                            <label className="block text-md font-medium text-gray-700">
                              ที่อยู่
                              <span className="text-red-500 ml-1">*</span>
                            </label>
                            <Field
                              name="addressDoc"
                              as="textarea"
                              innerRef={addressDocRef}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                                errors.addressDoc && touched.addressDoc
                                  ? "border-red-500"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="addressDoc"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>

                          <div className="items-center m-1">
                            <label className="block text-md font-medium text-gray-700">
                              เบอร์โทรศัพท์เพื่อติดต่อ
                              <span className="text-red-500 ml-1">*</span>
                            </label>
                            <Field
                              name="signer_phoneDoc"
                              innerRef={signer_phoneDocRef}
                              className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-md ${
                                errors.signer_phoneDoc &&
                                touched.signer_phoneDoc
                                  ? "border-red-500"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="signer_phoneDoc"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                        </>
                      )}
                      {/* <div className="col-span-2 flex m-auto">
                        <button
                          type="submit"
                          className="mt-6 mr-4 w-20 bg-[#13C296] text-white py-2 px-4 rounded-md hover:bg-[#11AE87]"
                        >
                          บันทึก
                        </button>
                        <button
                          type="reset"
                          className="mt-6 w-20 bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700"
                        >
                          ล้าง
                        </button>
                      </div> */}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditCustomerForm;
