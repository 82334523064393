import apiClient from "./apiClient";

const getPermissions = () => {
  return apiClient
    .get(`permissions`, {})
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const permissionServices = {
  getPermissions,
};
export default permissionServices;
