import { Link } from "react-router-dom";
import LayoutWeb from "../../components/LayoutWeb";
import RouteTable from "../../components/table/RouteTable";
import "../../index.css";

const Route = () => {
  return (
    <>
      <LayoutWeb>
        <div className="p-1">
          <div className="flex justify-between">
            <div>
              <h1 className="text-[24px] text-[#3B82F6] font-bold">
                รายการเส้นทางเดินรถ
              </h1>
            </div>
            <div>
              <Link to="/setting/create-routes">
                <button className="bg-[#13C296] hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg">
                  สร้างเส้นทาง
                </button>
              </Link>
            </div>
          </div>
          <RouteTable />
        </div>
      </LayoutWeb>
    </>
  );
};

export default Route;
