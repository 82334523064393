import apiClient from "./apiClient";
import { getStorage } from "../utils/storage";
import { GetProductCategoryFilter } from "../types/productCategory";
import { GetProductFilter } from "../types/product";

const createProductCategory = async (payload: any) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.post(`product-categories`, payload, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

const getAllProductCategories = () => {
  const token = getStorage("token");
  return apiClient
    .get(`product-categories`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });
}

const getProductCategories = (payload: GetProductCategoryFilter) => {
  let filters = "?";
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      if (
        payload[key as keyof GetProductCategoryFilter] !== undefined &&
        payload[key as keyof GetProductCategoryFilter] !== null &&
        payload[key as keyof GetProductCategoryFilter] !== ""
      ) {
        filters += `${encodeURIComponent(key)}=${encodeURIComponent(
          payload[key as keyof GetProductCategoryFilter]
        )}&`;
      }
    }
  }
  filters = filters.slice(0, -1);
  const token = getStorage("token");
  return apiClient
    .get(`product-categories${filters}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const getProductCategoryById = async (id: string) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.get(`product-categories/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    });
    return response;
  } catch (e) {
    console.error("error", e);
    return e;
  }
};

const updateProductCategoryById = async (id: string, payload: any) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.put(`product-categories/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    });
    return response;
  } catch (e) {
    return e;
  }
};

const deleteProductCategoryById = async (id: string) => {
    try {
      const token = getStorage("token");
      const response = await apiClient.delete(`product-categories/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      });
      return response
    } catch (e) {
      return e;
    }
};

  const createProduct = async (payload: any) => {
    try {
      const token = getStorage("token");
      const response = await apiClient.post(`products`, payload, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      });
      return response;
    } catch (e) {
      return e;
    }
  };
  
  const getProducts = (payload: GetProductFilter) => {
    let filters = "?";
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (
          payload[key as keyof GetProductFilter] !== undefined &&
          payload[key as keyof GetProductFilter] !== null &&
          payload[key as keyof GetProductFilter] !== ""
        ) {
          filters += `${encodeURIComponent(key)}=${encodeURIComponent(
            payload[key as keyof GetProductFilter]
          )}&`;
        }
      }
    }
    filters = filters.slice(0, -1);
    const token = getStorage("token");
    return apiClient
      .get(`products${filters}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      })
      .then((response) => {
        return response;
      })
      .catch((e) => {
        return e.response.data;
      });
  };
  
  const getProductById = async (id: string) => {
    try {
      const token = getStorage("token");
      const response = await apiClient.get(`products/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      });
      return response;
    } catch (e) {
      console.error("error", e);
      return e;
    }
  };
  
  const updateProductById = async (id: string, payload: any) => {
    try {
      const token = getStorage("token");
      const response = await apiClient.put(`products/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      });
      return response;
    } catch (e) {
      return e;
    }
  };
  
  const deleteProductById = async (id: string) => {
      try {
        const token = getStorage("token");
        const response = await apiClient.delete(`products/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Set the token dynamically here
          },
        });
        return response
      } catch (e) {
        return e;
      }
    };

const settingProductServices = {
    createProductCategory,
    getProductCategories,
    getAllProductCategories,
    getProductCategoryById,
    updateProductCategoryById,
    deleteProductCategoryById,
    createProduct,
    getProducts,
    getProductById,
    updateProductById,
    deleteProductById,
};
export default settingProductServices;
