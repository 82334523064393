import apiClient from "./apiClient";
import { GetUserFilter } from "../types/user";
import { getStorage } from "../utils/storage";
import { isNamedImports } from "typescript";
import { CustomerSchedule, CustomerScheduleResponse } from "../types/schedule";

const getSchedules = (payload: GetUserFilter) => {
  let filters = "?";
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      if (
        payload[key as keyof GetUserFilter] !== undefined &&
        payload[key as keyof GetUserFilter] !== null &&
        payload[key as keyof GetUserFilter] !== ""
      ) {
        filters += `${encodeURIComponent(key)}=${encodeURIComponent(
          payload[key as keyof GetUserFilter]
        )}&`;
      }
    }
  }
  filters = filters.slice(0, -1);
  const token = getStorage("token");
  return apiClient
    .get(`customer-schedules/route/schedule${filters}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });
};
const getSchedulesByCustomerId = async (id: number) => {
  const token = getStorage("token");
  return apiClient
    .get(`customer-schedules?customer_id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const updateScheduleOrder = async (payload: any) => {
  try {
    const token = getStorage("token");
    const response = await apiClient.post(
      `customer-schedules/route/order`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Set the token dynamically here
        },
      }
    );
    return response;
  } catch (e) {
    return e;
  }
};

const createCustomerSchedule = async (
  payload: CustomerSchedule
): Promise<CustomerScheduleResponse | null> => {
  try {
    const token = getStorage("token");
    const response = await apiClient.post<CustomerScheduleResponse>(
      `customer-schedules`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Ensure you return the data property of the response
  } catch (e: any) {
    console.error("Error in createCustomerSchedule:", e.message || e);
    return null; // Return null or handle the error as per your use case
  }
};

const deleteScheduleByID = async (id: number) => {
  const token = getStorage("token");
  return apiClient
    .delete(`customer-schedules/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the token dynamically here
      },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e.response.data;
    });
};

const apiServices = {
  getSchedules,
  updateScheduleOrder,
  createCustomerSchedule,
  getSchedulesByCustomerId,
  deleteScheduleByID,
};
export default apiServices;
