// components/TableComponent.tsx
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import "react-dropdown/style.css";
import "./Index.css";
import NewCustomerService from "../../services/newCustomerApi";
import { GetNewCustomerFilter } from "../../types/customer";
import searchImage from "../../assets/icon/search.png";
import closeButton from "../../assets/icon/Close.svg";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;
interface TableProps {
  handleAdd: (customerID: number) => void;
  togglePopup: () => void;
}

const CustomerPopUpTable: React.FC<TableProps> = ({
  handleAdd,
  togglePopup,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  const fetchNewCustomer = async (payload: GetNewCustomerFilter) => {
    setLoading(true);
    NewCustomerService.getNewCustomers(payload).then(
      async (res) => {
        if (res.status === 200) {
          setData(res.data.data);
        }
        if (res.status === 400) {
        }
        setLoading(false);
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  const handleDoubleClickCustomer = (customerID: number) => {
    handleAdd(customerID);
  };

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <Spinner />
      <div className="flex justify-center font-bold">Loader...</div>
    </div>
  );

  const handleInputSearch = async (searchText: string) => {
    setSearch(searchText);
    let payload: GetNewCustomerFilter = {
      perPage: 0,
      page: 1,
      startDate: "",
      endDate: "",
      search: searchText,
      isNewCustomer: false,
    };
    await fetchNewCustomer(payload);
  };

  // useEffect(() => {
  //   let payload: GetNewCustomerFilter = {
  //   perPage: 0,
  //   page: 1,
  //   startDate: "",
  //   endDate: "",
  //   search: search,
  //   isNewCustomer: false,
  //   };
  //   fetchNewCustomer(payload); // fetch page 1 of users
  //   setLoading(false)
  // }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg w-[730px] relative">
        <div className="flex justify-between p-2">
          <div className="flex items-center">
            <div className="text-18 font-normal">
              เลือกลูกค้าที่จะเข้าเก็บฉุกเฉิน
            </div>
          </div>
          <div>
            <div className="flex rounded-md">
              <button
                onClick={togglePopup}
                className="bg-white rounded-r-md w-10 flex justify-center items-center"
              >
                <img src={closeButton} alt="" className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>

        <div className="flex rounded-md mt-3">
          <input
            placeholder="ค้นหา"
            className="border-l-2 border-t-2 border-b-2 pl-4 py-1 rounded-l-xl w-full"
            onBlur={(e) => handleInputSearch(e.target.value)}
          />
          <button className="bg-grey-light-1 rounded-r-md w-10 flex justify-center items-center">
            <img src={searchImage} alt="" className="w-5 h-5" />
          </button>
        </div>
        <div className="mt-4 max-h-48 overflow-y-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-grey-light-1">
                <th className="px-4 py-2 border-b">รหัสลูกค้า</th>
                <th className="px-4 py-2 border-b">ชื่อลูกค้า</th>
                <th className="px-4 py-2 border-b">ชื่อสถานพยาบาล</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td className="px-4 py-2 border-b text-center">
                    ไม่พบข้อมูล
                  </td>
                  <td className="px-4 py-2 border-b">ไม่พบข้อมูล</td>
                  <td className="px-4 py-2 border-b">ไม่พบข้อมูล</td>
                </tr>
              ) : (
                data?.map((field, index) => (
                  <React.Fragment key={index}>
                    <tr
                      onDoubleClick={() =>
                        handleDoubleClickCustomer(field["id"])
                      }
                    >
                      <td className="px-4 py-2 border-b text-center">
                        {field["customer_code"]}
                      </td>
                      <td className="px-4 py-2 border-b">
                        {field["addresses"][0]["company_name"]}
                      </td>
                      <td className="px-4 py-2 border-b">
                        {field["business_name"]}
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomerPopUpTable;
