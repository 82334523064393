import React, { useEffect, useRef, useState } from "react";
import {
  CustomerData,
  FormAddOrEditCustomerProduct,
} from "../../types/customer";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import {
  GetCustomerProductFilter,
  GetProductFilter,
  ProductPriceData,
} from "../../types/product";
import { ReactComponent as Iconinfo } from "../../assets/icon/edit_info.svg";
import { ReactComponent as Trash } from "../../assets/icon/trash.svg";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CustomerDetail from "../widget/haederCustomerForm";
import customerProductServices from "../../services/customerProductApi";
import DataTable, { TableStyles } from "react-data-table-component";
import DropdownTable from "../dropdown/DropDownTable";
import newCustomerRegisterServices from "../../services/newCustomerApi";
import settingProductServices from "../../services/settingProduct";
import Tooltip from "../tooltip/Tooltip";
import { toast, ToastContainer } from "react-toastify";
import {
  GetProductCategoryFilter,
  ProductCategoryData,
} from "../../types/productCategory";

const customStyles: TableStyles = {
  headCells: {
    style: {
      padding: "0px",
      textAlign: "center",
      backgroundColor: "#3758F9",
      color: "#FFF",
      "&:first-child": {
        padding: "0px",
        textAlign: "end",
        borderRadius: "15px",
      },
      "&:last-child": {
        padding: "0px",
        textAlign: "center",
        borderRadius: "15px",
      },
    },
  },
  headRow: {
    style: {
      backgroundColor: "#3758F9",
      color: "#FFFFFF",
      fontSize: "16px",
      fontWeight: "bold",
      position: "sticky",
      top: "0", // Stick to the top
      zIndex: 100, // Ensure it stays above other elements
    },
  },
  cells: {
    style: {
      padding: "12px",
      textAlign: "center",
      "&:first-child": {
        padding: "0px",
        textAlign: "center",
      },
      "&:last-child": {
        padding: "0px",
        textAlign: "center",
      },
    },
  },
  rows: {
    style: {
      minHeight: "50px", // Row height
      "&:hover": {
        backgroundColor: "#f1f5f9", // Hover color
      },
      fontSize: "16px",
    },
  },
  pagination: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: "#E2E8F0",
      padding: "10px",
    },
  },
};

const CustomerProductPriceForm = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const [customer, setCustomer] = useState<CustomerData | null>(null);
  const [priceData, setPriceData] = useState<any[]>([]); // Table data
  const navigate = useNavigate();
  const [productCategories, setProductCategories] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]); // Stores all products
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [productPrice, setProductPrice] = useState<any>([]);
  const [data, setData] = useState([]);
  const [productDetail, setProductDetail] = useState<string>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState("");
  const [productCategorys, setProductCategorys] =
    useState<ProductCategoryData[]>();

  const [loading, setLoading] = useState(true);

  const setToastId = "toast-id";

  const getNewCustomerById = async (id: string) => {
    try {
      const responseCustomer =
        await newCustomerRegisterServices.getNewCustomerByid(id);
      return responseCustomer.data;
    } catch (error) {
      console.error("Error in getNewCustomerById:", error);
      throw error;
    }
  };
  const setNewCustomerById = async () => {
    try {
      if (id) {
        const newCustomer = await getNewCustomerById(id);
        setCustomer(newCustomer);
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  useEffect(() => {
    setNewCustomerById();
  }, [id]);

  const fetchProductCategories = async () => {
    let payload: GetProductCategoryFilter = {
      perPage: 0,
      page: 0,
      search: "",
    };

    settingProductServices.getProductCategories(payload).then(
      async (res) => {
        if (res.status === 200) {
          let result: ProductCategoryData[] = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let item: ProductCategoryData = {
              id: res.data.data[i].id,
              name: res.data.data[i].name,
            };
            result.push(item);
          }
          console.log("getProductCategories", result);

          await setProductCategorys(result);
        }
        if (res.status === 400) {
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };
  useEffect(() => {
    fetchProductCategories();
  }, []);

  const fetchProductData = async (payload: GetProductFilter) => {
    try {
      const response = await settingProductServices.getProducts(payload);
      console.log("fetchProductData", response.data.data);

      if (response.status === 200) {
        let data = response.data.data;
        data = data.filter((item: any) => item.deleted_at === null);
        setProducts(data);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  const getProductUserOwner = async (payload: GetCustomerProductFilter) => {
    setLoading(true);
    customerProductServices.getCustomerProducts(payload).then(
      async (res) => {
        console.log("getProductUserOwner", res);

        if (res.status === 200) {
          let result: any = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.no = i + 1 + (res.data.page - 1) * res.data.perPage;
            result.push(item);
          }
          await setPage(res.data.page);
          await setData(result);
          await setPerPage(res.data.perPage);
          await setTotalRows(res.data.total);
        }
        if (res.status === 400) {
        }
        setLoading(false);
      },
      (error) => {
        console.error(error.message);
      }
    );
  };

  useEffect(() => {
    let payload: GetCustomerProductFilter = {
      perPage: perPage,
      page: page,
      search: search,
      product_category_id: 0,
      is_include_delete: false,
      customer_id: Number(id),
    };
    getProductUserOwner(payload); // fetch page 1 of users
  }, [perPage, page, productCategorys, search, id]);

  const handleCategoryChange = (categoryId: number) => {
    let payload: GetProductFilter = {
      perPage: 9999,
      page: 1,
      search: "",
      product_category_id: categoryId,
      is_include_delete: false,
    };
    fetchProductData(payload);
  };

  const handleProductChange = async (
    productId: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => Promise<void | FormikErrors<{
      productCategory: string;
      productId: string;
      productDetails: string;
      pricePerUnit: string;
      expirationDate: string;
    }>>
  ) => {
    console.log(handleProductChange);

    const productsPricelegth: any = products.filter((p) => p.id === productId);
    setProductDetail(productsPricelegth.description);
    await setProductPrice(productsPricelegth[0].product_prices);
    const selectedProduct: any = products.find((p) => p.id === productId);
    if (selectedProduct) {
      const unitPerPrice: any = selectedProduct.product_prices.find(
        (p: { is_unit_per_price: boolean }) => p.is_unit_per_price === true
      );
      setFieldValue(
        "productCategory",
        productsPricelegth[0].product_category.id
      );
      setFieldValue("productId", productId);
      setFieldValue("productDetails", selectedProduct.description || ""); // Set the description
      setProductPrice(selectedProduct.product_prices); // Update product prices
      setFieldValue("unitPerPrice", unitPerPrice.price);
    }
  };

  const initialValues = {
    productCategory: "",
    productId: "",
    productDetails: "",
    expirationDate: "",
    ...Object.fromEntries(
      productPrice.map((p: { id: number; price: number }) => [
        `price_${p.id}`,
        p.price,
      ])
    ),
    ...Object.fromEntries(
      productPrice.map((p: { id: number; max_unit: number }) => [
        `max_unit${p.id}`,
        p.max_unit,
      ])
    ),
    unitPerPrice: "",
  };

  const validationSchema = Yup.object().shape({
    productCategory: Yup.number().required("กรุณาเลือกหมวดสินค้า"),
    productId: Yup.string().required("กรุณาเลือกสินค้า"),
    expirationDate: Yup.date().required("กรุณาระบุวันที่หมดอายุ"),
    unitPerPrice: Yup.number()
      .min(1, "กรุณาระบุราคาเป็นจำนวนเต็มบวก")
      .required("กรุณาระบุราคาขาย/หน่วย"),
  });

  const minUnit = (index: number, data: ProductPriceData[]) => {
    if (index === 0) {
      return 0;
    }
    const prevMaxUnit = data[index - 1].max_unit;
    if (prevMaxUnit !== undefined) {
      return prevMaxUnit + 1;
    }
  };

  const transformFormData = (formData: any, customerId: number) => {
    // Extract top-level properties
    const { productCategory, productId, productDetails, ...rest } = formData;

    // Prepare customer_product_prices array
    const customerProductPrices: { price: number; max_unit: number | null }[] =
      [];

    for (const key in rest) {
      if (key.startsWith("price_")) {
        const id = key.split("_")[1]; // Extract the ID after "price_"
        const maxUnitKey = `max_unit${id}`;
        customerProductPrices.push({
          price: rest[key],
          max_unit: rest[maxUnitKey] || null, // Use null if max_unit is undefined
        });
      }
    }
    return {
      product_category_id: Number(productCategory),
      product_id: Number(productId),
      customer_id: customerId,
      description: productDetails,
      customer_product_prices: customerProductPrices,
    };
  };

  const handleSubmit = async (values: FormAddOrEditCustomerProduct) => {
    console.log(values);
    const transformedData = await transformFormData(values, Number(id));
    const response = await customerProductServices.createCustomerProduct(
      transformedData
    );
    console.log(response);

    if (response?.status === 200) {
      toast.success("เพิ่มสินค้า สำเร็จ!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: setToastId,
      });
      setPerPage(perPage + 1);
    } else if (response?.status === 404) {
      toast.error("เพิ่มสินค้า ไม่สำเร็จ (การเชื่อมต่อ server มีปัญหา)", {
        hideProgressBar: false,
        closeOnClick: true,
      });
    } else if (response?.status === 500) {
      toast.error("เพิ่มสินค้า ไม่สำเร็จ (สินค้าซ้ำ)", {
        hideProgressBar: false,
        closeOnClick: true,
      });
    } else {
      console.error("Failed to create product. Please try again.");
      // Show an error notification or handle gracefully
    }
  };

  const handleDelete = async (id: number) => {
    console.log(id);

    const response = await customerProductServices.deleteCustomerProductById(
      id
    );
    console.log(response);

    if (response?.status === 200) {
      toast.success("ลบสินค้า สำเร็จ!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: setToastId,
      });
      setPerPage(perPage + 1);
    } else {
      toast.error("ลบสินค้า ไม่สำเร็จ", {
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const columns = [
    {
      name: "ลำดับ",
      selector: (row: any) => row.no,
      width: "80px",
      sortable: true,
    },
    {
      name: "หมวดสินค้า",
      selector: (row: any) => row.product_category.name,
      width: "30%",
      sortable: true,
    },

    {
      name: "สินค้าหรือบริการ",
      selector: (row: any) => row.product?.name,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-left items-center truncate-text text-[15px]">
          <Tooltip
            body={
              <div className="bg-[#264CA2] p-4 rounded-lg mx-auto shadow-lg text-[10px] max-w-[400px]">
                <div className="text-left font-normal text-white text-wrap line-clamp-4">
                  {row.product?.description}
                </div>
              </div>
            }
          />
          {row.product?.name}
        </div>
      ),
      width: "30%",
      sortable: true,
    },
    {
      name: "ราคาต่อหน่วย",
      selector: (row: any) => row.customer_product_prices[0]?.price,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-end items-center truncate-text text-[15px] gap-1">
          {row.customer_product_prices[0]?.price}
          {row.customer_product_prices.length > 1 ? (
            <Tooltip
              body={
                <div className="bg-[#264CA2] p-4 rounded-lg mx-auto shadow-lg text-[10px]">
                  <div className="text-left font-normal text-white">
                    ค่าบริการเก็บขนและกำจัดมูลฝอยติดเชื้อรายเดือน
                  </div>
                  <div className="">
                    {row.customer_product_prices.map((item: any, index: any) =>
                      index !== row.customer_product_prices.length - 1 ? (
                        <React.Fragment key={index}>
                          <div key={index} className="flex gap-1 text-white">
                            <span>
                              {minUnit(index, row.customer_product_prices)}
                            </span>
                            <span>-</span>
                            <span>{item.max_unit}</span>
                            <span>กิโลกรัม</span>
                            <span>{item.price}</span>
                            <span>บาท</span>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment key={index}>
                          <div
                            key={row.customer_product_prices.length - 1}
                            className="flex text-white gap-1"
                          >
                            <span>ส่วนเกินคิดกิโลกรัมละ</span>
                            <span>{item.price}</span>
                            <span>บาท</span>
                          </div>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </div>
              }
            />
          ) : (
            <div className="w-5"></div>
          )}
        </div>
      ),
      width: "12%",
      sortable: true,
    },
    {
      name: "รหัสสินค้า",
      selector: (row: any) => row.code,
      cell: (row: any) => (
        <div className="w-[100%] flex justify-center items-center truncate-text text-[15px]">
          {row.code}
        </div>
      ),
      sortable: true,
      width: "10%",
    },
    // {
    //   name: "วันที่หมดอายุของราคา",
    //   selector: (row: any) => row.expirationDate,
    // },
    {
      name: "",
      cell: (row: any) => (
        <DropdownTable
          list={[
            {
              svg: <Trash className="w-6 h-6 mx-2 opacity-60" />,
              path: "",
              hoverClass: "hover:bg-red-400 hover:rounded-md hover:text-white",
              label: "ลบ",
              method: () => {
                handleDelete(row.id);
              }, // Example method
            },
          ]}
        />
      ),
      width: "5%",
    },
  ];

  const productCategoryRef = useRef<HTMLInputElement>(null);
  const productIdRef = useRef<HTMLInputElement>(null);
  const expirationDateRef = useRef<HTMLInputElement>(null);
  const focusFirstError = (errors: FormikErrors<any>) => {
    if (errors.productCategory && productCategoryRef.current) {
      productCategoryRef.current.focus();
    } else if (errors.productId && productIdRef.current) {
      productIdRef.current.focus();
    } else if (errors.expirationDate && expirationDateRef.current) {
      expirationDateRef.current.focus();
    }
  };

  return (
    <div className="mx-auto">
      <CustomerDetail
        customer={customer}
        isReadonly={true}
        onBack={() => navigate(-1)}
      />
      <div className="p-4 card rounded-2xl shadow-md my-2">
        <h2 className="text-lg font-semibold mb-4">จัดการข้อมูลราคาลูกค้า</h2>

        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
          }}
        >
          {({ setFieldValue, values, errors, touched, handleSubmit }) => (
            <Form
              className="space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                focusFirstError(errors); // Focus on the first invalid input
                handleSubmit(e);
              }}
            >
              <div className="">
                <div className="flex justify-between">
                  <div className="my-auto">
                    <label className="block text-md font-medium text-gray-700">
                      ราคาสินค้าหรือบริการ :
                    </label>
                  </div>
                  <div className="my-auto">
                    <button
                      type="submit"
                      className="bg-[#13C296] text-[#FFFFFF] p-2 rounded w-28 bottom-0"
                    >
                      เพิ่ม
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-3 grid grid-cols-10 gap-2">
                    <div className="col-span-2">
                      <label className="block mb-1">หมวดสินค้า</label>
                      <Field
                        as="select"
                        name="productCategory"
                        innerRef={productCategoryRef}
                        className="w-full border rounded px-2 py-1"
                        onChange={(e: { target: { value: string } }) => {
                          setFieldValue("productCategory", e.target.value);
                          handleCategoryChange(Number(e.target.value));
                        }}
                      >
                        <option value="">เลือกหมวดสินค้า</option>
                        {productCategorys?.map((category) => (
                          <option
                            key={`category-${category.id}`}
                            value={category.id}
                          >
                            {category.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="productCategory"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div className="col-span-6">
                      <label className="block mb-1">สินค้าหรือบริการ</label>
                      <Field
                        as="select"
                        name="productId"
                        className="w-full border rounded px-2 py-1"
                        innerRef={productIdRef}
                        onChange={(e: { target: { value: string } }) => {
                          setFieldValue("productId", e.target.value);
                          const selectedValue = Number(e.target.value);
                          handleProductChange(selectedValue, setFieldValue);
                        }}
                      >
                        <option value="">เลือกสินค้า</option>
                        {products.map((product) => (
                          <option
                            key={`product-${product.id}`}
                            value={product.id}
                          >
                            {product.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="productId"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="block mb-1">วันที่หมดอายุของราคา</label>
                      <Field
                        type="date"
                        name="expirationDate"
                        className="w-full border rounded px-2 py-1"
                        innerRef={expirationDateRef}
                      />
                      <ErrorMessage
                        name="expirationDate"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>
                  <div className=" col-span-3">
                    <label className="block mb-1">รายละเอียดสินค้า</label>
                    <Field
                      as="textarea"
                      name="productDetails"
                      placeholder="รายละเอียดสินค้า"
                      className="w-full border rounded px-2 py-1"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(`productDetails`, e.target.value);
                      }}
                      value={values.productDetails} // This ensures the value comes from Formik's state
                    />
                  </div>

                  <div className="col-span-3 p-2 pt-4 mb-4">
                    <div className="my-auto">
                      <label className="block text-md font-medium text-gray-700">
                      ราคาขายตามเงื่อนไขช่วงราคา :
                      </label>
                    </div>
                    {productPrice.map((price: any, index: number) => (
                      <div className="flex justify-between my-2" key={price.id}>
                        {index === productPrice.length - 1 &&
                        price.is_unit_per_price ? (
                          // Special rendering for the last item
                          <div className="w-[50%] mx-2 pr-4">
                            <label className="block mb-1">
                              ราคาขาย/หน่วย(กก):
                            </label>
                            <Field
                              type="number"
                              name={`unitPerPrice`}
                              className="w-full border rounded px-2 py-1 h-[34px]"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setFieldValue(`unitPerPrice`, e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name={`unitPerPrice`}
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                        ) : (
                          // Default rendering for other items
                          <>
                            <div className="w-full mx-2">
                              <label className="block mb-1">
                                จำนวนน้ำหนักสูงสุด(กก.):
                              </label>
                              <Field
                                disabled
                                name={`max_unit${price.id}`}
                                className="w-full border rounded px-2 py-1 h-[34px]"
                                value={price.max_unit || ""}
                              />
                            </div>
                            <div className="w-full mx-2">
                              <label className="block mb-1">ราคาขาย:</label>
                              <Field
                                type="number"
                                name={`price_${price.id}`}
                                className="w-full border rounded px-2 py-1 h-[34px]"
                                validate={(value: number) => {
                                  if (index > 0) {
                                    const previousPriceFieldName = `price_${
                                      productPrice[index - 1].id
                                    }`;
                                    const previousPrice = (values as any)[
                                      previousPriceFieldName
                                    ];
                                    if (value <= previousPrice) {
                                      return "ราคาต้องมากกว่าราคาก่อนหน้า";
                                    }
                                  }
                                  return undefined;
                                }}
                              />
                              <ErrorMessage
                                name={`price_${price.id}`}
                                component="div"
                                className="text-red-500 text-sm"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
          customStyles={customStyles}
          highlightOnHover
          pagination
          // paginationServer
          noDataComponent={<div className="p-6">ไม่พบข้อมูล</div>}
        />
      </div>
    </div>
  );
};

export default CustomerProductPriceForm;
