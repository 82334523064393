import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import settingProductServices from "../../services/settingProduct";
import LayoutWeb from "../../components/LayoutWeb";
import LoaderPage from "../../components/LoaderPage";

import { ProductCategoryData, ProductCategoryValidate } from "../../types/productCategory";
import Swal from "sweetalert2";
import ProductCategoryForm from "../../components/form/ProductCategoryForm";

const ProductCategoryCreate: React.FC = () => {
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(false);
  const [productCategory, setProductCategory] = useState<ProductCategoryData>({
    name: "",
  });
  const [productCategoryValidate, setProductCategoryValidate] = useState<ProductCategoryValidate>({});

  const handleSubmit = async (data: ProductCategoryData) => {
    setLoadingPage(true);
    setProductCategoryValidate({});
    
    let payload: ProductCategoryData = {
      name: data.name,
    };
    
    await settingProductServices.createProductCategory(payload).then(
      async (res: any) => {
        console.log(res.response)
        if (res.status === 200) {
          navigate(`/setting/product-category`, { replace: true });
        } else if (res.status === 422 && res.response?.data?.message === "InvalidInput") {
          res.response.data.errors.map((element: any) => {
            let message: string = element.message
            if (message === "duplicate") {
              message = "ชื่อหมวดสินค้าซ้ำ"
            }
            setProductCategoryValidate((prevFields) => ({
              ...prevFields,
              [element.field]: message
            }));        
          });   
          
          Object.entries(data).map(([key, value]) => (
            setProductCategory((prevFields) => ({
              ...prevFields,
              [key]: value
            }))
          ));  
          
          setLoadingPage(false);
        } else {
          setLoadingPage(false);
          Swal.fire({
            position: "top",
            title: "บันทึกข้อมูลไม่สำเร็จ",
            html: "Something went wrong",
            icon: "error",
            confirmButtonColor: "#13C296",
            confirmButtonText: "ปิด",
            footer: "โปรดถ่ายภาพเพื่อความสะดวกในการช่วยเหลือ",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  };

  return (
    <>
      <LayoutWeb>
        {loadingPage ? (
          <LoaderPage />
        ) : (
          <>
            <ProductCategoryForm
              data={productCategory}
              handleSubmit={handleSubmit} 
              validation={productCategoryValidate}
            />
          </>
        )}
      </LayoutWeb>
    </>
  );
};

export default ProductCategoryCreate;
