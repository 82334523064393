import React, { useState, useEffect } from "react";
import "./ThaiMonthPicker.css";
import {
  showDatetimeFormatTh,
  showDatetimeFormat,
  getDate,
} from "../../utils/date";
import { formToJSON } from "axios";

interface MonthPickerProps {
  value: string;
  onMonthSelect: (month: string) => void;
}

const MonthPicker: React.FC<MonthPickerProps> = ({ value, onMonthSelect }) => {
  const thaiMonths = [
    { short: "ม.ค.", full: "มกราคม" },
    { short: "ก.พ.", full: "กุมภาพันธ์" },
    { short: "มี.ค.", full: "มีนาคม" },
    { short: "เม.ย.", full: "เมษายน" },
    { short: "พ.ค.", full: "พฤษภาคม" },
    { short: "มิ.ย.", full: "มิถุนายน" },
    { short: "ก.ค.", full: "กรกฎาคม" },
    { short: "ส.ค.", full: "สิงหาคม" },
    { short: "ก.ย.", full: "กันยายน" },
    { short: "ต.ค.", full: "ตุลาคม" },
    { short: "พ.ย.", full: "พฤศจิกายน" },
    { short: "ธ.ค.", full: "ธันวาคม" },
  ];

  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );

  console.log("selectedMonth:", selectedMonth);

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleMonthClick = (month: string) => {
    const monthIndex = thaiMonths.findIndex((m) => m.short === month);
    const formattedMonth =
      monthIndex >= 0 ? String(monthIndex + 1).padStart(2, "0") : "00"; // แปลงเดือนเป็นเลขสองหลัก

    // สร้างวันที่ในรูปแบบ YYYY-MM
    const formattedDate = `${currentYear}-${formattedMonth}`;

    setSelectedMonth(`${month} ${currentYear + 543}`); // เก็บเดือนที่เลือก
    onMonthSelect(formattedDate); // เรียกใช้ callback ด้วยวันที่ที่แปลงแล้ว
    setShowDropdown(false); // ซ่อน dropdown หลังจากเลือกเดือน
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const clearSelection = () => {
    setSelectedMonth("");
  };

  const selectCurrentMonth = () => {
    const currentMonthIndex = new Date().getMonth();
    handleMonthClick(thaiMonths[currentMonthIndex].short);
  };

  useEffect(() => {
    console.log("value:", value);
    if (value !== "" && selectedMonth !== value) {
      const dateValue = new Date(value + "-01");
      const dateValueString =
        thaiMonths[dateValue.getMonth()].short +
        " " +
        (dateValue.getFullYear() + 543);
      console.log("dateValueString", dateValueString);
      setSelectedMonth(dateValueString);
    }
  }, [value]);

  return (
    <div
      className="month-picker"
      onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
    >
      <div className="input-wrapper">
        <input
          type="text"
          className="date-input text-[#637381]"
          readOnly
          value={selectedMonth}
          onClick={toggleDropdown}
          placeholder="เลือกเดือน"
        />
        <div className="calendar-icon" onClick={toggleDropdown}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#637381"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="icon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 7V3m8 4V3m-6 0h6M4 7h16a2 2 0 012 2v11a2 2 0 01-2 2H4a2 2 0 01-2-2V9a2 2 0 012-2z"
            />
          </svg>
        </div>
      </div>
      {showDropdown && (
        <div className="month-dropdown">
          <div className="year-control">
            <button
              className="nav-button"
              onClick={() => setCurrentYear(currentYear - 1)}
            >
              &lt;
            </button>
            <span>{currentYear + 543}</span>
            <button
              className="nav-button"
              onClick={() => setCurrentYear(currentYear + 1)}
            >
              &gt;
            </button>
          </div>
          <div className="months-grid">
            {thaiMonths.map((month, index) => (
              <div
                key={index}
                className={`month-item ${
                  selectedMonth.includes(month.short) ? "selected" : ""
                }`}
                onClick={() => handleMonthClick(month.short)}
              >
                {month.short}
              </div>
            ))}
          </div>
          <div className="controls">
            <button onClick={clearSelection} className="clear-button">
              Clear
            </button>
            <button onClick={selectCurrentMonth} className="current-button">
              This month
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthPicker;
