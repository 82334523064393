import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const SettingDocs = () => {
  // Document options for the dropdown
  const documentOptions = [
    'เอกสารทั้งหมด',
    'เอกสารซื้อและขายทั้งหมดในระบบ',
    'ในเสนอราคา',
    'ใบวางบิล/ใบแจ้งหนี้',
    'ในเสร็จรับเงิน/ใบกำกับภาษี',
    'ในใบสั่งซื้อ',
    'ใบรับสินค้า',
    'ในเตรียมจ่าย/ใบสำคัญจ่าย',
  ];

  // Formik setup
  const formik = useFormik({
    initialValues: {
      electronicSignature: null,
      documentLine: null,
      documentType: 'เอกสารทั้งหมด', // Default selection for dropdown
      documentPurpose: '',
    },
    validationSchema: Yup.object({
      documentPurpose: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-8">
      {/* Electronic Signature Upload */}
      <div>
        <label className="block text-gray-700 font-semibold mb-2">ตรายางอิเล็กทรอนิกส์</label>
        <input
          type="file"
          id="electronicSignature"
          name="electronicSignature"
          onChange={(event) => {
            if (event.currentTarget.files && event.currentTarget.files.length > 0) {
              formik.setFieldValue('electronicSignature', event.currentTarget.files[0]);
            }
          }}
          className="block w-full px-4 py-2 border-2 border-dashed border-gray-300 rounded-lg text-sm text-gray-600 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:bg-blue-50"
        />
        <p className="text-sm text-gray-500 mt-1">*ขนาดภาพที่แนะนำ 500*300 px และขนาดไม่เกิน 3MB</p>
      </div>

      {/* Document Line Upload */}
      <div>
        <label className="block text-gray-700 font-semibold mb-2">ลายเซ็นอิเล็กทรอนิกส์</label>
        <input
          type="file"
          id="documentLine"
          name="documentLine"
          onChange={(event) => {
            if (event.currentTarget.files && event.currentTarget.files.length > 0) {
              formik.setFieldValue('documentLine', event.currentTarget.files[0]);
            }
          }}
          className="block w-full px-4 py-2 border-2 border-dashed border-gray-300 rounded-lg text-sm text-gray-600 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:bg-blue-50"
        />
        <p className="text-sm text-gray-500 mt-1">*ขนาดภาพที่แนะนำ 400*140 px และขนาดไม่เกิน 3MB</p>
      </div>

      {/* Document Type Dropdown and Purpose Textarea */}
      <div>
        <label className="block text-gray-700 font-semibold mb-2">เอกสาร :</label>
        <select
          name="documentType"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.documentType}
          className="block w-full px-4 py-2 border border-gray-300 rounded-lg text-sm"
        >
          {documentOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-gray-700 font-semibold mb-2">หมายเหตุ :</label>
        <textarea
          id="documentPurpose"
          name="documentPurpose"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.documentPurpose}
          rows={4}
          className="block w-full px-4 py-2 border border-gray-300 rounded-lg text-sm"
        ></textarea>
        {formik.touched.documentPurpose && formik.errors.documentPurpose ? (
          <p className="text-sm text-red-500 mt-1">{formik.errors.documentPurpose}</p>
        ) : null}
      </div>

      {/* Submit Button */}
      <div className="text-right">
        <button
          type="submit"
          className="px-6 py-2 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600"
        >
          บันทึกข้อมูล
        </button>
      </div>
    </form>
  );
};

export default SettingDocs;
